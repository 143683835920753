import Switch from "react-switch";
import classNames from "classnames";
import classNamesDedupe from "classnames/dedupe";
import { FiMinus, FiMinusCircle, FiPlus, FiSpeaker, FiVolume, FiVolume2, } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { ReactComponent as OpenPeep2 } from "../images/svg/openpeep2.svg";
import Button from "../components/buttons/Button";
import { getBackgroundCSS } from "../components/css";
import { apiAction_endDeleteCustomVoice, apiAction_startDeleteCustomVoice, asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile, pagesAction_closePage, pagesAction_openPage } from "../store";
import ReactPlayer from "react-player";
import { delete_custom_voice } from "../api/delete_custom_voice";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import { FaSpinner } from "react-icons/fa";

function ManageCustomVoicesPage({count}){
    const dispatch = useDispatch();

    const asfFormState = useSelector((state) => {return state.addSlidesForm});
    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});

    const voices = asfFormState.voices;

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const voiceIconsClass = classNames('h-12 w-12 rounded-[30px]');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'mt-6', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1', {
        'hidden': false
    })
    const topErrorDivClass = () => {
        let hiddenValue = true;
        if (asfFormState.manageCustomVoices_voiceAudioFile !== ''){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }
    const topErrorMessage = () => {
        return asfFormState.manageCustomVoices_voiceAudioFile;
    };


    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const handleOpenAddCustomVoicePage = () => {
        const action = pagesAction_openPage('AddCustomVoicePage'); dispatch(action);
    }

    const isDisableAllButtons = () => {
        return apiState.deleteCustomVoiceStatus === 'waiting';
    }

    const getReactPlayerButtonJSX = (voice) => {
        if (voice.voiceAudioFiles === null || voice.voiceAudioFiles === undefined || voice.voiceAudioFiles.length === 0){
            return <></>
        }

        const voiceAudioFilesJSX = [];

        voice.voiceAudioFiles.forEach((voiceAudioFile, index) => {
            voiceAudioFilesJSX.push(
                <div key={index} className="py-1">
                    <Button plain={true} plainDisabled={isDisableAllButtons()} 
                        className="" onClick={() => {
                            handlePlayPauseClick(voiceAudioFile);
                        }}>
                        {playPauseVoiceAudioFileIcon(voiceAudioFile)}
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Play Audio Sample {index + 1}
                        </div>
                    </Button>
                </div>
            );
        });

        return voiceAudioFilesJSX;
    }

    const handlePlayPauseClick = (voiceAudioFile) => {
        const action = asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile(voiceAudioFile); dispatch(action);
    }

    const playPauseVoiceAudioFileIcon = (voiceAudioFile) => {
        if (asfFormState.manageCustomVoices_voiceAudioFile !== null && voiceAudioFile.id === asfFormState.manageCustomVoices_voiceAudioFile.id){
            return <FiVolume2 className={featherIconsClass}/>
        }else{
            return <FiVolume className={featherIconsClass}/>
        }
    }

    const getReactPlayerJSX = () => {
        if (asfFormState.manageCustomVoices_voiceAudioFile === null){
            return (
                <ReactPlayer 
                    url=''
                    playing={false} 
                />
            )
        }else{
            return (
                <ReactPlayer 
                    playing={true}
                    loop={false}
                    onEnded={() => {handlePlayPauseClick(null);}}
                    url={`https://saythatback-voicefile.s3.amazonaws.com/${asfFormState.manageCustomVoices_voiceAudioFile.s3key}.wav`}                    
                />
            );
        }
    }

    const handleDeleteCustomVoice = async (voice) => {
        let action = apiAction_startDeleteCustomVoice(voice); dispatch(action);
        const res = await delete_custom_voice(voice.id, sessionState.token);
        const payload = { res: res, voice: voice };
        if (res.success && res.success === true && res.status === 'custom_voice_deleted'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endDeleteCustomVoice(payload); dispatch(action);
    }

    const getVoiceDeleteButtonIconSpinner = (voice) => {
        if (apiState.deleteCustomVoiceWaitingArray.includes(voice.id)){
            return <FaSpinner className={classNames(featherIconsClass, 'animate-spin')} />
        }else{
            return <FiMinusCircle className="justify-self-start h-6 w-6"/>
        }
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className='flex border-black items-center w-full font-montserrat font-medium text-[1rem] text-red-1'>
                    {errorMessage(stateErrorVariable)}
                </div>
            )
        }
        
    }

    const errorMessage = (stateErrorVariable) => {
        return stateErrorVariable === null || stateErrorVariable === undefined ? '' : stateErrorVariable;
    };

    const getVoicesJSX = () => {
        const voicesJSX = [];
        voices.forEach((voice, index) => {
            if (voice.isSystemVoice === true){
                return;
            }

            //null or str
            const errorMessage = voice.error.topErrorErrorMessage;
            
            voicesJSX.push(
                <div key={index} className={classNames('flex flex-col w-full bg-white rounded-[20px] p-6 text-center border-2 mt-3',
                    {
                        'border-red-1': typeof errorMessage === 'string',
                        'border-black': errorMessage === null || errorMessage === undefined
                    }
                )}>
                    {getErrorJSX(errorMessage)}
                    <div className="flex flex-row font-montserrat font-extrabold text-xl place-content-center">
                         <img className={voiceIconsClass}
                            src={`https://saythatback-voicefile.s3.amazonaws.com/${voice.voiceImageFile.s3key}_128x128.jpg`}
                        />
                        <div className="w-full my-3">
                            {voice.label}
                        </div>
                    </div>
                    {getReactPlayerButtonJSX(voice)}
                    <div className="mt-3">
                        <Button primary={!isDisableAllButtons()} primaryDisabled={isDisableAllButtons()} 
                            className="" onClick={() => {
                                handleDeleteCustomVoice(voice);
                            }}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Remove this custom voice
                            </div>
                            {getVoiceDeleteButtonIconSpinner(voice)}
                        </Button>
                    </div>
                </div> 
            );
        });

        return voicesJSX;
    }

    const getTopErrorJSX = () => {
        return <div className={topErrorDivClass()}>{topErrorMessage()}</div>
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep2 />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Manage your custom voices
                </div> 
                <div className={classNames('bg-white text-center w-full mt-6 gap-5')}>
                    <Button primary={!isDisableAllButtons()} primaryDisabled={isDisableAllButtons()} 
                        className="" onClick={handleOpenAddCustomVoicePage}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Add a new custom voice
                        </div>
                        <FiPlus className="justify-self-start h-6 w-6"/>
                    </Button>
                </div>  
                {getVoicesJSX()}
                {getReactPlayerJSX()}
            </div>
        </div> 
        
    )
}

export default ManageCustomVoicesPage;