
import Modal from 'react-modal';
import classNames from "classnames";
import { ReactComponent as OpenPeep } from "../../images/svg/openpeep2.svg";
import { FiTrash, FiX } from "react-icons/fi";
import { FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import { cpAction_setCompletedDeleteProjectId } from '../../store';

const DeleteCompletedProjectModal = ({
    isOpen,
    handleModalDeleteClick,
}) => {
    const dispatch = useDispatch();

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '20px',
            padding: '20px',
            height: 'fit-content',
            width: '90%',
            maxWidth: '30rem',
            backgroundColor: '#FFFFFF',
            maxHeight: '90%',
            overflow              : 'auto',
            WebkitOverflowScrolling: 'touch'
          },
    };

    const apiState = useSelector((state) => {return state.api;});
    
    const handleCloseModal = () => {
        const action = cpAction_setCompletedDeleteProjectId(null); dispatch(action);
    }    

    const isDisableAllButtons = () => {
        return apiState.deleteProjectLoadCompletedProjectsStatus === 'waiting';
    }

    const getButtonIcon = () => {
        if (isDisableAllButtons() === false){
            return <FiTrash className="justify-self-end h-6 w-6"/>
        }else{
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }
    }
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            style={modalStyle}
            preventScroll={true}
        >
            <div className="absolute">
                <Button round={!isDisableAllButtons()} roundDisabled={isDisableAllButtons()} 
                    className="mb-5" onClick={handleCloseModal}>
                    <FiX className="justify-self-start w-6 h-6"/>
                </Button>
            </div>
            <div className="flex w-full place-content-center"><OpenPeep className="w-40 h-40 mt-4" /></div>
            <div className="w-full bg-white font-montserrat font-extrabold 
            text-2xl rounded-[20px] text-center p-1">
                Are you sure you’d like to delete this project?
            </div>
            <div className={classNames('bg-white text-center w-full mt-6 gap-5')}>
                <Button primary={!isDisableAllButtons()} onClick={handleModalDeleteClick} primaryDisabled={isDisableAllButtons()}>
                    <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                        Delete project
                    </div>
                    {getButtonIcon()}
                </Button>
            </div>  
        </Modal>
    )
}

export default DeleteCompletedProjectModal;