import { createAction } from "@reduxjs/toolkit";

//api
const apiAction_startIsSessionValid = createAction('api/apiAction_startIsSessionValid');
const apiAction_endIsSessionValid = createAction('api/apiAction_endIsSessionValid');
const apiAction_startSubmitEmail = createAction('api/apiAction_startSubmitEmail');
const apiAction_endSubmitEmail = createAction('api/apiAction_endSubmitEmail');
const apiAction_startSubmitEmailPassword = createAction('api/apiAction_startSubmitEmailPassword');
const apiAction_endSubmitEmailPassword = createAction('api/apiAction_endSubmitEmailPassword');
const apiAction_startSignOut = createAction('api/apiAction_startSignOut');
const apiAction_endSignOut = createAction('api/apiAction_endSignOut');
const apiAction_startUploadManualText = createAction('api/apiAction_startUploadManualText');
const apiAction_endUploadManualText = createAction('api/apiAction_endUploadManualText');
const apiAction_startResetPassword1 = createAction('api/apiAction_startResetPassword1');
const apiAction_endResetPassword1 = createAction('api/apiAction_endResetPassword1');
const apiAction_startResetPassword2 = createAction('api/apiAction_startResetPassword2');
const apiAction_endResetPassword2 = createAction('api/apiAction_endResetPassword2');
const apiAction_startSubmitUrl = createAction('api/apiAction_startSubmitUrl');
const apiAction_endSubmitUrl = createAction('api/apiAction_endSubmitUrl');
const apiAction_startUploadMediaFile = createAction('api/apiAction_startUploadMediaFile');
const apiAction_endUploadMediaFile = createAction('api/apiAction_endUploadMediaFile');
const apiAction_startSaveToDraft = createAction('api/apiAction_startSaveToDraft');
const apiAction_endSaveToDraft = createAction('api/apiAction_endSaveToDraft');
const apiAction_startLoadDraftProjects = createAction('api/apiAction_startLoadDraftProjects');
const apiAction_endGetDraftProjects = createAction('api/apiAction_endGetDraftProjects');
const apiAction_startLoadCompletedProjects = createAction('api/apiAction_startLoadCompletedProjects');
const apiAction_endLoadCompletedProjects = createAction('api/apiAction_endLoadCompletedProjects');
const apiAction_startSaveToDraftLoadBasket = createAction('api/apiAction_startSaveToDraftLoadBasket');
const apiAction_endSaveToDraftLoadBasket = createAction('api/apiAction_endSaveToDraftLoadBasket');
const apiAction_startGetDraftProject = createAction('api/apiAction_startGetDraftProject');
const apiAction_endGetDraftProject = createAction('api/apiAction_endGetDraftProject');
const apiAction_startGetCopyProject = createAction('api/apiAction_startGetCopyProject');
const apiAction_endGetCopyProject = createAction('api/apiAction_endGetCopyProject');
const apiAction_startGetCompletedProject = createAction('api/apiAction_startGetCompletedProject');
const apiAction_endGetCompletedProject = createAction('api/apiAction_endGetCompletedProject');
const apiAction_startDeleteProjectLoadDraftProjects = createAction('api/apiAction_startDeleteProjectLoadDraftProjects');
const apiAction_endDeleteProjectLoadDraftProjects = createAction('api/apiAction_endDeleteProjectLoadDraftProjects');
const apiAction_startDeleteProjectLoadCompletedProjects = createAction('api/apiAction_startDeleteProjectLoadCompletedProjects');
const apiAction_endDeleteProjectLoadCompletedProjects = createAction('api/apiAction_endDeleteProjectLoadCompletedProjects');
const apiAction_startCreatePaymentIntent = createAction('api/apiAction_startCreatePaymentIntent');
const apiAction_endCreatePaymentIntent = createAction('api/apiAction_endCreatePaymentIntent');
const apiAction_startStripeANDcheckUpdatePaymentIntentStatus = createAction('api/apiAction_startStripeANDcheckUpdatePaymentIntentStatus');
const apiAction_endStripeANDcheckUpdatePaymentIntentStatus = createAction('api/apiAction_endStripeANDcheckUpdatePaymentIntentStatus');
const apiAction_startDownloadCompletedProjectStatus = createAction('api/apiAction_startDownloadCompletedProjectStatus');
const apiAction_endDownloadCompletedProjectStatus = createAction('api/apiAction_endDownloadCompletedProjectStatus');
const apiAction_startUploadVoiceImageFile = createAction('api/apiAction_startUploadVoiceImageFile');
const apiAction_endUploadVoiceImageFile = createAction('api/apiAction_endUploadVoiceImageFile');
const apiAction_resetAllOnReload = createAction('api/apiAction_resetAllOnReload');
const apiAction_startUploadVoiceAudioFile = createAction('api/apiAction_startUploadVoiceAudioFile');
const apiAction_endUploadVoiceAudioFile = createAction('api/apiAction_endUploadVoiceAudioFile');
const apiAction_startSaveCustomVoice = createAction('api/apiAction_startSaveCustomVoice');
const apiAction_endSaveCustomVoice = createAction('api/apiAction_endSaveCustomVoice');
const apiAction_startDeleteCustomVoice = createAction('api/apiAction_startDeleteCustomVoice');
const apiAction_endDeleteCustomVoice = createAction('api/apiAction_endDeleteCustomVoice');
const apiAction_startGetFineTuneProject = createAction('api/apiAction_startGetFineTuneProject');
const apiAction_endGetFineTuneProject = createAction('api/apiAction_endGetFineTuneProject');
const apiAction_startRevertToOriginalFineTuneProjectStatus = createAction('api/apiAction_startRevertToOriginalFineTuneProjectStatus');
const apiAction_endRevertToOriginalFineTuneProjectStatus = createAction('api/apiAction_endRevertToOriginalFineTuneProjectStatus');
const apiAction_startSaveFineTuneProjectStatus = createAction('api/apiAction_startSaveFineTuneProjectStatus');
const apiAction_endSaveFineTuneProjectStatus = createAction('api/apiAction_endSaveFineTuneProjectStatus');
const apiAction_startSaveSubmitFineTuneProjectStatus = createAction('api/apiAction_startSaveSubmitFineTuneProjectStatus');
const apiAction_endSaveSubmitFineTuneProjectStatus = createAction('api/apiAction_endSaveSubmitFineTuneProjectStatus');
export { 
    apiAction_startIsSessionValid, apiAction_endIsSessionValid, 
    apiAction_startSubmitEmail, apiAction_endSubmitEmail, apiAction_startSubmitEmailPassword,
    apiAction_endSubmitEmailPassword, apiAction_startSignOut,
    apiAction_endSignOut, apiAction_startUploadManualText,
    apiAction_endUploadManualText, apiAction_startResetPassword1,
    apiAction_endResetPassword1,apiAction_startResetPassword2, apiAction_endResetPassword2, 
    apiAction_startSubmitUrl, apiAction_endSubmitUrl, 
    apiAction_startUploadMediaFile, apiAction_endUploadMediaFile, 
    apiAction_startSaveToDraft, apiAction_endSaveToDraft,
    apiAction_startLoadDraftProjects, apiAction_endGetDraftProjects, 
    apiAction_startLoadCompletedProjects, apiAction_endLoadCompletedProjects,
    apiAction_startSaveToDraftLoadBasket, apiAction_endSaveToDraftLoadBasket,
    apiAction_startGetDraftProject, apiAction_endGetDraftProject, 
    apiAction_startGetCompletedProject, apiAction_endGetCompletedProject,
    apiAction_startGetCopyProject, apiAction_endGetCopyProject, 
    apiAction_startDeleteProjectLoadDraftProjects, apiAction_endDeleteProjectLoadDraftProjects,
    apiAction_startDeleteProjectLoadCompletedProjects, apiAction_endDeleteProjectLoadCompletedProjects,
    apiAction_startCreatePaymentIntent, apiAction_endCreatePaymentIntent,
    apiAction_startStripeANDcheckUpdatePaymentIntentStatus, apiAction_endStripeANDcheckUpdatePaymentIntentStatus,
    apiAction_startDownloadCompletedProjectStatus, apiAction_endDownloadCompletedProjectStatus,
    apiAction_startUploadVoiceImageFile, apiAction_endUploadVoiceImageFile,
    apiAction_startUploadVoiceAudioFile, apiAction_endUploadVoiceAudioFile,
    apiAction_startSaveCustomVoice, apiAction_endSaveCustomVoice,
    apiAction_startDeleteCustomVoice, apiAction_endDeleteCustomVoice,
    apiAction_startGetFineTuneProject, apiAction_endGetFineTuneProject,
    apiAction_startRevertToOriginalFineTuneProjectStatus, apiAction_endRevertToOriginalFineTuneProjectStatus,
    apiAction_startSaveFineTuneProjectStatus, apiAction_endSaveFineTuneProjectStatus,
    apiAction_startSaveSubmitFineTuneProjectStatus, apiAction_endSaveSubmitFineTuneProjectStatus,

    apiAction_resetAllOnReload
}

//completedProjectsPage
const cpAction_setCompletedCopyProjectId = createAction('completedProjectsPage/cpAction_setCompletedCopyProjectId');
const cpAction_setCompletedDeleteProjectId = createAction('completedProjectsPage/cpAction_setCompletedDeleteProjectId');
export { cpAction_setCompletedCopyProjectId, cpAction_setCompletedDeleteProjectId};

//draftProjectsPage
const dpAction_setDraftCopyProjectId = createAction('draftProjectsPage/dpAction_setDraftCopyProjectId');
const dpAction_setDraftDeleteProjectId = createAction('draftProjectsPage/dpAction_setDraftDeleteProjectId');
export { dpAction_setDraftDeleteProjectId, dpAction_setDraftCopyProjectId };



//pricingPage
const ppAction_SetAudio = createAction('pricingPage/ppAction_SetAudio');
export { ppAction_SetAudio, };

//addSlidesForm
const asfAction_updateTitleField = createAction('addSlidesForm/asfAction_updateTitleField');
const asfAction_openAddNewSlideModal = createAction('addSlidesForm/asfAction_openAddNewSlideModal');
const asfAction_closeAddNewSlideModal = createAction('addSlidesForm/asfAction_closeAddNewSlideModal');
const asfAction_addBackgroundMusicSlide = createAction('addSlidesForm/asfAction_addBackgroundMusicSlide');
const asfAction_addTTSImageSlide = createAction('addSlidesForm/asfAction_addTTSImageSlide');
const asfAction_addTTSVideoSlide = createAction('addSlidesForm/asfAction_addTTSVideoSlide');
const asfAction_addNoTTSImageSlide = createAction('addSlidesForm/asfAction_addNoTTSImageSlide');
const asfAction_addNoTTSVideoSlide = createAction('addSlidesForm/asfAction_addNoTTSVideoSlide');
const asfAction_removeSlide = createAction('addSlidesForm/asfAction_removeSlide');
const asfAction_moveSlideUp = createAction('addSlidesForm/asfAction_moveSlideUp');
const asfAction_moveSlideDown = createAction('addSlidesForm/asfAction_moveSlideDown');
const asfAction_copyMediaFile = createAction('addSlidesForm/asfAction_copyMediaFile');
const asfAction_pasteMediaFile = createAction('addSlidesForm/asfAction_pasteMediaFile');
const asfAction_removeMediaFile = createAction('addSlidesForm/asfAction_removeMediaFile');
const asfAction_updateLoopBackgroundMusic = createAction('addSlidesForm/asfAction_updateLoopBackgroundMusic');
const asfAction_openChangeVoiceModal = createAction('addSlidesForm/asfAction_openChangeVoiceModal');
const asfAction_closeChangeModal = createAction('addSlidesForm/asfAction_closeChangeModal');
const asfAction_changeVoiceOnModalIndex = createAction('addSlidesForm/asfAction_changeVoiceOnModalIndex');
const asf_updateAddCustomVoiceLabel = createAction('addSlidesForm/asf_updateAddCustomVoiceLabel');
const asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile  = createAction ('addSlidesForm/asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile');
const asfAction_addCustomVoicePage_playPauseVoiceAudioFile = createAction('addSlidesForm/asfAction_addCustomVoicePage_playPauseVoiceAudioFile');
const asfAction_updateOriginalText = createAction('addSlidesForm/asfAction_updateOriginalText');
const asfAction_updateSubtitlesOnField = createAction('addSlidesForm/asfAction_updateSubtitlesOnField')
const asfAction_updateLargeSubtitlesField = createAction('addSlidesForm/asfAction_updateLargeSubtitlesField');
const asfAction_updateSubtitlesPositionField = createAction('addSlidesForm/asfAction_updateSubtitlesPositionField');
const asfAction_updateDurationField = createAction('addSlidesForm/asfAction_updateDurationField');
const asfAction_updatePlayVideoTillEndField = createAction('addSlidesForm/asfAction_updatePlayVideoTillEndField');
const asfAction_updateSilenceIntervalAtEndField = createAction('addSlidesForm/asfAction_updateSilenceIntervalAtEndField');
const asfAction_updateTransitionOpenClose = createAction('addSlidesForm/asfAction_updateTransitionOpenClose');
const asfAction_updateTransitionTypeId = createAction('addSlidesForm/asfAction_updateTransitionTypeId');
const asfAction_updateColor = createAction('addSlidesForm/asfAction_updateColor');
const asfAction_updateFrom = createAction('addSlidesForm/asfAction_updateFrom');
const asfAction_updateTo = createAction('addSlidesForm/asfAction_updateFrom');
const asfAction_updateDirection = createAction('addSlidesForm/asfAction_updateDirection');
const asfAction_UpdateFragmentOriginalText = createAction('addSlidesForm/asfAction_UpdateFragmentOriginalText');
const asfAction_UpdateFragmentAiSpeakableText = createAction('addSlidesForm/asfAction_UpdateFragmentAiSpeakableText');
const asfAction_addFragment = createAction('addSlidesForm/asfAction_addFragment');
const asfAction_removeFragment = createAction('addSlidesForm/asfAction_removeFragment');
export{
    asfAction_updateTitleField,
    asfAction_openAddNewSlideModal, asfAction_closeAddNewSlideModal,
    asfAction_openChangeVoiceModal, asfAction_closeChangeModal, asfAction_changeVoiceOnModalIndex,
    asfAction_addBackgroundMusicSlide, 
    asfAction_addTTSImageSlide, asfAction_addTTSVideoSlide, 
    asfAction_addNoTTSImageSlide, asfAction_addNoTTSVideoSlide, 
    asfAction_removeSlide,
    asfAction_moveSlideUp, asfAction_moveSlideDown,
    asfAction_copyMediaFile, asfAction_pasteMediaFile, asfAction_removeMediaFile,
    asfAction_updateLoopBackgroundMusic,
    asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile,
    asfAction_addCustomVoicePage_playPauseVoiceAudioFile,
    asfAction_updateOriginalText,
    asfAction_updateSubtitlesOnField,
    asfAction_updateLargeSubtitlesField,
    asfAction_updateSubtitlesPositionField,
    asfAction_updateDurationField,
    asfAction_updatePlayVideoTillEndField,
    asfAction_updateSilenceIntervalAtEndField,
    asfAction_updateTransitionOpenClose,
    asfAction_updateTransitionTypeId,
    asfAction_updateColor,
    asfAction_updateFrom, asfAction_updateTo,
    asfAction_updateDirection,
    asfAction_UpdateFragmentOriginalText,
    asfAction_UpdateFragmentAiSpeakableText,
    asfAction_addFragment,
    asfAction_removeFragment,
    
    //addCustomVoice
    asf_updateAddCustomVoiceLabel
}

//basket
const bAction_setClientSecret = createAction('basketPage/bAction_setClientSecret');
const bAction_setEmail = createAction('basketPage/bAction_setEmail');
const bAction_setPaymentIntentStatus = createAction('basketPage/bAction_setPaymentIntentStatus');
export {
    bAction_setClientSecret,
    bAction_setEmail,
    bAction_setPaymentIntentStatus,
}

//pages
const pagesAction_openPage = createAction('pages/pagesAction_openPage');
const pagesAction_closePage = createAction('pages/pagesAction_closePage');
const pagesAction_switchPage = createAction('pages/pagesAction_switchPage');
const pagesAction_closeMenuOpenPage = createAction('pages/pagesAction_closeMenuOpenPage');
const pagesAction_cleanStackOpenTwoPages = createAction('pages/pagesAction_cleanStackOpenTwoPages');
const pagesAction_closeTwoPages = createAction('pages/pagesAction_closeTwoPages')
const pagesAction_closeThreePages = createAction('pages/pagesAction_closeThreePages');
const pagesAction_closeTwoPages_openPage = createAction('pages/pagesAction_closeTwoPages_openPage');
const pagesAction_closeFourPages = createAction('pages/pagesAction_closeFourPages');
const pagesAction_closeFourPages_openPage = createAction('pages/pagesAction_closeFourPages_openPage');
const pagesAction_resetToInitialState = createAction('pages/pagesAction_resetToInitialState');
const pagesAction_logoutAllPages = createAction('pages/pagesAction_logoutAllPages');
const pagesAction_resetToInitialStateAndLoadTAC = createAction('pages/pagesAction_resetToInitialStateAndLoadTAC');
export { 
    pagesAction_openPage, 
    pagesAction_closePage,
    pagesAction_switchPage, 
    pagesAction_closeMenuOpenPage, pagesAction_cleanStackOpenTwoPages, 
    pagesAction_closeTwoPages, pagesAction_closeThreePages,
    pagesAction_closeTwoPages_openPage, pagesAction_closeFourPages, pagesAction_closeFourPages_openPage,
    pagesAction_resetToInitialState, pagesAction_logoutAllPages,
    pagesAction_resetToInitialStateAndLoadTAC
};

//session
const sessionAction_setLoggedIn = createAction('session/sessionAction_setLoggedIn');
export { sessionAction_setLoggedIn };

//signInPageForm
const sipfAction_updateEmailField = createAction('signInPageForm/sipfAction_updateEmailField');
const sipfAction_updatePasswordField = createAction('signInPageForm/sipfAction_updatePasswordField');
const sipfAction_updateResetPasswordField = createAction('signInPageForm/sipfAction_updateResetPasswordField');
const sipfAction_updateResetCodeField = createAction('signInPageForm/sipfAction_updateResetCodeField');
const sipfAction_setTermsCheckbox = createAction('signInPageForm/sipfAction_setTermsCheckbox');
const sipfAction_setTermsCheckboxError = createAction('signInPageForm/sipfAction_setTermsCheckboxError');
export { sipfAction_updateEmailField, sipfAction_updatePasswordField,
    sipfAction_updateResetPasswordField,
    sipfAction_updateResetCodeField, sipfAction_setTermsCheckbox, 
    sipfAction_setTermsCheckboxError
}
