import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import BackPageTopBar from "../components/BackPageTopBar";
import { FiChevronRight, FiUser, FiLoader } from 'react-icons/fi';
import { pagesAction_closePage, pagesAction_switchPage } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps4_smile.svg";
import { sipfAction_updatePasswordField } from "../store";
import { apiAction_startSubmitEmailPassword, apiAction_endSubmitEmailPassword, 
    apiAction_startResetPassword1,
    apiAction_endResetPassword1 } from "../store";
import { submit_email_password } from "../api/submit_email_password";
import { session_setCookie, session_removeCookie } from "../cookie/SessionCookie";
import { reset_password_1 } from '../api/reset_password_1';
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";
 
 function LoginRegisterPasswordPage1({count}){
    const dispatch = useDispatch();

    const pagesState = useSelector((state) => {return state.pages;});
    const sipFormState = useSelector((state) => {return state.signInPageForm;});
    const apiState = useSelector((state) => {return state.api;});

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const inputFieldDivClass = classNames('flex border-black border-2 rounded-[20px] items-center h-[3.5rem] my-6 px-2.5 w-full');
    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const textInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] focus:outline-none');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'mt-6', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1');

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const passwordErrorDivClass = () => {
        let hiddenValue = true;
        if (sipFormState.passwordFieldState === 'failed-server-test'){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }

    const resetPasswordSpanClass = () => {
        return classNames('text-red-1 cursor-pointer', {
            'cursor-not-allowed': apiState.resetPassword1Status === 'waiting'
        })
    }

    const passwordErrorMessage = () => {
        return sipFormState.passwordErrorMessage;
    };

    const handlePasswordLFieldChange = (e) => {
        const action = sipfAction_updatePasswordField(e.target.value); dispatch(action);
    };

    const handleSubmit = async () => {
        //check if logged in - if not then present login screen
        let action = apiAction_startSubmitEmailPassword(); dispatch(action);
        const res = await submit_email_password(sipFormState.email, sipFormState.password);
        const payload = { res: res, }
        if (res.success && res.success === true && res.status === 'successfully_signed_in'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'LoginRegisterSuccessfulPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else{
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endSubmitEmailPassword(payload); dispatch(action);
    }

    const handleResetClick = async () => {
        if (!isAPICallWaiting()){
            //check if logged in - if not then present login screen
            let action = apiAction_startResetPassword1(); dispatch(action);
            const res = await reset_password_1(sipFormState.email);
            const payload = { res: res, }
            if (res.success && res.success === true){
                payload['addSlidesForm'] = {};
                payload['pages'] = {
                    pagesAction: 'pagesAction_openPage',
                    nextPage: 'LoginRegisterResetPage'
                };
            }
            action = apiAction_endResetPassword1(payload); dispatch(action);
        }
    }

    const isAPICallWaiting = () => {
        return apiState.submitEmailPasswordStatus === 'waiting' 
        || apiState.resetPassword1Status === 'waiting';
    }

    const getButtonIcon = () => {
        if (isAPICallWaiting() === false){
            return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }else{
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className={errorDivClass}>{stateErrorVariable}</div>
            )
        }
        
    }

    return (
        <div className={backgroundClass}>
            <BackPageTopBar onClick={handleClosePage} disabled={isAPICallWaiting()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep className="w-80" />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Sign In
                </div>
                <div className="w-full bg-white 
                rounded-[20px] p-6 text-center mt-2.5 border-black border-2">
                    <div className="font-montserrat font-medium text-[1.063rem]">
                        Enter your password.
                    </div>
                    {getErrorJSX(sipFormState.emailErrorMessage)}
                    {getErrorJSX(sipFormState.passwordErrorMessage)}
                    <div className={inputFieldDivClass}>
                        <FiUser className={featherIconsClass}/>
                        <input type="text" name="email" className={textInputClass} placeholder="Email"
                         value={sipFormState.email} readOnly />
                    </div>
                    <div className={inputFieldDivClass}>
                        <FiUser className={featherIconsClass}/>
                        <input type="password" name="password" className={textInputClass} placeholder="Password"
                        onChange={handlePasswordLFieldChange} value={sipFormState.password}/>
                    </div>
                    <Button primary waiting={isAPICallWaiting()} onClick={handleSubmit}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Continue
                        </div>
                        {getButtonIcon()}
                    </Button>
                    <div className="text-left font-montserrat font-medium text-sm mt-3">
                        Forgotten your password? 
                        <span onClick={handleResetClick} className={resetPasswordSpanClass()}>
                            &nbsp;Reset it here.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
 }

 export default LoginRegisterPasswordPage1;