import classNames from "classnames"
import { FaSpinner } from "react-icons/fa";
import { FiLink } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { get_draft_projects } from "../api/get_draft_projects";
import { getBackgroundCSS } from "../components/css";
import { pagesAction_closePage, pagesAction_closeMenuOpenPage, pagesAction_switchPage, pagesAction_cleanStackOpenTwoPages, pagesAction_openPage, pagesAction_resetToInitialStateAndLoadTAC } from "../store";
import { apiAction_endGetDraftProjects, apiAction_startLoadDraftProjects } from "../store/actions";
import { loadCompletedProjects } from "./CompletedProjectsPage";
import { getDraftProjects } from "./DraftProjectsPage";

function MenuPage({ count }){
    const dispatch = useDispatch();

    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});

    let backgroundClass = classNames({
        'bg-white': true,
        'h-screen': true,
        'min-h-screen': true,
        'h-full': true,
        'w-screen': true,
        'grid': true,
        'grid-cols-1': true,
        'content-center': true,
        'hidden': count !== 0
    });

    if (count !== 0){
        backgroundClass = classNames({
            'hidden': true
        })
    }

    const getTextClassNames = (pageName) => {
        const parentPage = pagesState[1];
        let menuItemTouchClass = classNames({
            'py-6': true,
            'text-center': true,
            'font-montserrat': true,
            'font-extrabold': true,
            'text-5xl ': true,
            'text-red-1': parentPage===pageName,
            'text-black': parentPage!==pageName,
            'cursor-pointer': true,
            'hover:text-red-1': true,
        });

        if (pageName === 'LoginRegisterEmailPage1' && sessionState.isLoggedIn){
            menuItemTouchClass = classNames(menuItemTouchClass, {'hidden': true})
        }else if (
            (
                pageName === 'AddSlidesPage' ||
                pageName === 'SignOutPage' || 
                pageName === 'DraftProjectsPage' ||
                pageName === 'CompletedProjectsPage' ||
                pageName === 'ManageCustomVoicesPage'
            ) && !sessionState.isLoggedIn){
            menuItemTouchClass = classNames(menuItemTouchClass, {'hidden': true})
        }

       return menuItemTouchClass;
    }

    const showPage = (pageName) => {
        const parentPage = pagesState[1];
        let action = null;
        if (parentPage === pageName){
            const pageToClose = pagesState[0];
            const action = pagesAction_closePage(pageToClose); dispatch(action);
        }else{
            action = pagesAction_closeMenuOpenPage(pageName); dispatch(action);
        }
    }

    const showSignInPage = () => {
        const action = pagesAction_switchPage('LoginRegisterEmailPage1'); dispatch(action);
    }

    const showSignOutPage = () => {
        const action = pagesAction_switchPage('SignOutPage'); dispatch(action);
    }

    const showPrivacyPage = () => {
        const action = pagesAction_switchPage('PrivacyPage'); dispatch(action);
    }

    const showTACSPage = () => {
        const action = pagesAction_resetToInitialStateAndLoadTAC('TACSPage'); dispatch(action);
    }

    const handleAddSlidesPagePageClick = async () => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_switchPage('LoginRegisterEmailPage1');dispatch(action);
            return;
        }

        const parentPage = pagesState[1];
        const pageName = 'AddSlidesPage';
        const newParentPageName = 'DraftProjectsPage'
        let action = null;
        if (parentPage===pageName){
            const pageToClose = pagesState[0];
            action = pagesAction_closePage(pageToClose); dispatch(action);
        }else{
            action = pagesAction_cleanStackOpenTwoPages([newParentPageName, pageName]); dispatch(action);
        }
        getDraftProjects(dispatch, sessionState);
    }
    
    const handleDraftProjectsPageClick = async () => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_switchPage('LoginRegisterEmailPage1');dispatch(action);
            return;
        }

        const parentPage = pagesState[1];
        const pageName = 'DraftProjectsPage';
        let action = null;
        if (parentPage===pageName){
            const pageToClose = pagesState[0];
            action = pagesAction_closePage(pageToClose); dispatch(action);
        }else{
            action = pagesAction_closeMenuOpenPage(pageName); dispatch(action);
        }
        getDraftProjects(dispatch, sessionState);
    }

    const handleCompletedProjectsPageClick = async () => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_switchPage('LoginRegisterEmailPage1');dispatch(action);
            return;
        }

        const parentPage = pagesState[1];
        const pageName = 'CompletedProjectsPage';
        let action = null;
        if (parentPage===pageName){
            const pageToClose = pagesState[0];
            action = pagesAction_closePage(pageToClose); dispatch(action);
        }else{
            action = pagesAction_closeMenuOpenPage(pageName); dispatch(action);
        }
        loadCompletedProjects(dispatch, sessionState);
    }

    const handleManageCustomVoicesPageClick = async () => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_switchPage('LoginRegisterEmailPage1');dispatch(action);
            return;
        }
        
        const pageName = 'ManageCustomVoicesPage';
        const action = pagesAction_switchPage(pageName); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <div>
                <div className={getTextClassNames('PricingPage')}
                    onClick={() => showPage('PricingPage')}>
                    Pricing & Demo
                </div>
                <div className={getTextClassNames('AddSlidesPage')}
                    onClick={handleAddSlidesPagePageClick}>
                    Start A Project
                </div>
                <div className={classNames(getTextClassNames('DraftProjectsPage'), 'flex flex-row justify-center')}
                    onClick={handleDraftProjectsPageClick}>
                    Draft Projects
                </div>
                <div className={classNames(getTextClassNames('CompletedProjectsPage'), 'flex flex-row justify-center')}
                    onClick={handleCompletedProjectsPageClick}>
                    Completed Projects
                </div>
                <div className={classNames(getTextClassNames('ManageCustomVoicesPage'), 'flex flex-row justify-center')}
                    onClick={handleManageCustomVoicesPageClick}>
                    Custom Voices
                </div>
                <div className={getTextClassNames('LoginRegisterEmailPage1')}
                    onClick={() => showSignInPage()}>
                    Sign In
                </div>
                <div className={getTextClassNames('SignOutPage')}
                    onClick={() => showSignOutPage('SignOutPage')}>
                    Sign Out
                </div>
                <div className={classNames(getTextClassNames('TACSPage'))}
                    onClick={() => showTACSPage('TACSPage')}>
                    T&Cs
                </div>
                <div className={getTextClassNames('PrivacyPage')}
                    onClick={() => showPrivacyPage('PrivacyPage')}>
                    Privacy
                </div>
            </div>
        </div>
    );
}

export default MenuPage;