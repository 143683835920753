import { useDispatch, useSelector } from "react-redux";
import dateFormat, { masks } from "dateformat";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import OpenMenuTopBar from "../components/OpenMenuTopBar";
import { FiCheck, FiChevronRight, FiCopy, FiEdit, FiLoader, FiRefreshCw, FiShoppingCart, FiTrash } from 'react-icons/fi';
import { apiAction_startGetCompletedProject, apiAction_endLoadCompletedProjects, 
    apiAction_startLoadCompletedProjects, cpAction_setCompletedCopyProjectId, 
    cpAction_setCompletedDeleteProjectId, apiAction_endGetCompletedProject, 
     pagesAction_openPage, pagesAction_switchPage, apiAction_startGetCopyProject, apiAction_endGetCopyProject, apiAction_startDeleteProjectLoadCompletedProjects, apiAction_endDeleteProjectLoadCompletedProjects, pagesAction_cleanStackOpenTwoPages } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps5.svg";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";
import { get_completed_projects } from "../api/get_completed_projects";
import CopyCompletedProjectModal from "./modal/CopyCompletedProjectModal";
import DeleteCompletedProjectModal from "./modal/DeleteCompletedProjectModal";
import { get_copy_project } from "../api/get_copy_project";
import { delete_project_get_completed_projects } from "../api/delete_project_get_completed_projects";
import { get_completed_project } from "../api/get_completed_project";
import { getDraftProjects } from "./DraftProjectsPage";

const loadCompletedProjects = async (dispatch, sessionState) =>{
    let action = apiAction_startLoadCompletedProjects(); dispatch(action);
    const res = await get_completed_projects(sessionState.token);
    const payload = { res: res, };
    if (res.success && res.success === true && res.status === 'loaded_completed_projects'){
        const sessionObject = {
            isLoggedIn: true,
            token: res.session.token,
            creationDate: res.session.creationDate,
            expiryDate: res.session.expiryDate
        }
        payload['session'] = sessionObject;
        payload['completedProjectsPage'] = {}
        //SET COOKIES
        session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
    }else if(res.error && res.error === 'TOKEN_INVALID'){
        const sessionObject = {
            isLoggedIn: false,
            token: null,
            creationDate: null,
            expiryDate: null
        }
        payload['session'] = sessionObject;
        payload['pages'] = {
            pagesAction: 'pagesAction_logoutAllPages',
            nextPage: ''
        };

        //REMOVE COOKIES
        session_removeCookie();
    }
    
    action = apiAction_endLoadCompletedProjects(payload); dispatch(action);
}

function CompletedProjectsPage({count}){
    const dispatch = useDispatch();
    
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});
    const completedProjectsState = useSelector((state) => {return state.completedProjectsPage;});
    const pagesState = useSelector((state) => {return state.pages;});

    let backgroundClass = getBackgroundCSS(count, 'bg-pink-1');

    const openMenuPage = () => {
        const action = pagesAction_openPage('MenuPage'); dispatch(action);
    }

    const handleCardClick = async (projectId, e) =>{
        //open AddSlidesPage
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let payload = {projectId: projectId}
        let action = apiAction_startGetCompletedProject(payload); dispatch(action);
        const res = await get_completed_project(projectId, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'got_completed_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'CompletedProjectPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endGetCompletedProject(payload); dispatch(action);
    }

    const handleCopyClick = async (projectId, e) => {
        e.stopPropagation();
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let action = cpAction_setCompletedCopyProjectId(projectId); dispatch(action);
    }

    const handleDeleteClick = (projectId, e) => {
        e.stopPropagation();
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }

        let action = cpAction_setCompletedDeleteProjectId(projectId); dispatch(action);
    }

    const isLoading = () => {
        if (
            apiState.loadCompletedProjectsStatus === 'waiting' ||
            apiState.getCompletedProjectStatus === 'waiting'
        ){
            return true;
        }else{
            return false;
        }
    }

    const isDisableAllButtons = () => {
        return isLoading();
    }

    const projectDivClass = (project) => {

        const isLoadingVar = isLoading();

        const getShadow = () => {
            if (isLoadingVar){
                return 'shadow-none active:shadow-none hover:shadow-none'
            }else{
                return 'shadow-xl active:shadow-none hover:shadow-lg';
            }
        }

        return classNames(`flex flex-col rounded-[20px] items-center mt-3 w-full ${getShadow()}`, {
            'cursor-default': !canOpenProject(project),
            'cursor-pointer': canOpenProject(project) && !isLoadingVar,
            'cursor-not-allowed': canOpenProject(project) && isLoadingVar,
            'bg-white': canOpenProject(project),
            'bg-zinc-100': !canOpenProject(project)
        });
    }

    const canOpenProject = (project) => {
        return project.status === 4;
    }

    const getButtons = (project) => {
        //if loading === true
        if (isLoading()){
            if (apiState.getCompletedProject_projectId === project.id || apiState.getCopyProject_projectId === project.id){
                return (
                    <div className="flex flex-rows gap-3 h-full items-center pr-5">
                        <div>
                            <FaSpinner className="justify-self-start h-6 w-6 animate-spin"/>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="flex flex-rows gap-3 h-full items-center pr-5">
                        <div>
                            <Button roundDisabled className="">
                                <FiCopy className="w-6 h-6" color="black" />
                            </Button>
                        </div>
                        <div>
                            <Button roundDisabled>
                                <FiTrash className="w-6 h-6" color="black" />
                            </Button></div>
                        </div>
                )
            }
        }else{
            return (
                <div className="flex flex-rows gap-3 h-full items-center pr-5">
                    <div>
                        <Button round className="" onClick={async (e) => {
                            await handleCopyClick(project.id, e);
                        }}>
                            <FiCopy className="w-6 h-6" color="black"/>
                        </Button>
                    </div>
                    <div>
                        <Button round onClick={async (e) => {
                            handleDeleteClick(project.id, e);
                        }}>
                            <FiTrash className="w-6 h-6" color="black"/>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    masks.ProjectDetails = 'ddd d mmm"," yyyy';

    const getProjectStatusJSX = (project) => {
        if (!canOpenProject(project)){
            return (
                <>
                    <div className="flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6">
                        <div><FiShoppingCart className="w-4 h-4" color="black" /></div>
                        <div>Paid on {dateFormat(project.creationDate, masks.ProjectDetails)}</div>
                    </div>
                    <div className="flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6 pb-3">
                        <div><FiLoader className="w-4 h-4" color="black" /></div>
                        <div>In Progress</div>
                    </div>
                </>
            )
        }else if(canOpenProject(project)){
            return (
                <>
                    <div className="flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6">
                        <div><FiShoppingCart className="w-4 h-4" color="black" /></div>
                        <div>Paid on {dateFormat(project.paidDate, masks.ProjectDetails)}</div>
                    </div>
                    <div className="flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6 pb-3">
                        <div><FiCheck className="w-4 h-4" color="black" /></div>
                        <div>Ready on {dateFormat(project.readyDate, masks.ProjectDetails)}</div>
                    </div>
                </>
            )
        }
    }

    const handleToCompletedProjectsPageClick = async () => {
        let action = pagesAction_switchPage('DraftProjectsPage'); dispatch(action);
        await getDraftProjects(dispatch, sessionState);
    }

    const handleToAddSlidesPageClick = async () => {
        let action = pagesAction_cleanStackOpenTwoPages(['DraftProjectsPage', 'AddSlidesPage']); dispatch(action);
        await getDraftProjects(dispatch, sessionState);
    }

    const getAllProjectsJSX = () => {
        if (completedProjectsState.projects.length === 0){
            return(
                <div className="w-full bg-white 
                rounded-[20px] p-6 text-center mt-2.5">
                    <div className="font-montserrat font-medium text-[1.063rem] mb-3">
                        Completed projects appear here. Click below to view your draft projects or start a new project. 
                    </div>
                    <div className="w-full bg-white text-center flex flex-row gap-5 pt-3">
                        <Button plain className="basis-1/2" 
                            onClick={handleToCompletedProjectsPageClick}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Completed projects
                            </div>
                            <FiChevronRight className="justify-self-end h-6 w-6"/>
                        </Button>
                        <Button primary className="basis-1/2"
                            onClick={handleToAddSlidesPageClick}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Start a new project
                            </div>
                            <FiChevronRight className="justify-self-end h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            );
        }else{
            let projectsJSX = []
            for (const project of completedProjectsState.projects){

                const getFirstImageVideoJSX = () => {
                    if (project.firstImageFileId !== null){
                        return (
                            <div className={classNames('flex, rounded-[20px], items-center, w-full')} >
                                <img alt="" 
                                className={classNames('w-full, bg-white, rounded-[20px]')} 
                                src={`https://saythatback-imagefile.s3.amazonaws.com/${project.firstImageFileS3Key}_1280x720.jpg`} />
                            </div>
                        )
                    }else if (project.firstVideoFileId !== null){
                        return (
                            <div className={classNames('flex, rounded-[20px], items-center, w-full')} >
                                <img alt="" 
                                className={classNames('w-full, bg-white, rounded-[20px]')} 
                                src={`https://saythatback-videofile.s3.amazonaws.com/${project.firstVideoFileS3Key}_1280x720.jpg`} />
                            </div>
                        )
                    }else{
                        return <></>
                    }
                }

                projectsJSX.push(
                    <div key={project.id} className={projectDivClass(project)} onClick={async (e) => {
                        if (canOpenProject(project)){
                            await handleCardClick(project.id, e);
                        }
                    }}>
                        {getFirstImageVideoJSX()}
                        {getErrorJSX(project.error.topErrorErrorMessage)}
                        <div className="w-full h-fit font-montserrat font-extrabold text-2xl flex justify-start items-center mt-1 px-6 py-3">
                            {project.title}
                        </div>
                        <div className="w-full h-fit flex justify-start items-center">
                            <div className="flex flex-col w-full">
                                <div className="flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6">
                                    <div><FiEdit className="w-4 h-4" color="black" /></div>
                                    <div>Created on {dateFormat(project.creationDate, masks.ProjectDetails)}</div>
                                </div>
                                {getProjectStatusJSX(project)}
                            </div>
                            {getButtons(project)}
                        </div>
                    </div>

                )
            }
            return projectsJSX;
        }
    }

    const getRefreshIcon = () => {
        if (apiState.loadCompletedProjectsStatus === 'waiting'){
            return <FiRefreshCw color='black' className="animate-spin justify-self-end h-6 w-6"/>
        }else{
            return <FiRefreshCw className="justify-self-end h-6 w-6"/>
        }
    }

    const handleModalCopyClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');dispatch(action);
            return;
        }
        
        const projectId = completedProjectsState.copyProjectId;
        const payload = {projectId: projectId};
        
        let action = apiAction_startGetCopyProject(payload); dispatch(action);
        const res = await get_copy_project(projectId, sessionState.token, [2, 3, 4]);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'got_copy_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['pages'] = {
                pagesAction: 'pagesAction_cleanStackOpenTwoPages',
                nextPages: ['DraftProjectsPage', 'AddSlidesPage'],
            };
            payload['draftProjectsPage'] = {}
            payload['addSlidesForm'] = { res: res, }
            payload['session'] = sessionObject;

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endGetCopyProject(payload); dispatch(action);
    }

    const handleModalDeleteClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');dispatch(action);
            return;
        }
        
        let action = apiAction_startDeleteProjectLoadCompletedProjects(); dispatch(action);
        const res = await delete_project_get_completed_projects(sessionState.token, completedProjectsState.deleteProjectId);
        const projectId = completedProjectsState.deleteProjectId;
        const payload = {
            projectId: projectId,
            res: res
        }
        if (res.success && res.success === true && res.status === 'deleted_project_loaded_draft_projects'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['completedProjectsPage'] = {}

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        
        action = apiAction_endDeleteProjectLoadCompletedProjects(payload); dispatch(action);
    }

    const getTopErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className="w-full bg-white rounded-[20px] p-6 text-center mt-2.5">
                    <div className='flex border-black items-center w-full font-montserrat font-medium text-[1rem] text-red-1'>
                        {stateErrorVariable}
                    </div>
                </div>
            )
        }
        
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className='flex border-black items-center w-full pl-6 mt-6 font-montserrat font-medium text-[1rem] text-red-1'>
                    {stateErrorVariable}
                </div>
            )
        }
        
    }

    return (
        <div className={backgroundClass}>
            <OpenMenuTopBar onClick={openMenuPage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep />
                <div className=" flex flex-row w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center">
                        <div className="w-full">Your completed projects</div>
                        <div>
                            <Button round={apiState.loadCompletedProjectsStatus !== 'waiting'} 
                            roundDisabled={apiState.loadCompletedProjectsStatus === 'waiting'}  
                            onClick={async () => await loadCompletedProjects(dispatch, sessionState)}>
                                {getRefreshIcon()}
                            </Button>
                        </div>
                </div>
                {getTopErrorJSX(completedProjectsState.error.topErrorErrorMessage)}
                {getAllProjectsJSX()}
            </div>
            <CopyCompletedProjectModal
                isOpen={completedProjectsState.copyProjectId !== null}
                handleModalCopyClick={handleModalCopyClick}
            />
            <DeleteCompletedProjectModal
                isOpen={completedProjectsState.deleteProjectId !== null}
                handleModalDeleteClick={handleModalDeleteClick}
            />
        </div>
    )
}

export { loadCompletedProjects }
export default CompletedProjectsPage;
