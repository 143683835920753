
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import {
    apiReducer,
    apiAction_startIsSessionValid,
    apiAction_endIsSessionValid,
    apiAction_startSubmitEmail,
    apiAction_endSubmitEmail,
    apiAction_startSubmitEmailPassword,
    apiAction_endSubmitEmailPassword,
    apiAction_startSignOut,
    apiAction_endSignOut,
    apiAction_startUploadManualText,
    apiAction_endUploadManualText,
    apiAction_startResetPassword1,
    apiAction_endResetPassword1, 
    apiAction_startResetPassword2, apiAction_endResetPassword2,
    apiAction_startSubmitUrl,  apiAction_endSubmitUrl,
    apiAction_startUploadMediaFile, apiAction_endUploadMediaFile,
    apiAction_startSaveToDraft, apiAction_endSaveToDraft,
    apiAction_startLoadDraftProjects, apiAction_endGetDraftProjects,
    apiAction_startLoadCompletedProjects, apiAction_endLoadCompletedProjects,
    apiAction_startSaveToDraftLoadBasket, apiAction_endSaveToDraftLoadBasket,
    apiAction_startGetDraftProject, apiAction_endGetDraftProject,
    apiAction_startGetCompletedProject, apiAction_endGetCompletedProject,
    apiAction_startGetCopyProject, apiAction_endGetCopyProject,
    apiAction_startDeleteProjectLoadDraftProjects, apiAction_endDeleteProjectLoadDraftProjects,
    apiAction_startDeleteProjectLoadCompletedProjects, apiAction_endDeleteProjectLoadCompletedProjects,
    apiAction_startCreatePaymentIntent, apiAction_endCreatePaymentIntent,
    apiAction_startStripeANDcheckUpdatePaymentIntentStatus, apiAction_endStripeANDcheckUpdatePaymentIntentStatus,
    apiAction_startDownloadCompletedProjectStatus, apiAction_endDownloadCompletedProjectStatus,
    apiAction_startUploadVoiceImageFile, apiAction_endUploadVoiceImageFile,
    apiAction_startUploadVoiceAudioFile, apiAction_endUploadVoiceAudioFile,
    apiAction_startSaveCustomVoice, apiAction_endSaveCustomVoice,
    apiAction_startDeleteCustomVoice, apiAction_endDeleteCustomVoice,
    apiAction_startGetFineTuneProject, apiAction_endGetFineTuneProject,
    apiAction_startRevertToOriginalFineTuneProjectStatus, apiAction_endRevertToOriginalFineTuneProjectStatus,
    apiAction_startSaveFineTuneProjectStatus, apiAction_endSaveFineTuneProjectStatus,
    apiAction_startSaveSubmitFineTuneProjectStatus, apiAction_endSaveSubmitFineTuneProjectStatus,
    
    apiAction_resetAllOnReload
} from "./slices/APISlice";

import { pagesReducer, pagesAction_openPage, 
    pagesAction_closePage, pagesAction_switchPage, 
    pagesAction_closeMenuOpenPage, pagesAction_cleanStackOpenTwoPages, 
    pagesAction_closeTwoPages, 
    pagesAction_closeThreePages, pagesAction_closeTwoPages_openPage,
    pagesAction_closeFourPages, pagesAction_closeFourPages_openPage,
    pagesAction_resetToInitialState, pagesAction_logoutAllPages,
    pagesAction_resetToInitialStateAndLoadTAC
} from "./slices/PagesSlice";

import { pricingPageReducer, ppAction_SetAudio } from "./slices/PricingPageSlice";

import {addSlidesFormReducer,
    asfAction_updateTitleField,
    asfAction_openAddNewSlideModal, asfAction_closeAddNewSlideModal,
    asfAction_openChangeVoiceModal, asfAction_closeChangeModal, asfAction_changeVoiceOnModalIndex,
    asfAction_addBackgroundMusicSlide,
    asfAction_addTTSImageSlide, asfAction_addTTSVideoSlide, 
    asfAction_addNoTTSImageSlide, asfAction_addNoTTSVideoSlide, 
    asfAction_removeSlide,
    asfAction_moveSlideUp, asfAction_moveSlideDown,
    asfAction_copyMediaFile, asfAction_pasteMediaFile, asfAction_removeMediaFile,
    asfAction_updateLoopBackgroundMusic,
    asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile,
    asfAction_addCustomVoicePage_playPauseVoiceAudioFile,
    asfAction_updateOriginalText,
    asfAction_updateSubtitlesOnField,
    asfAction_updateLargeSubtitlesField,
    asfAction_updateSubtitlesPositionField,
    asfAction_updateDurationField,
    asfAction_updatePlayVideoTillEndField,
    asfAction_updateSilenceIntervalAtEndField,
    asfAction_updateTransitionOpenClose,
    asfAction_updateTransitionTypeId,
    asfAction_updateColor,
    asfAction_updateFrom, asfAction_updateTo,
    asfAction_updateDirection,
    asfAction_UpdateFragmentOriginalText,
    asfAction_UpdateFragmentAiSpeakableText,
    asfAction_addFragment,
    asfAction_removeFragment,
    
    //addCustomVoice
    asf_updateAddCustomVoiceLabel
} from './slices/AddSlidesFormSlice';

import { 
    sessionAction_setLoggedIn,
    sessionReducer
} from "./slices/SessionSlice";

import { signInPageFormReducer, 
    sipfAction_updateEmailField, sipfAction_updatePasswordField,
    sipfAction_updateResetPasswordField,
    sipfAction_updateResetCodeField, sipfAction_setTermsCheckbox, 
    sipfAction_setTermsCheckboxError
} from "./slices/SignInPageFormSlice";

import { completedProjectsReducer,
    cpAction_setCompletedCopyProjectId, 
    cpAction_setCompletedDeleteProjectId, } from "./slices/CompletedProjectsSlice";

import { draftProjectsReducer,
    dpAction_setDraftDeleteProjectId, 
    dpAction_setDraftCopyProjectId } from "./slices/DraftProjectsSlice";

import { 
    basketReducer,
    bAction_setClientSecret,
    bAction_setEmail,
    bAction_setPaymentIntentStatus
} from "./slices/BasketSlice";

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: hardSet
};

const rootReducer = combineReducers({ 
    pages: pagesReducer,
    pricingPage: pricingPageReducer,
    addSlidesForm: addSlidesFormReducer,
    basketPage: basketReducer,
    session: sessionReducer,
    signInPageForm: signInPageFormReducer,
    api: apiReducer,
    completedProjectsPage: completedProjectsReducer,
    draftProjectsPage: draftProjectsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export const persistor = persistStore(store)

export { store };

export{ apiAction_startIsSessionValid, apiAction_endIsSessionValid,
     apiAction_startSubmitEmail, apiAction_endSubmitEmail, apiAction_startSubmitEmailPassword,
     apiAction_endSubmitEmailPassword, apiAction_startSignOut,
     apiAction_endSignOut, apiAction_startUploadManualText,
     apiAction_endUploadManualText, apiAction_startResetPassword1,
     apiAction_endResetPassword1,apiAction_startResetPassword2, apiAction_endResetPassword2,
     apiAction_startSubmitUrl, apiAction_endSubmitUrl, 
     apiAction_startUploadMediaFile, apiAction_endUploadMediaFile, 
     apiAction_startSaveToDraft, apiAction_endSaveToDraft, 
     apiAction_startLoadDraftProjects, apiAction_endGetDraftProjects, 
     apiAction_startLoadCompletedProjects, apiAction_endLoadCompletedProjects,
     apiAction_startSaveToDraftLoadBasket, apiAction_endSaveToDraftLoadBasket,
     apiAction_startGetDraftProject, apiAction_endGetDraftProject,
     apiAction_startGetCompletedProject, apiAction_endGetCompletedProject,
     apiAction_startGetCopyProject, apiAction_endGetCopyProject,
     apiAction_startDeleteProjectLoadDraftProjects, apiAction_endDeleteProjectLoadDraftProjects,
     apiAction_startDeleteProjectLoadCompletedProjects, apiAction_endDeleteProjectLoadCompletedProjects,
     apiAction_startCreatePaymentIntent, apiAction_endCreatePaymentIntent,
     apiAction_startStripeANDcheckUpdatePaymentIntentStatus, apiAction_endStripeANDcheckUpdatePaymentIntentStatus,
     apiAction_startDownloadCompletedProjectStatus, apiAction_endDownloadCompletedProjectStatus,
     apiAction_startUploadVoiceImageFile, apiAction_endUploadVoiceImageFile,
     apiAction_startUploadVoiceAudioFile, apiAction_endUploadVoiceAudioFile,
     apiAction_startSaveCustomVoice, apiAction_endSaveCustomVoice,
     apiAction_startDeleteCustomVoice, apiAction_endDeleteCustomVoice,
     apiAction_startGetFineTuneProject, apiAction_endGetFineTuneProject,
     apiAction_startRevertToOriginalFineTuneProjectStatus, apiAction_endRevertToOriginalFineTuneProjectStatus,
     apiAction_startSaveFineTuneProjectStatus, apiAction_endSaveFineTuneProjectStatus,
     apiAction_startSaveSubmitFineTuneProjectStatus, apiAction_endSaveSubmitFineTuneProjectStatus,
     apiAction_resetAllOnReload
}

export { 
    cpAction_setCompletedCopyProjectId, 
    cpAction_setCompletedDeleteProjectId,
}

export { dpAction_setDraftCopyProjectId, dpAction_setDraftDeleteProjectId };

export { pagesAction_openPage, 
    pagesAction_closePage, 
    pagesAction_switchPage, 
    pagesAction_closeMenuOpenPage, pagesAction_cleanStackOpenTwoPages,
    pagesAction_closeTwoPages, pagesAction_closeThreePages, 
    pagesAction_closeTwoPages_openPage, pagesAction_closeFourPages, 
    pagesAction_closeFourPages_openPage, pagesAction_resetToInitialState,
    pagesAction_logoutAllPages,
    pagesAction_resetToInitialStateAndLoadTAC
};

export { ppAction_SetAudio, };

export{
    asfAction_updateTitleField,
    asfAction_openAddNewSlideModal, asfAction_closeAddNewSlideModal,
    asfAction_openChangeVoiceModal, asfAction_closeChangeModal, asfAction_changeVoiceOnModalIndex,
    asfAction_addBackgroundMusicSlide,
    asfAction_addTTSImageSlide, asfAction_addTTSVideoSlide, 
    asfAction_addNoTTSImageSlide, asfAction_addNoTTSVideoSlide, 
    asfAction_removeSlide,
    asfAction_moveSlideUp, asfAction_moveSlideDown,
    asfAction_copyMediaFile, asfAction_pasteMediaFile, asfAction_removeMediaFile,
    asfAction_updateLoopBackgroundMusic,
    asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile,
    asfAction_addCustomVoicePage_playPauseVoiceAudioFile,
    asfAction_updateOriginalText,
    asfAction_updateSubtitlesOnField,
    asfAction_updateLargeSubtitlesField,
    asfAction_updateSubtitlesPositionField,
    asfAction_updateDurationField,
    asfAction_updatePlayVideoTillEndField,
    asfAction_updateSilenceIntervalAtEndField,
    asfAction_updateTransitionOpenClose,
    asfAction_updateTransitionTypeId,
    asfAction_updateColor,
    asfAction_updateFrom, asfAction_updateTo,
    asfAction_updateDirection,
    asfAction_UpdateFragmentOriginalText,
    asfAction_UpdateFragmentAiSpeakableText,
    asfAction_addFragment,
    asfAction_removeFragment,

    //addCustomVoice
    asf_updateAddCustomVoiceLabel,
}

export {
    bAction_setClientSecret,
    bAction_setEmail,
    bAction_setPaymentIntentStatus
}

export { sessionAction_setLoggedIn };

export { sipfAction_updateEmailField, sipfAction_updatePasswordField,
    sipfAction_updateResetPasswordField,
    sipfAction_updateResetCodeField, sipfAction_setTermsCheckbox,
    sipfAction_setTermsCheckboxError
}