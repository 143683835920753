import _ from 'lodash';
import { createSlice } from "@reduxjs/toolkit";
import { 
    apiAction_endSubmitEmailPassword, apiAction_endUploadMediaFile, 
    apiAction_endUploadVoiceImageFile, apiAction_endUploadVoiceAudioFile, 
    pagesAction_openPage, apiAction_endSaveCustomVoice, apiAction_endDeleteCustomVoice, 
    apiAction_endSaveToDraft, apiAction_endGetDraftProject, apiAction_endGetCopyProject, 
    apiAction_endSaveToDraftLoadBasket, apiAction_endGetFineTuneProject,
    apiAction_endRevertToOriginalFineTuneProjectStatus, apiAction_endSaveFineTuneProjectStatus,
    apiAction_endSaveSubmitFineTuneProjectStatus,
    apiAction_endGetDraftProjects,
    apiAction_endDeleteProjectLoadDraftProjects,
    pagesAction_cleanStackOpenTwoPages,
    apiAction_endResetPassword2
} from "../actions";
import { getWordCount } from '../../utils/utils';

const getTemplateProject = () => {
    return {
        id: null,
        title: '',
        slides: [],
        transitionUI: [],

        error: {
            topErrorErrorMessage: null,
            title: null,
            slides: null,
        }
    }
}

const addSlidesFormSlice = createSlice({
    name: 'addSlidesForm',
    initialState: {
        //addSlidesPage
        project: getTemplateProject(),

        //voices
        voices: [],
        voicesChangeSlideId: 0,

        //manageCustomVoicesPage
        manageCustomVoices_voiceAudioFile: null,
        manageCustomVoices_topErrorErrorMessage: '',

        //addCustomVoicePage
        addCustomVoice: {
            label: '',
            voiceImageFile: {
                id: null,
                s3key: null,
            },
            voiceAudioFiles: [
                {id: null, creationDate: null, s3key: null}
            ],

            //UI and error
            topErrorErrorMessage: '',
            confirmSaveCustomVoiceModalOpen: false,
            playingVoiceAudioFile: null,
        },

        //modals
        isAddNewSlideModalOpen: false,
        isChangeVoiceModalOpen: false,
        isSavedSumittedRemakeProjectOpen: false,
        modalIndex: null,

    },
    reducers: {
        asfAction_updateTitleField(state, action){
            state.project.title = action.payload;
        },
        asfAction_openAddNewSlideModal(state, action){
            state.modalIndex = action.payload
            state.isAddNewSlideModalOpen = true;
        },
        asfAction_closeAddNewSlideModal(state, action){
            _asfAction_closeAddNewSlideModal(state, action);
        },
        asfAction_openChangeVoiceModal(state, action){
            state.modalIndex = action.payload
            state.isChangeVoiceModalOpen = true;
        },
        asfAction_closeChangeModal(state, action){
            _asfAction_closeChangeVoiceModal(state, action);
        },
        asfAction_addBackgroundMusicSlide(state, action){
            _asfAction_addBackgroundMusicSlide(state, action);
            _asfAction_closeAddNewSlideModal(state, action);
        },
        asfAction_addTTSImageSlide(state, action){
            _asfAction_addTTSImageSlide(state, action);
            _asfAction_closeAddNewSlideModal(state, action);
        },
        asfAction_addTTSVideoSlide(state, action){
            _asfAction_addTTSVideoSlide(state, action);
            _asfAction_closeAddNewSlideModal(state, action);
        },
        asfAction_addNoTTSImageSlide(state, action){
            _asfAction_addNoTTSImageSlide(state, action);
            _asfAction_closeAddNewSlideModal(state, action);
        },
        asfAction_addNoTTSVideoSlide(state, action){
            _asfAction_addNoTTSVideoSlide(state, action);
            _asfAction_closeAddNewSlideModal(state, action);
        },
        asfAction_removeSlide(state, action){
            state.project.slides.splice(action.payload, 1);
        },
        asfAction_moveSlideUp(state, action){
            const index = action.payload;
            const indexMinus1 = index - 1;
            swapSlides(indexMinus1, index, state, action);
        },
        asfAction_moveSlideDown(state, action){
            const index = action.payload;
            const indexPlus1 = index + 1;
            swapSlides(index, indexPlus1, state, action);
        },
        asfAction_copyMediaFile(state, action){
            const index = action.payload;
            for (const [i, slide] of state.project.slides.entries()){
                if (i === index){
                    slide.copiedMedia = !slide.copiedMedia;
                }else{
                    slide.copiedMedia = false;
                }
            }
        },
        asfAction_pasteMediaFile(state, action){
            const index = action.payload;
            const copiedSlide = getCopiedSlide(state, action);
            const slide = state.project.slides[index];
            if (copiedSlide.slideType === 1){
                slide.soundFileId = copiedSlide.soundFileId;
                slide.soundFileS3Key = copiedSlide.soundFileS3Key;
            }else if (copiedSlide.slideType === 2 || copiedSlide.slideType === 4){
                slide.imageFileId = copiedSlide.imageFileId;
                slide.imageFileS3Key = copiedSlide.imageFileS3Key;
            }else if (copiedSlide.slideType === 3 || copiedSlide.slideType === 5){
                slide.videoFileId = copiedSlide.videoFileId;
                slide.videoFileS3Key = copiedSlide.videoFileS3Key;
                slide.videoFileDuration = copiedSlide.videoFileDuration
            }
        },
        asfAction_removeMediaFile(state, action){
            const slide = state.project.slides[action.payload];
            slide.soundFileId = null;
            slide.soundFileS3Key = null;
            slide.imageFileId = null;
            slide.imageFileS3Key = null;
            slide.videoFileId = null;
            slide.videoFileS3Key = null;
            slide.copiedMedia = false;
        },
        asfAction_updateLoopBackgroundMusic(state, action){
            const loopBackgroundMusic = action.payload.loopBackgroundMusic;
            const index = action.payload.index;
            state.project.slides[index].loopBackgroundMusic = loopBackgroundMusic;
        },
        asfAction_changeVoiceOnModalIndex(state, action){
            const voice = action.payload;
            const slide = state.project.slides[state.modalIndex];
            slide.voice = voice;
            _asfAction_closeChangeVoiceModal(state, action);
        },
        asf_updateAddCustomVoiceLabel(state, action) {
            state.addCustomVoice.label = action.payload;
        },
        asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile(state, action){
            const voiceAudioFile = action.payload;
            if (voiceAudioFile === null){
                state.manageCustomVoices_voiceAudioFile = null;
            }else if (state.manageCustomVoices_voiceAudioFile !== null &&
                state.manageCustomVoices_voiceAudioFile.id === voiceAudioFile.id   
            ){
                state.manageCustomVoices_voiceAudioFile = null;
            }else{
                state.manageCustomVoices_voiceAudioFile = voiceAudioFile;
            }
        },
        asfAction_addCustomVoicePage_playPauseVoiceAudioFile(state, action){
            const voiceAudioFile = action.payload;
            if (voiceAudioFile === null){
                state.addCustomVoice.playingVoiceAudioFile = null;
            }else if (state.addCustomVoice.playingVoiceAudioFile !== null &&
                state.addCustomVoice.playingVoiceAudioFile.id === voiceAudioFile.id   
            ){
                state.addCustomVoice.playingVoiceAudioFile = null;
            }else{
                state.addCustomVoice.playingVoiceAudioFile = voiceAudioFile;
            }
        },
        asfAction_updateOriginalText(state, action){
            const {originalText, index} = action.payload;
            const charCount = originalText.length;
            const wordCount = getWordCount(originalText);
            
            state.project.slides[index].originalText = originalText;
            state.project.slides[index].wordCount = wordCount;
            state.project.slides[index].charCount = charCount;
        },
        asfAction_updateSubtitlesOnField(state, action){
            const index = action.payload.index;
            const subtitlesOn = action.payload.subtitlesOn;
            state.project.slides[index].subtitlesOn = subtitlesOn;
        },
        asfAction_updateLargeSubtitlesField(state, action){
            const index = action.payload.index;
            const largeSubtitles = action.payload.largeSubtitles;
            state.project.slides[index].largeSubtitles = largeSubtitles;
        },
        asfAction_updateSubtitlesPositionField(state, action){
            const index = action.payload.index;
            const subtitlesPosition = action.payload.subtitlesPosition;
            state.project.slides[index].subtitlesPosition = subtitlesPosition;
        },
        asfAction_updateDurationField(state, action){
            const index = action.payload.index;
            const duration = action.payload.duration;
            state.project.slides[index].duration = duration;
        },
        asfAction_updatePlayVideoTillEndField(state, action){
            const index = action.payload.index;
            const playVideoTillEnd = action.payload.playVideoTillEnd;
            state.project.slides[index].playVideoTillEnd = playVideoTillEnd;
        },
        asfAction_updateSilenceIntervalAtEndField(state, action){
            const index = action.payload.index;
            const silenceIntervalAtEnd = action.payload.silenceIntervalAtEnd;
            state.project.slides[index].silenceIntervalAtEnd = silenceIntervalAtEnd;
        },
        asfAction_updateTransitionOpenClose(state, action) {
            const index = action.payload.index;
            const transitionUIOpen = action.payload.transitionUIOpen;
            state.project.slides[index].transitionUIOpen = transitionUIOpen;
        },
        asfAction_updateTransitionTypeId(state, action){
            const index = action.payload.index;
            const transitionTypeId = action.payload.transitionTypeId;
            state.project.slides[index].transitionTypeId = transitionTypeId;
            /*  
                1 - cut - 
                2 - fadeInOut - color, 
                3 - dissolve
                4 - iris - color, fromX, fromY, toX, toY
                5 - wipe - direction
                6 - whipPan - direction
                7 - zoomTransition - 
                8 - barrelRoll -
            */
            if (transitionTypeId === 1 || 
                transitionTypeId === 3 ||
                transitionTypeId === 7 ||
                transitionTypeId === 8){
                state.project.slides[index].color = null;
                state.project.slides[index].direction = null;
                state.project.slides[index].fromX = null;
                state.project.slides[index].fromY = null;
                state.project.slides[index].toX = null;
                state.project.slides[index].toY = null;
            }else if(transitionTypeId === 2){
                state.project.slides[index].color = state.project.slides[index].color===null?'000000':state.project.slides[index].color;
                state.project.slides[index].direction = null;
                state.project.slides[index].fromX = null;
                state.project.slides[index].fromY = null;
                state.project.slides[index].toX = null;
                state.project.slides[index].toY = null;
            }else if(transitionTypeId === 4){
                state.project.slides[index].color = state.project.slides[index].color===null?'000000':state.project.slides[index].color;
                state.project.slides[index].direction = state.project.slides[index].direction===null?6:state.project.slides[index].direction;
                state.project.slides[index].fromX = state.project.slides[index].fromX===null?1920:state.project.slides[index].fromX;
                state.project.slides[index].fromY = state.project.slides[index].fromY===null?1080:state.project.slides[index].fromY;
                state.project.slides[index].toX = state.project.slides[index].toX===null?1920:state.project.slides[index].toX;
                state.project.slides[index].toY = state.project.slides[index].toY===null?1080:state.project.slides[index].toY;
            }else if(transitionTypeId === 5 ||
                    transitionTypeId === 6){
                state.project.slides[index].color = null;
                state.project.slides[index].direction = state.project.slides[index].direction===null?6:state.project.slides[index].direction;
                state.project.slides[index].fromX = null;
                state.project.slides[index].fromY = null;
                state.project.slides[index].toX = null;
                state.project.slides[index].toY = null;
            }
        },
        asfAction_updateColor(state, action){
            const index = action.payload.index;
            const color = action.payload.color;
            state.project.slides[index].color = color;
        },
        asfAction_updateFrom(state, action){
            const index = action.payload.index;
            const x = action.payload.x;
            const y = action.payload.y;
            state.project.slides[index].fromX = Math.trunc(x);
            state.project.slides[index].fromY = Math.trunc(y);
        },
        asfAction_updateTo(state, action){
            const index = action.payload.index;
            const x = action.payload.x;
            const y = action.payload.y;
            state.project.slides[index].toX = Math.trunc(x);
            state.project.slides[index].toY = Math.trunc(y);
        },
        asfAction_updateDirection(state, action){
            const index = action.payload.index;
            const direction = action.payload.direction;
            state.project.slides[index].direction = direction;
        },
        asfAction_UpdateFragmentOriginalText(state, action){
            const slideIndex = action.payload.slideIndex;
            const fragmentIndex = action.payload.fragmentIndex;
            const originalText = action.payload.originalText;
            state.project.slides[slideIndex].fragments[fragmentIndex].originalText = originalText;
            state.project.slides[slideIndex].fragments[fragmentIndex].charCount = originalText.length;
        },
        asfAction_UpdateFragmentAiSpeakableText(state, action){
            const slideIndex = action.payload.slideIndex;
            const fragmentIndex = action.payload.fragmentIndex;
            const aiSpeakableText = action.payload.aiSpeakableText;
            state.project.slides[slideIndex].fragments[fragmentIndex].aiSpeakableText = aiSpeakableText;
            state.project.slides[slideIndex].fragments[fragmentIndex].aiSpeakableTextCharCount = aiSpeakableText.length;
        },
        asfAction_addFragment(state, action){
            const slideIndex = action.payload.slideIndex;
            const fragmentIndex = action.payload.fragmentIndex;
            const fragments = state.project.slides[slideIndex].fragments;

            const previousFragment = fragments[fragments.length - 1];
            const newFragment = {
                id: null,
                projectId: previousFragment.projectId,
                slideId: previousFragment.slideId,
                voiceId: previousFragment.voiceId,
                voice: previousFragment.voice,
                imageFileId: previousFragment.imageFileId,
                imageFileS3Key: previousFragment.imageFileS3Key,
                videoFileId: previousFragment.videoFileId,
                videoFileS3Key: previousFragment.videoFileS3Key,
                s3key: null,
                originalText: '',
                aiSpeakableText: '',
                msFragmentBegin: 0,
                msFragmentEnd: 0,
                duration: 0,
                wordCount: 0,
                charCount: 0,
                aiSpeakableTextCharCount: 0,
                subtitlesOn: previousFragment.subtitlesOn,
                largeSubtitles: previousFragment.largeSubtitles,
                subtitlesPosition: previousFragment.subtitlesOn,
            }

            _.merge(newFragment, fragmentUIAndErrorProperties);

            fragments.splice(fragmentIndex + 1, 0, newFragment);
            state.project.slides[slideIndex].fragments = fragments;
        },
        asfAction_removeFragment(state, action){
            const slideIndex = action.payload.slideIndex;
            const fragmentIndex = action.payload.fragmentIndex;
            const fragments = [...state.project.slides[slideIndex].fragments]

            if (fragments.length > 1){
                fragments.splice(fragmentIndex, 1);
                for (let i = fragmentIndex; i < fragments.length; i++) {
                    fragments[i].indexPosition--;
                }

                state.project.slides[slideIndex].fragments = fragments;  
            }         
        },
    },
    extraReducers(builder){
        //PAGES
        builder.addCase(pagesAction_openPage, (state, action) => {
            if (action.payload){
                if(action.payload === 'AddCustomVoicePage'){
                    resetAddCustomVoice(state, action);
                    resetManageCustomVoicesErrorStates(state, action);
                    state.isChangeVoiceModalOpen = false;
                }else if (action.payload === 'AddSlidesPage' || action.payload === 'FineTuneCompletedProjectsPage'){
                    resetAddSlidesPage(state, action);
                }
            }
        });
        builder.addCase(pagesAction_cleanStackOpenTwoPages, (state, action) => {
            if (action.payload && _.isEqual(action.payload, ['DraftProjectsPage', 'AddSlidesPage'])){
                resetAddSlidesPage(state, action);
            }
        })

        //LOGIN/RESET - get Voices
        builder.addCase(apiAction_endSubmitEmailPassword, (state, action) => {
            const res = action.payload.res;
            if(res.success && res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
            }
        });
        builder.addCase(apiAction_endResetPassword2, (state, action) => {
            const res = action.payload.res;
            if(res.success && res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
            }
        });
        
        //addSlidesPage
        builder.addCase(apiAction_endUploadMediaFile, (state, action) => {
            resetProjectErrorStates(state, action);
            resetAddCustomVoiceErrorStates(state, action);
            
            const res = action.payload.res;
            const index = action.payload.index;
            if(action.payload.addSlidesForm && res.success && res.success === true){
                removeAllCopiedSlides(state, action);
                const slide = state.project.slides[index];
                if (slide.slideType === 1){    
                    const soundFile = action.payload.addSlidesForm.res.data.soundFile;
                    slide.soundFileId = soundFile.id;
                    slide.soundFileS3Key = soundFile.s3key;
                    slide.soundFileDuration = soundFile.duration
                }else if (slide.slideType === 2 || slide.slideType === 4){
                    const imageFile = action.payload.addSlidesForm.res.data.imageFile;
                    slide.imageFileId = imageFile.id;
                    slide.imageFileS3Key = imageFile.s3key;
                }else if (slide.slideType === 3 || slide.slideType === 5){
                    const videoFile = action.payload.addSlidesForm.res.data.videoFile;
                    slide.videoFileId = videoFile.id;
                    slide.videoFileS3Key = videoFile.s3key;
                    slide.videoFileDuration = videoFile.duration
                }
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                state.project.error.topErrorErrorMessage = `Slide ${index + 1}: ${res.error_description}`;
                if(error === 'SOUND_FILE_INVALID'){
                    state.project.slides[index].error.soundFileId = res.error_description;
                }else if(error === 'IMAGE_FILE_INVALID'){
                    state.project.slides[index].error.imageFileId = res.error_description;
                }else if (error === 'VIDEO_FILE_INVALID'){
                    state.project.slides[index].error.videoFileId = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endSaveToDraft, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                //resetAddSlidesPage is called in jsonProjectToStateProject
                jsonProjectToStateProject(state, action, action.payload.addSlidesForm.res.data.project);
                jsonVoicesToStateVoices(state, action, res.data.voices);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                resetAddCustomVoiceErrorStates(state, action);
                resetProjectErrorStates(state, action);
                if(error === 'PROJECT_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_ID_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_TITLE_INVALID'){
                    state.project.error.title = res.error_description;
                }else if(error === 'SLIDES_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'SLIDE_INVALID'){
                    const index = res.index;
                    const type = res.type;
                    const error_description = res.error_description

                    state.project.error.topErrorErrorMessage = `Slide ${index + 1}: ${error_description}`;
                    if (type === ''){
                        state.project.slides[index].error['slide'] = error_description;
                    }else{
                        state.project.slides[index].error[type] = error_description;
                    }
                }else{
                    state.project.error.topErrorErrorMessage = res.error_description;
                }
            }

        });
        builder.addCase(apiAction_endSaveToDraftLoadBasket, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                //resetAddSlidesPage is called in jsonProjectToStateProject
                jsonProjectToStateProject(state, action, action.payload.addSlidesForm.res.data.project);
                jsonVoicesToStateVoices(state, action, res.data.voices);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                resetProjectErrorStates(state, action);
                resetAddCustomVoiceErrorStates(state, action);
                if(error === 'PROJECT_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_ID_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_TITLE_INVALID'){
                    state.project.error.title = res.error_description;
                }else if(error === 'SLIDES_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'SLIDE_INVALID'){
                    const index = res.index;
                    const type = res.type;
                    const error_description = res.error_description

                    state.project.error.topErrorErrorMessage = `Slide ${index + 1}: ${error_description}`;
                    if (type === ''){
                        state.project.slides[index].error['slide'] = error_description;
                    }else{
                        state.project.slides[index].error[type] = error_description;
                    }
                }else{
                    state.project.error.topErrorErrorMessage = res.error_description;
                }
            }
        });
        

        //draftProjectsPage
        builder.addCase(apiAction_endGetDraftProjects, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                //AUDIO_ID_INVALID
                state['topErrorFieldState'] = 'failed-server-test';
                state['topErrorErrorMessage'] = res.error_description;
            }
        });
        builder.addCase(apiAction_endGetDraftProject, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
                jsonProjectToStateProject(state, action, res.data.project);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                resetProjectErrorStates(state, action);
                resetAddCustomVoiceErrorStates(state, action);
            }
        });

        //DELETE DRAFT PROJECT
        builder.addCase(apiAction_endDeleteProjectLoadDraftProjects, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && res.success && res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                //AUDIO_ID_INVALID
                //AUDIO_STATUS_ARRAY_ID_INVALID
                state['topErrorFieldState'] = 'failed-server-test';
                state['topErrorErrorMessage'] = res.error_description;
            }
        });

        //COPY PROJECT
        builder.addCase(apiAction_endGetCopyProject, (state, action) => {
            const res = action.payload.res;
            if(res.success && res.success === true){
                const voices = action.payload.addSlidesForm.res.data.voices;
                resetAddSlidesPage(state, action);
                jsonVoicesToStateVoices(state, action, voices);
                jsonProjectToStateProject(state, action, action.payload.addSlidesForm.res.data.project);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                //AUDIO_ID_INVALID
                //AUDIO_STATUS_ARRAY_ID_INVALID
                state['topErrorFieldState'] = 'failed-server-test';
                state['topErrorErrorMessage'] = res.error_description;
            }
        });

        //FINE TUNE PROJECT
        //----called in FineTuneProjectPage & CompletedProjectPage
        builder.addCase(apiAction_endGetFineTuneProject, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && res.success && res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
                jsonProjectToStateProject(state, action, res.data.project)
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                resetProjectErrorStates(state, action);
                resetAddCustomVoiceErrorStates(state, action);
                const error = res.error;
                if(error === 'PROJECT_ID_INVALID'){
                    const projectId = res.projectId;
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else{
                    state.project.error.topErrorErrorMessage = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endRevertToOriginalFineTuneProjectStatus, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && res.success && res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
                jsonProjectToStateProject(state, action, res.data.project)
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                resetProjectErrorStates(state, action);
                resetAddCustomVoiceErrorStates(state, action);
                const error = res.error;
                if(error === 'PROJECT_ID_INVALID'){
                    const projectId = res.projectId;
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else{
                    state.project.error.topErrorErrorMessage = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endSaveFineTuneProjectStatus, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && res.success && res.success === true){
                jsonVoicesToStateVoices(state, action, res.data.voices);
                jsonProjectToStateProject(state, action, res.data.project)
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                resetProjectErrorStates(state, action);
                resetAddCustomVoiceErrorStates(state, action);
                const error = res.error;
                if(error === 'PROJECT_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_ID_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_TITLE_INVALID'){
                    state.project.error.title = res.error_description;
                }else if(error === 'SLIDES_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'SLIDE_INVALID'){
                    const index = res.index;
                    const type = res.type;
                    const error_description = res.error_description;
                    
                    state.project.error.topErrorErrorMessage = `Slide ${index + 1}: ${error_description}`;
                    if (type === ''){
                        state.project.slides[index].error['slide'] = error_description;
                    }else{
                        state.project.slides[index].error[type] = error_description;
                    }
                }else if(error === 'FRAGMENT_INVALID'){
                    const slideIndex = res.slideIndex;
                    const index = res.index;
                    const type = res.type;
                    const error_description = res.error_description;

                    console.log('hhh');

                    state.project.error.topErrorErrorMessage = `Slide ${slideIndex + 1}: ${error_description}`;
                    if (type === ''){
                        state.project.slides[slideIndex].fragments[index].error['fragment'] = error_description;
                    }else{
                        state.project.slides[slideIndex].fragments[index].error[type] = error_description;
                    }
                }else{
                    state.project.error.topErrorErrorMessage = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endSaveSubmitFineTuneProjectStatus, (state, action) => {
            resetProjectErrorStates(state, action);
            resetAddCustomVoiceErrorStates(state, action);
            
            const res = action.payload.res;
            if(action.payload.addSlidesForm && res.success && res.success === true){
                state.isSavedSumittedRemakeProjectOpen = true;
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                if(error === 'PROJECT_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_ID_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'PROJECT_TITLE_INVALID'){
                    state.project.error.title = res.error_description;
                }else if(error === 'SLIDES_INVALID'){
                    state.project.error.topErrorErrorMessage = res.error_description;
                }else if(error === 'SLIDE_INVALID'){
                    const index = res.index;
                    const type = res.type;
                    const error_description = res.error_description
                    
                    state.project.error.topErrorErrorMessage = `Slide ${index + 1}: ${error_description}`;
                    if (type === ''){
                        state.project.slides[index].error['slide'] = error_description;
                    }else{
                        state.project.slides[index].error[type] = error_description;
                    }
                }else if(error === 'FRAGMENT_INVALID'){
                    const slideIndex = res.slideIndex;
                    const index = res.index;
                    const type = res.type;
                    const error_description = res.error_description

                    state.project.error.topErrorErrorMessage = `Slide ${slideIndex + 1}: ${error_description}`;
                    if (type === ''){
                        state.project.slides[slideIndex].fragments[index].error['fragment'] = error_description;
                    }else{
                        state.project.slides[slideIndex].fragments[index].error[type] = error_description;
                    }
                }else{
                    state.project.error.topErrorErrorMessage = res.error_description;
                }
            }
        });

        //CUSTOM VOICE
        builder.addCase(apiAction_endUploadVoiceImageFile, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                const voiceImageFile = action.payload.addSlidesForm.res.data.voiceImageFile;
                const index = action.payload.addSlidesForm.index;
                state.addCustomVoice.voiceImageFile = voiceImageFile;
                resetAddCustomVoiceErrorStates(state, action);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                resetAddCustomVoiceErrorStates(state, action);
                if(error === 'VOICE_IMAGE_FILE_INVALID'){
                    state.addCustomVoice.topErrorErrorMessage = res.error_description;
                }else{
                    state.addCustomVoice.topErrorErrorMessage = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endUploadVoiceAudioFile, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                const voiceAudioFile = action.payload.addSlidesForm.res.data.voiceAudioFile;
                const index = action.payload.addSlidesForm.index;
                state.addCustomVoice.voiceAudioFiles[index] = voiceAudioFile;
                if(state.addCustomVoice.voiceAudioFiles.length < 6 &&
                    state.addCustomVoice.voiceAudioFiles[state.addCustomVoice.voiceAudioFiles.length - 1].id !== null){
                    state.addCustomVoice.voiceAudioFiles.push({id: null, creationDate: null, s3key: null});
                }
                resetAddCustomVoiceErrorStates(state, action);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                resetAddCustomVoiceErrorStates(state, action);
                if(error === 'VOICE_AUDIO_FILE_INVALID'){
                    state.addCustomVoice.topErrorErrorMessage = res.error_description;
                }else{
                    state.addCustomVoice.topErrorErrorMessage = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endSaveCustomVoice, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                const voices = action.payload.addSlidesForm.res.data.voices;
                jsonVoicesToStateVoices(state, action, voices);
                state.addCustomVoice.confirmSaveCustomVoiceModalOpen = true;
                resetAddCustomVoiceErrorStates(state, action);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                const error = res.error;
                resetAddCustomVoiceErrorStates(state, action);
                if(error === 'VOICE_INVALID'){
                    state.addCustomVoice.topErrorErrorMessage = res.error_description;
                }else{
                    state.addCustomVoice.topErrorErrorMessage = res.error_description;
                }
            }
        });
        builder.addCase(apiAction_endDeleteCustomVoice, (state, action) => {
            const res = action.payload.res;
            if(action.payload.addSlidesForm && action.payload.res.success && action.payload.res.success === true){
                const voices = action.payload.addSlidesForm.res.data.voices;
                jsonVoicesToStateVoices(state, action, voices);
                resetManageCustomVoicesErrorStates(state, action);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                resetManageCustomVoicesErrorStates(state, action);
                if(res.error === 'VOICE_INVALID'){
                    const voiceId = action.payload.voice.id;
                    for (const voice of state.voices){
                        if (voice.id === voiceId){
                            voice.error.topErrorErrorMessage = res.error_description;
                        }
                    }
                    state.manageCustomVoices_topErrorErrorMessage = res.error_description;
                }
            }
        });
    }
});



const resetAddSlidesPage = (state, action) => {
    state.project = getTemplateProject();
   
    state.isAddNewSlideModalOpen = false;
    state.isChangeVoiceModalOpen = false;
    state.isSavedSumittedRemakeProjectOpen = false;
    state.modalIndex = null;

    resetProjectErrorStates(state, action);
}

const resetProjectErrorStates = (state, action) => {
    state.project.error = {
        topErrorErrorMessage: null,
        title: null,
        slides: null,
    }

    for (let slide of state.project.slides){
        slide = _.merge(slide, slideUIAndErrorProperties);

        if (slide.fragments){
            for (let fragment of slide.fragments){
                fragment = _.merge(fragment, fragmentUIAndErrorProperties);
            }
        }
    }
}

const resetAddCustomVoice = (state, action) => {
    state.addCustomVoice = {
        label: '',
        voiceImageFile: {
            id: null,
        },
        voiceAudioFiles: [
            {id: null, creationDate: null, s3key: null}
        ],


        topErrorErrorMessage: '',
        confirmSaveCustomVoiceModalOpen: false,
        playingVoiceAudioFile: null,
    };
}

const _asfAction_closeAddNewSlideModal = (state, action) => {
    state.isAddNewSlideModalOpen = false;
    state.modalIndex = null;
}

const _asfAction_closeChangeVoiceModal = (state, action) => {
    state.isChangeVoiceModalOpen = false;
    state.modalIndex = null;
}

const slideUIAndErrorProperties = {
    //UI specific
    copiedMedia: false,
    transitionUIOpen: false,
    /*
    1 - cut - 
	2 - fadeInOut - color, 
	3 - dissolve
	4 - iris - color, fromX, fromY, toX, toY
	5 - wipe - direction
	6 - whipPan - direction
	7 - zoomTransition - 
	8 - barrelRoll -
    */

    //error
    error: {
        slide: null,
        fragment: null,
        index: null,
        soundFileId: null,
        imageFileId: null,
        videoFileId: null,
        voiceId: null,
        subtitlesOn: null,
        largeSubtitles: null,
        subtitlesPosition: null,
        originalText: null,
        playVideoTillEnd: null,
        silenceIntervalAtEnd: null,
        duration: null,
        loopBackgroundMusic: null,
        transitionTypeId: null,
        color: null,
        direction: null,
        fromX: null,
        fromY: null,
        toX: null,
        toY: null,
    }
}

const fragmentUIAndErrorProperties = {
    //UI specific
    transitionUIOpen: false,
    
    //error
    error: {
        fragment: null,
        originalText: null,
        aiSpeakableText: null
    }
}


const getBackgroundMusicSlideTemplate = () => {
    const slide = {
        slideType: 1,
        soundFileId: null,
        soundFileS3Key: null,
        loopBackgroundMusic: true,
    };

    return _.merge(slide, slideUIAndErrorProperties);
}

const _asfAction_addBackgroundMusicSlide = (state, action) => {
    let slide = getBackgroundMusicSlideTemplate();
    const previousSlide = getPreviousSlideWithSameSlideType(state, action, state.modalIndex, 1);
    
    if (previousSlide !== null){
        slide  = _.merge(slide, previousSlide);
    }

    slide = _.merge(slide, slideUIAndErrorProperties);
    state.project.slides.splice(state.modalIndex, 0, slide);
}

const getTTSImageSlideTemplate = (state, action) => {
    const slide = {
        slideType: 2,
        imageFileId: null,
        imageFileS3Key: null,
        voice: state.voices[0],
        subtitlesOn: true,
        largeSubtitles: true,
        subtitlesPosition: 2,
        originalText: '',
        silenceIntervalAtEnd: 0,
        //transition
        transitionTypeId: 1,
        color: '000000',
        direction: 6,
        fromX: 1920,
        fromY: 1080,
        toX: 1920,
        toY: 1080,
    }

    return _.merge(slide, slideUIAndErrorProperties);
}

const _asfAction_addTTSImageSlide = (state, action) => {
    let slide = getTTSImageSlideTemplate(state, action);
    const previousSlide = getPreviousSlideWithSameSlideType(state, action, state.modalIndex, 2);
    
    if (previousSlide !== null){
        slide  = _.merge(slide, previousSlide);
        slide.originalText = '';
    }

    slide = _.merge(slide, slideUIAndErrorProperties);
    state.project.slides.splice(state.modalIndex, 0, slide);
}

const getTTSVideoSlideTemplate = (state, action) => {
    const slide = {
        slideType: 3,
        videoFileId: null,
        videoFileS3Key: null,
        voice: state.voices[0],
        subtitlesOn: true,
        largeSubtitles: true,
        subtitlesPosition: 2,
        originalText: '',
        playVideoTillEnd: true,
        silenceIntervalAtEnd: 0,
        //transition
        transitionTypeId: 1,
        color: '000000',
        direction: 6,
        fromX: 1920,
        fromY: 1080,
        toX: 1920,
        toY: 1080,
    }

    return _.merge(slide, slideUIAndErrorProperties);
}

const _asfAction_addTTSVideoSlide = (state, action) => {

    let slide = getTTSVideoSlideTemplate(state, action);
    const previousSlide = getPreviousSlideWithSameSlideType(state, action, state.modalIndex, 3);
    if (previousSlide !== null){
        slide  = _.merge(slide, previousSlide);
        slide.originalText = '';
    }

    slide = _.merge(slide, slideUIAndErrorProperties);
    state.project.slides.splice(state.modalIndex, 0, slide);
}

const getNoTTSImageSlideTemplate = () => {
    const slide = {
        slideType: 4,
        imageFileId: null,
        imageFileS3Key: null,
        duration: 0, //max 60000
        //transition
        transitionTypeId: 1,
        color: '000000',
        direction: 6,
        fromX: 1920,
        fromY: 1080,
        toX: 1920,
        toY: 1080,
    }

    return _.merge(slide, slideUIAndErrorProperties);
}

const _asfAction_addNoTTSImageSlide = (state, action) => {
    let slide = getNoTTSImageSlideTemplate();
    const previousSlide = getPreviousSlideWithSameSlideType(state, action, state.modalIndex, 4);
    if (previousSlide === null){
        slide  = _.merge(slide, previousSlide);
    }

    slide = _.merge(slide, slideUIAndErrorProperties);
    state.project.slides.splice(state.modalIndex, 0, slide);
}

const getNoTTSVideoSlideTemplate = () => {
    const slide = {
        slideType: 5,
        videoFileId: null,
        videoFileS3Key: null,
        duration: 0, //max 60000
        //transition
        transitionTypeId: 1,
        color: '000000',
        direction: 6,
        fromX: 1920,
        fromY: 1080,
        toX: 1920,
        toY: 1080,
    }

    return _.merge(slide, slideUIAndErrorProperties);
}

const _asfAction_addNoTTSVideoSlide = (state, action) => {
    let slide = getNoTTSVideoSlideTemplate();
    const previousSlide = getPreviousSlideWithSameSlideType(state, action, state.modalIndex, 5);
    if (previousSlide === null){
        slide  = _.merge(slide, previousSlide);
    }

    slide = _.merge(slide, slideUIAndErrorProperties);
    state.project.slides.splice(state.modalIndex, 0, slide);
}

const getPreviousSlideWithSameSlideType = (state, action, index, slideType) => {
    let slide = null;
    let i = index - 1;
    while (i > 0){
        const currentSlide = state.project.slides[i];
        if(currentSlide.slideType === slideType){
            slide = currentSlide;
            break;
        }
        i--;
    }
    return slide;
}

const jsonVoicesToStateVoices = (state, action, jsonVoices) => {
    for (const jsonVoice of jsonVoices){
        jsonVoice.error = {
            topErrorErrorMessage: null
        };
    }

    state.voices = jsonVoices;
    return;
}

const swapSlides = (index0, index1, state, action) =>{
    const tempSlide = state.project.slides[index0];
    
    state.project.slides[index0] = state.project.slides[index1];
    state.project.slides[index0].indexPosition = index0;

    state.project.slides[index1] = tempSlide;
    state.project.slides[index1].indexPosition = index1;
}

const removeAllCopiedSlides = (state, action) => {
    for (const slides of state.project.slides){
        slides.copiedMedia = false;
    }
}

const resetManageCustomVoicesErrorStates = (state, action) => {
    state.manageCustomVoices_topErrorErrorMessage = '';
    for (const voice of state.voices){
        voice.error = {
            topErrorErrorMessage: null
        };
    }
}

const resetAddCustomVoiceErrorStates = (state, action) => {
    state.addCustomVoice.topErrorErrorMessage = null;
}

const getCopiedSlide = (state, action) => {
    for (const slide of state.project.slides){
        if (slide.copiedMedia === true){
            return slide;
        }
    }
    return null;
}

const jsonProjectToStateProject = (state, action, jsonProject) => {
    const oldProject = _.cloneDeep(state.project);

    //_.merge(a, b, c)
    //parameter 2 is overwritten with parameter 3.
    const newProject = _.merge({}, getTemplateProject(), jsonProject);
    
    for (const [index, slide] of newProject.slides.entries()){
        //merge slide with default error
        _.merge(slide, slideUIAndErrorProperties);

        //this is added back in for transition UI to persist
        if (oldProject.slides.length < index + 1 ||
            oldProject.slides[index].transitionUIOpen === null || 
            oldProject.slides[index].transitionUIOpen === undefined){
                slide.transitionUIOpen = false;
        }else{
            slide.transitionUIOpen = oldProject.slides[index].transitionUIOpen;
        }

        //voice
        if (slide.voiceId !== null){
            slide.voice = getVoiceById(state, action, slide.voiceId);
        }
        
        for(const fragment of slide.fragments){
            _.merge(fragment, fragmentUIAndErrorProperties);
            fragment.charCount = fragment.originalText.length;
            fragment.aiSpeakableTextCharCount = fragment.aiSpeakableText.length;
        }
    }

    resetAddSlidesPage(state, action);
    state.project = newProject;
}

const getVoiceById = (state, action, voiceId) => {
    for (const voice of state.voices){
        if(voice.id === voiceId){
            return voice;
        }
    }

    return state.voices[0];
}



export const {
    asfAction_updateTitleField,
    asfAction_openAddNewSlideModal, asfAction_closeAddNewSlideModal,
    asfAction_openChangeVoiceModal, asfAction_closeChangeModal, asfAction_changeVoiceOnModalIndex,
    asfAction_addBackgroundMusicSlide, 
    asfAction_addTTSImageSlide, asfAction_addTTSVideoSlide, 
    asfAction_addNoTTSImageSlide, asfAction_addNoTTSVideoSlide,
    asfAction_removeSlide,
    asfAction_moveSlideUp, asfAction_moveSlideDown,
    asfAction_copyMediaFile, asfAction_pasteMediaFile, asfAction_removeMediaFile,
    asfAction_updateLoopBackgroundMusic,
    asfAction_manageCustomVoicesPage_playPauseVoiceAudioFile,
    asfAction_addCustomVoicePage_playPauseVoiceAudioFile,
    asfAction_updateOriginalText,
    asfAction_updateSubtitlesOnField,
    asfAction_updateLargeSubtitlesField,
    asfAction_updateSubtitlesPositionField,
    asfAction_updateDurationField,
    asfAction_updatePlayVideoTillEndField,
    asfAction_updateSilenceIntervalAtEndField,
    asfAction_updateTransitionOpenClose,
    asfAction_updateTransitionTypeId,
    asfAction_updateColor,
    asfAction_updateFrom, asfAction_updateTo,
    asfAction_updateDirection,
    asfAction_UpdateFragmentOriginalText,
    asfAction_UpdateFragmentAiSpeakableText,
    asfAction_addFragment,
    asfAction_removeFragment,

    //addCustomVoice
    asf_updateAddCustomVoiceLabel
} = addSlidesFormSlice.actions;
export const addSlidesFormReducer = addSlidesFormSlice.reducer;
