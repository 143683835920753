import { FiArrowLeft } from "react-icons/fi";
import Button from "./buttons/Button";

function BackPageTopBar({ onClick }){
    return (
        <div className="w-full py-4 px-6 absolute top-0 left-0">
            <Button round onClick={onClick}><FiArrowLeft className="w-8 h-8" /></Button>
        </div>
    )
}

export default BackPageTopBar;