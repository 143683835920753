import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { FiChevronRight, FiUser, FiLoader } from 'react-icons/fi';
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps4_smile.svg";
import { pagesAction_closeFourPages } from "../store";
import { getBackgroundCSS } from "../components/css";
 
 function LoginRegisterResetConfirmationPage({count}){
    const dispatch = useDispatch();

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const handleSubmit = async () => {
        const payload = {sipfAction: 'reset_to_initial'};
        const action = pagesAction_closeFourPages(payload); dispatch(action);
    }


    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleSubmit} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep className="w-80" />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Password Succesfully Reset. Signed In.
                </div>
                <div className="w-full bg-white 
                rounded-[20px] p-6 text-center mt-2.5 border-black border-2">
                    <div className="font-montserrat font-medium text-[1.063rem mb-3">
                        You've succesfully reset your password and are signed in. You can now continue what you were doing.
                    </div>
                    <Button primary onClick={handleSubmit}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Let's Go!
                        </div>
                        <FiChevronRight className="justify-self-end h-6 w-6"/>
                    </Button>
                </div>
            </div>
        </div>
    )
 }

 export default LoginRegisterResetConfirmationPage;