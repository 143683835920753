import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import ReactPlayer from 'react-player';
import classNames from "classnames";
import OpenMenuTopBar from "../components/OpenMenuTopBar";
import { pagesAction_cleanStackOpenTwoPages, ppAction_SetAudio } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeep3.svg";
import { ReactComponent as PlayButton } from "../images/svg/play_button.svg";
import { ReactComponent as PauseButton } from "../images/svg/pause_button.svg";
import { ReactComponent as BookOpen } from "../images/svg/book_open.svg";
import { ReactComponent as FileText } from "../images/svg/assets.svg";
import { ReactComponent as Film } from "../images/svg/film.svg";
import { pagesAction_openPage } from "../store";
import Button from "../components/buttons/Button";
import { FiChevronRight } from "react-icons/fi";
import { pagesAction_switchPage } from "../store/actions";
import { getBackgroundCSS } from "../components/css";

function PrivacyPage({count}){    
    const dispatch = useDispatch();

    const pricingPageState = useSelector((state) => {return state.pricingPage;});

    let backgroundClass = getBackgroundCSS(count, 'bg-yellow-1');

    const openMenuPage = () =>{
        const action = pagesAction_openPage('MenuPage'); dispatch(action);
    }

    const handleAddSlidesPageClick = () => {
        const pageName = ['DraftProjectsPage', 'AddSlidesPage'];
        const action = pagesAction_cleanStackOpenTwoPages(pageName); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <OpenMenuTopBar onClick={openMenuPage} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center space-y-2">
                <OpenPeep />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl p-6 text-center rounded-[20px] ">
                    <div>
                        Privacy Policy
                    </div> 
                </div>
                <div className="w-full bg-white font-montserrat 
                text-bg p-6 text-start rounded-[20px] ">
                    <div>
                    Your privacy matters to us, leading us to develop this Privacy Policy. Along with our Terms of Service, and any other related documents, this Privacy Policy clarifies the data we gather, its usage, sharing methods, international transfers, and your management rights over it. Kindly go through this to comprehend our stance and procedures about your personal data and its handling.
                    <br /><br />
                    For users from the European Union (EU), European Economic Area (EEA), and Switzerland, commonly termed as “Designated Countries”, we advise referencing our GDPR Policy, highlighting their rights under the General Data Protection Regulation.
                    <br /><br />
                    Definitions<br />
                    In this Privacy Policy, the following definitions apply:<br />
                    any reference to 'SAYTHATBACK.COM Group', 'us', 'we' or 'our' means the Sites’ proprietary owners;<br />
                    Services mean the Sites, SAYTHATBACK.COM-branded apps, Google Slides add-on, Text to speech and other SAYTHATBACK.COM-related sites, apps, communications and services.<br />
                    Site means a site operated by us and includes each of https//saythatback.com, and any other domain that we may use from time to time<br />
                    <br /><br />
                    **Collection of Non-Personal Information**Every time you access our Site, we gather non-personal details like the frequently visited sections, the duration, and frequency of visits. This data is utilized in its aggregated form. We might scrutinize this non-personal data and share the consolidated version with third parties.
                    <br /><br />
                    **Acquisition of Personal Information**<br />
                    - **Service Sign-Up**: By registering and setting up an account, you provide us with:<br />
                    - Email address<br />
                    - **Making Purchases**: If you transact or intend to on our Site, we gather:<br />
                    - Name<br />
                    - Email address<br />
                    - Payment or credit card details (Note: We don’t see your full credit card number or CVC; our payment gateway manages that).<br />
                    - **Creating Audio/Video Content**: If you produce content with our services, we collect:<br />
                    - All images, videos, audio, or text content you provide during content creation.<br />
                    - **Optional Data**: We might request you for more details which might include:<br />
                    - Your social media account links (like Facebook, Instagram, Twitter, and YouTube)<br />
                    - Insights into your professional domain and the nature of your organization.<br />
                    <br /><br />
                    **Information from Third Parties**<br />
                    Sometimes, we obtain data (possibly personal) about you from other sources. If you interact with our Site using third-party platforms like Facebook or Google, they might share certain data regarding your activity on their platform. The data could encompass your user ID, access tokens, details you've permitted the third party to disclose, and data you've made public on their platform. If you wish to detach your third-party account from our Site, modify your settings on the third-party service.
                    <br /><br />
                    **Device Details**<br />
                    As is typical with many sites, our Site automatically assembles specific data, including details about your web browser, IP address, and timezone, through 'cookies', web beacons, and other technologies. This information can be categorized as personal data. As you navigate our Site, we also gather information about pages or products you view, referral sources to our Site, and your Site interactions. This is referred to as 'Device Information'. The technologies used for collecting Device Information include:<br />
                    - **Cookies**: These are data packets stored on your device.<br />
                    - **Log Files**: These keep a record of activities on the Site and gather data like your IP address, browser type, and other related details.<br />
                    - **Web Beacons, Tags, and Pixels**: These digital files document your Site browsing patterns.
                    <br /><br />
                    If you wish to manage or reset your cookies, utilize your web browser settings. Opting to reject all cookies might impact some functionalities of our Site.How do we use your personal information?
                    <br /><br />
                    **Utilization of Your Information**<br />
                    **Service Provision**: Your data aids us in offering our products and services. It facilitates account creation and sign-in on our Site, enables access to your videos, syncs you across multiple devices and services, and processes your transactions.<br />
                    **Communication**: Your contact details allow us to relay crucial updates about our services and products. Based on your preferences, we might also send you promotional content related to our offerings.<br />
                    **Data Analysis**: To refine our services and understand user behavior on our Site, we analyze the data about your interactions. This helps in gauging the effectiveness of our marketing endeavors.<br />
                    **Personalized Experience**: By understanding you better, we tailor our Site and offerings to cater to your specific needs.<br />
                    **Ensuring Security**: Your data helps us identify and prevent potential fraudulent activities and check for violations of our Privacy Policy or Terms of Use.<br />
                    **Legal Mandates**: We might disclose your data when:<br />
                    - Required by law or at the behest of legal or governmental entities.<br />
                    - Enforcing our Terms of Use, ensuring the safety of our Site.<br />
                    - Safeguarding the rights, assets, or safety of SAYTHATBACK.COM Group, our patrons, or others.<br />
                    **Based on Your Consent**: Occasionally, we might request your consent to process your data for specific purposes. Once granted, we use it as agreed. Remember, you can retract your consent anytime.<br />
                    **For GDPR-governed data**, our processing bases include:<br />
                    - Your explicit consent.<br />
                    - Processing necessities arising from contracts involving you, such as when you purchase our offerings.<br />
                    - Legitimate business interests, including product enhancements and marketing, unless these interests are overshadowed by your data protection rights.<br />
                    - Adhering to our legal obligations.
                    <br /><br />
                    Should you opt not to provide certain personal data, it could restrict our service provision. You have the liberty to retract consent for personal data processing anytime. To do so, just get in touch as specified in the 'Contact us' section below. Who do we disclose Personally Identifiable Information to?
                    <br /><br />
                    **Sharing Your Personal Information**<br />
                    We are dedicated to preserving the sanctity of your personal information. Except for our dedicated team members, we only share your details with third-party entities that align with the privacy standards stated in this Privacy Policy. Here’s a breakdown:<br />
                    **Vendor Support**: For processing personal information and other associated data, we collaborate with:<br />
                    - Marketing and Communication platforms: Firebase, Google Cloud, Google Analytics, Mixpanel, Toplyne, Clearbit, Sendinblue, Customer.io, Userflow, and Hubspot.<br />
                    - Cloud Services: Google and Amazon Web Services, Inc.<br />
                    - Payment Mechanisms: Stripe Inc.<br />
                    - Software Feedback: G2 Crowd
                    <br /><br />
                    **Legal & Government Entities**: In scenarios mandated, authorized, or permitted by law, we might disclose your information to governmental bodies, regulatory entities, and law enforcement agencies.
                    <br /><br />
                    **Service Assistants**:<br />
                    We leverage third-party services for various functions, ranging from maintenance, analysis, and audit to payments, fraud detection, marketing, and development. These entities access your data solely to the extent required for executing their tasks for us and are contractually bound to maintain its confidentiality and prevent its misuse for other objectives. <br />
                    Entrusting us with your information comes with the assurance that it's handled responsibly and shared only under stringent privacy norms.<br />
                    **Third-Party Involvements and Your Data**
                    <br /><br />
                    **Service Partners**:<br />
                    We collaborate with external agencies to enhance the quality of our Services. This covers areas like maintenance, analysis, audits, payments, detecting fraudulent activities, marketing, and development. These external partners have access to your data only to the extent it's needed to fulfill their responsibilities to us. They are bound by terms ensuring the confidentiality of your information and preventing its misuse for any other objectives.
                    <br /><br />
                    **Legal Obligations**:<br />
                    At times, we might be compelled to reveal your data due to legal obligations like subpoenas or other legal processes. Disclosure will be deemed necessary if:<br />
                    - It's crucial for probing, preventing, or addressing potential or confirmed unlawful activities, or aiding government enforcement agencies.<br />
                    - It's essential for upholding our commitments to you.<br />
                    - It’s required to investigate and protect ourselves against third-party accusations.<br />
                    - It's vital for safeguarding the integrity or security of our Services.<br />
                    - It's needed to guard the rights, security, and welfare of our users, our staff, or the public.
                    <br /><br />
                    While we aim to notify our users about such legal demands, there might be situations where we can't due to legal restrictions, emergencies, or other factors. We reserve the right to challenge demands that seem excessive, vague, or without adequate authorization, though we don’t guarantee opposing all demands.
                    <br /><br />
                    **Transitions in Business**:<br />
                    Your data can also be a part of our assets during business transitions like sales, mergers, or changes in control. Such eventualities might necessitate sharing your information. Any successor entity acquiring us or our assets will maintain the rights to use your data. However, they'll be governed by the terms of this Privacy Policy unless you provide consent for any alterations.
                    <br /><br />
                    **Cross-Border Data Transfers**:<br />
                    - Data is processed globally, and the company relies on legal mechanisms to transfer data.<br />
                    - For users in Designated Countries, data transferred outside these countries to third parties will be subjected to appropriate safeguards.
                    <br /><br />
                    **Modifying and Deleting Your Personal Information**:<br />
                    - Users can update, access, or delete their personal data. Some requests may be limited or rejected based on specified circumstances.<br />
                    - Deleting data may hinder service provision.
                    <br /><br />
                    **Security**:<br />
                    - The company employs security measures like HTTPS. However, complete security can't be guaranteed.
                    <br /><br />
                    **Data Retention**:<br />
                    - Personal data is kept as long as the account is active or as needed for service provision. Some data might be retained in a depersonalized or aggregated form.
                    <br /><br />
                    **Minors**:<br />
                    - The company doesn't intentionally collect data from those below 13. Those under 16 need parental consent.
                    <br /><br />
                    **Links to Third-Party Sites**:<br />
                    - The site may link to third-party content. The company isn't responsible for third-party practices.
                    <br /><br />
                    **Privacy Policy Updates**:<br />
                    - The policy can be modified, with notices given for significant changes. Continued service use after changes implies consent.
                    <br /><br />
                    **Account Closure**:<br />
                    - Data is deleted within 30 days of account termination. Some data might be retained due to legal reasons.
                    <br /><br />
                    It's essential to read the full document to understand the nuances and terms fully. Ensure that you're comfortable with the way your data is handled, especially if you reside in a jurisdiction with strict data protection laws like the EU. Always prioritize your privacy and data security when using online services.
                    <br /><br />
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage;