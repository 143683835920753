import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Button from "../components/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { apiAction_endStripeANDcheckUpdatePaymentIntentStatus, apiAction_startStripeANDcheckUpdatePaymentIntentStatus, bAction_setEmail, bAction_setIsLoading, bAction_setMessage, pagesAction_openPage } from "../store";
import { FiChevronRight } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa";
import { check_update_payment_intent } from '../api/check_update_payment_intent';
import classNames from "classnames";
import { session_setCookie } from "../cookie/SessionCookie";

export default function CheckoutForm() {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  
  const sessionState = useSelector((state) => {return state.session;});
  const apiState = useSelector((state) => {return state.api;});
  const basketState = useSelector((state) => {return state.basketPage;});
  const pagesState = useSelector((state) => {return state.pages;});

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(sessionState.isLoggedIn === false ){
      //load login page
      const action = pagesAction_openPage('LoginRegisterEmailPage2');dispatch(action);
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let action = apiAction_startStripeANDcheckUpdatePaymentIntentStatus(); dispatch(action);
    const resFromStripe = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: BASE_WEBSITE_URL(),
      },
      redirect: 'if_required' 
    });

    const payload = {resFromStripe: resFromStripe};
  
    const error = resFromStripe.error;
    if (error === null || error === undefined ){
      const paymentIntentFromStripe = resFromStripe.paymentIntent;
      const res = await check_update_payment_intent(paymentIntentFromStripe.id, sessionState.token)
      payload['res'] = res;
      const sessionObject = {
        isLoggedIn: true,
        token: res.session.token,
        creationDate: res.session.creationDate,
        expiryDate: res.session.expiryDate
      }
      payload['session'] = sessionObject;
      
      //SET COOKIES
      session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
    }

    action = apiAction_endStripeANDcheckUpdatePaymentIntentStatus(payload); dispatch(action);
  };

  const setEmail = (email) => {
      const action = bAction_setEmail(email); dispatch(action);
  }

  const isLoading = () => {
      return apiState.stripeANDcheckUpdatePaymentIntentStatus === 'waiting';
  }

  const disableAllButtons = () => {
    return isLoading();
  }

  const isSucceeded = () => {
    return basketState.paymentIntentStatus === 'succeeded';
  }

  const message = () => {
      return basketState.checkoutFormMessage;
  }

  const paymentElementOptions = {
    layout: "tabs"
  }

  const getButtonIcon = () => {
    if (isLoading()){
      return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
    }else{
      return <FiChevronRight className="justify-self-end h-6 w-6"/>
    }
  }

  return (
    <form id="payment-form" className='w-full bg-white rounded-[20px] p-6 text-center '>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className={classNames({
        hidden: isSucceeded(),
      })}>
        <Button primary={
          !disableAllButtons() && 
          !isSucceeded() && 
          stripe && 
          elements
        } 
        primaryDisabled={
          disableAllButtons() ||
          isSucceeded() || 
          !stripe || 
          !elements
        }
          onClick={handleSubmit}>
            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                Pay Now
            </div>
            {getButtonIcon()}
        </Button>
      </div>
      {/* Show any error or success messages */}
      {message() && <div id="payment-message">{message()}</div>}
    </form>
  );
}