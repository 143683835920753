import _ from 'lodash';
import Switch from "react-switch";
import classNames from "classnames";
import classNamesDedupe from "classnames/dedupe";
import { FiEdit2, FiMinus, FiMinusCircle, FiMusic, FiPlus, FiUser, FiVolume, FiVolume2, } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { ReactComponent as OpenPeep2 } from "../images/svg/openpeep2.svg";
import Button from "../components/buttons/Button";
import { getBackgroundCSS } from "../components/css";
import { apiAction_endUploadVoiceImageFile, apiAction_endUploadVoiceAudioFile, apiAction_startUploadVoiceImageFile, apiAction_startUploadVoiceAudioFile, asf_updateAddCustomVoiceLabel, pagesAction_closePage, pagesAction_openPage, apiAction_startSaveCustomVoice, apiAction_endSaveCustomVoice, asfAction_addCustomVoicePage_playPauseVoiceAudioFile } from "../store";
import ReactPlayer from "react-player";
import { FaSpinner } from "react-icons/fa";
import { MdChatBubbleOutline } from "react-icons/md";
import { upload_voice_image_file } from "../api/upload_voice_image_file";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import { upload_voice_audio_file } from "../api/upload_voice_audio_file";
import { save_custom_voice } from "../api/save_custom_voice";
import ConfirmCustomVoiceSavedModal from "./modal/ConfirmCustomVoiceSavedModal";

function AddCustomVoicePage({count}){
    const dispatch = useDispatch();

    const asfFormState = useSelector((state) => {return state.addSlidesForm});
    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});

    const addCustomVoice = asfFormState.addCustomVoice;

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const sectionHeaderDivClass = classNames('w-full bg-white font-montserrat font-bold text-xl pt-6 text-start');
    const inputFieldDivClass = classNames('flex border-black border-2 rounded-[20px] items-center h-[3.5rem] px-2.5 w-full');
    const reactPlayerFieldDivClass = classNames('flex items-center h-[3.5rem] px-2.5 w-full');
    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const voiceIconsClass = classNames('h-8 w-8 rounded-[20px] mx-1');
    const textInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] focus:outline-none');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'mt-6', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1', {
        'hidden': false
    })
    const topErrorDivClass = () => {
        let hiddenValue = true;
        if (addCustomVoice.topErrorErrorMessage !== ''){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }
    const topErrorMessage = () => {
        return addCustomVoice.topErrorErrorMessage;
    };

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const isUploadingVoiceImageFile = () => {
        return apiState.uploadVoiceImageFileStatus === 'waiting'
    }

    const isUploadingVoiceAudioFile = () => {
        return apiState.uploadVoiceAudioFileWaitingArray.length > 0;
    }

    const isUploadingVoiceAudioFileAtIndex = (index) => {
        for (const voiceAudioFileId of apiState.uploadVoiceAudioFileWaitingArray){
            if (voiceAudioFileId === index){
                return true;
            }
        }

        return false;
    }

    const isReadyToAddCustomVoice = () => {
        if (addCustomVoice.label.trim() === '' || 
            addCustomVoice.label.trim().length < 3 ||
            addCustomVoice.label.trim().length > 25
        ){
            return false;
        }

        if (addCustomVoice.voiceImageFile.id === null){
            return false;
        }

        let count = 0;
        for (const voiceAudioFile of addCustomVoice.voiceAudioFiles){
            if (voiceAudioFile.id === null){
                break;
            }else{
                count++
            }
        }
        
        if (count < 3 || count > 6){
            return false;
        }

        return true;
    }

    const isSavingCustomVoice = () => {
        return apiState.saveCustomVoiceStatus === 'waiting';
    }

    const isDisableAllButtons = () => {
        return (
            isUploadingVoiceImageFile() ||
            isUploadingVoiceAudioFile() ||
            isSavingCustomVoice()
        );
    }

    const getVoiceImageFileInputPlaceholder = () => {
        if (apiState.uploadVoiceImageFileStatus === 'waiting'){
            return "uploading image"
        }else if (addCustomVoice.voiceImageFile.id !== null){
            return "Upload another image to replace this icon";
        }else{
            return "Select an icon for your custom voice";
        }
        
    }

    const getVoiceAudioFileInputPlaceholder = (index) => {
        if (addCustomVoice.voiceAudioFiles[index].id === null){
            return `${index + 1}) 15 second audio clip`
        }else{
            return `${index + 1}) Upload another audio clip`
        }
        
    }

    const handleSoundFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "audio/mpeg, audio/aac, audio/wav";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadVoiceAudioFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadVoiceAudioFile = async (voiceImageFileFilePath, index) => {
        let action = apiAction_startUploadVoiceAudioFile(index); dispatch(action);
        const res = await upload_voice_audio_file(voiceImageFileFilePath, sessionState.token);
        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'voice_audio_file_uploaded'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endUploadVoiceAudioFile(payload); dispatch(action);
    }

    const handleVoiceImageFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "image/png, image/jpeg, image/webp";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadVoiceImageFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadVoiceImageFile = async (voiceImageFileFilePath, index) => {
        let action = apiAction_startUploadVoiceImageFile(index); dispatch(action);
        const res = await upload_voice_image_file(voiceImageFileFilePath, sessionState.token);
        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'voice_image_file_uploaded'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endUploadVoiceImageFile(payload); dispatch(action);
    }

    const getVoiceAudioFilesJSX = () => {
        const voiceAudioFilesJSX = [];
        asfFormState.addCustomVoice.voiceAudioFiles.forEach((voiceAudioFile, index) => {
            voiceAudioFilesJSX.push(
                <div key={index} className="w-full">
                    <div className={inputFieldDivClass}>
                        {uploadVoiceAudioFileIconSpinner(index)}
                        <input id="soundFile" readOnly={true} type="text" name="soundFile" className={textInputClass} 
                        placeholder={getVoiceAudioFileInputPlaceholder(index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleSoundFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    {getVoiceAudioFilePlayPauseButtonJSX(voiceAudioFile, index)}
                </div>
            )
        });
        return voiceAudioFilesJSX;
    }

    const getVoiceAudioFilePlayPauseButtonJSX = (voiceAudioFile, index) => {
        if (voiceAudioFile.id === null){
            return (
                <></>
            );
        }else{
            return (
                <div className="py-3">
                        <Button plain={true} plainDisabled={isDisableAllButtons()} 
                            className="" onClick={() => {
                                handlePlayPauseClick(voiceAudioFile);
                            }}>
                            {playPauseVoiceAudioFileIcon(voiceAudioFile)}
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Play Audio Sample {index + 1}
                            </div>
                        </Button>
                    </div>
            );
        }
    }

    const playPauseVoiceAudioFileIcon = (voiceAudioFile) => {
        if (addCustomVoice.playingVoiceAudioFile !== null && voiceAudioFile.id === addCustomVoice.playingVoiceAudioFile.id){
            return <FiVolume2 className={featherIconsClass}/>
        }else{
            return <FiVolume className={featherIconsClass}/>
        }
    }

    const uploadVoiceAudioFileIconSpinner = (index) => {
        if (isUploadingVoiceAudioFileAtIndex(index)){
            return <FaSpinner className={classNames(featherIconsClass, 'animate-spin')} />
        }else{
            return <MdChatBubbleOutline className={featherIconsClass}/>
        }
    }

    const handleTitleFieldChange = (e) => {
        const action = asf_updateAddCustomVoiceLabel(e.target.value); dispatch(action);
    }

    const uploadVoiceImageFileIconSpinner = () => {
        if (isUploadingVoiceImageFile()){
            return <FaSpinner className={classNames(featherIconsClass, 'animate-spin')} />
        }else if (addCustomVoice.voiceImageFile.id !== null){
            return <img className={voiceIconsClass}
            src={`https://saythatback-voicefile.s3.amazonaws.com/${addCustomVoice.voiceImageFile.s3key}_128x128.jpg`}/>
        }else{
            return <FiUser className={featherIconsClass}/>
        }
    }

    const isAddCustomVoiceButtonDisabled = () => {
        return (
            //isReadyToAddCustomVoice() === false ||
            isDisableAllButtons() === true
        );
    }

    const handleSaveCustomVoice = async () => {
        //remove the last voiceAudioFile if id === null
        let customVoice = _.cloneDeep(addCustomVoice);
        if (customVoice.voiceAudioFiles[customVoice.voiceAudioFiles.length - 1].id === null){
            customVoice.voiceAudioFiles.pop();
        }

        let action = apiAction_startSaveCustomVoice(); dispatch(action);
        const res = await save_custom_voice(customVoice, sessionState.token);
        const payload = { res: res };
        if (res.success && res.success === true && res.status === 'custom_voice_saved'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endSaveCustomVoice(payload); dispatch(action);
    }

    const getReactPlayerJSX = () => {
        if (addCustomVoice.playingVoiceAudioFile === null){
            return (
                <ReactPlayer 
                    url=''
                    playing={false} 
                />
            )
        }else{
            return (
                <ReactPlayer 
                    playing={true}
                    loop={false}
                    onEnded={() => {handlePlayPauseClick(null);}}
                    url={`https://saythatback-voicefile.s3.amazonaws.com/${addCustomVoice.playingVoiceAudioFile.s3key}.wav`}                    
                />
            );
        }
    }

    const handlePlayPauseClick = (voiceAudioFile) => {
        const action = asfAction_addCustomVoicePage_playPauseVoiceAudioFile(voiceAudioFile); dispatch(action);
    }

    const getTopErrorJSX = () => {
        return <div className={topErrorDivClass()}>{topErrorMessage()}</div>
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep2 />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Create and add a custom voice
                </div> 
                <div className='flex, rounded-[20px], items-center, w-full'>
                    <div className='player-wrapper w-full rounded-[20px] mt-2'>
                        <ReactPlayer
                            url={`https://s3.amazonaws.com/saythatback.com-static-assets/customVoicePage1.mp4`}
                            className='react-player'
                            width="100%"
                            height="100%"
                            playsinline
                            controls
                        />
                    </div>
                </div>
                {getTopErrorJSX()}
                <div className={sectionHeaderDivClass}>
                    Name of your custom voice
                </div>
                <div className={inputFieldDivClass}>
                    <FiEdit2 className={featherIconsClass}/>
                    <input type="text" name="label" className={textInputClass} placeholder="Custom voice name" 
                    value={asfFormState.addCustomVoice.label} onChange={handleTitleFieldChange}/>
                </div>
                <div className={sectionHeaderDivClass}>
                    Add Icon for your custom voice
                </div>
                <div className={inputFieldDivClass}>
                    {uploadVoiceImageFileIconSpinner()}
                    <input id="soundFile" readOnly={true} type="text" name="soundFile" className={textInputClass} 
                    placeholder={getVoiceImageFileInputPlaceholder()}
                    onClick={() => {
                        if(!isDisableAllButtons()){
                            handleVoiceImageFileOnClick();
                        }
                    }} 
                    />
                </div>
                <div className={sectionHeaderDivClass}>
                    Audio samples (of the voice you'd like to use)
                </div>
                {getVoiceAudioFilesJSX()}
                <div className={classNames('bg-white text-center w-full mt-6 gap-5')}>
                    <Button primary={!isAddCustomVoiceButtonDisabled()} primaryDisabled={isAddCustomVoiceButtonDisabled()} 
                        className="" onClick={handleSaveCustomVoice}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Add custom voice
                        </div>
                        <FiPlus className="justify-self-start h-6 w-6"/>
                    </Button>
                </div>  
            </div>
            <ConfirmCustomVoiceSavedModal 
                isOpen={addCustomVoice.confirmSaveCustomVoiceModalOpen}
                handleClosePage={handleClosePage}
            />
            {getReactPlayerJSX()}
        </div> 
    )
}

export default AddCustomVoicePage;