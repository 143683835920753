import { createSlice } from "@reduxjs/toolkit";
import { 
    apiAction_endSubmitEmail,
    apiAction_endSubmitEmailPassword,
    apiAction_endSignOut,
    apiAction_endUploadManualText,
    apiAction_endResetPassword1,
    apiAction_endResetPassword2,
    apiAction_endSubmitUrl, 
    apiAction_endUploadMediaFile,
    apiAction_endSaveToDraft,
    apiAction_endGetDraftProject,
    apiAction_endGetCopyProject,
    apiAction_endDeleteProjectLoadCompletedProjects,
    apiAction_endSaveToDraftLoadBasket,
    apiAction_endCreatePaymentIntent,
    apiAction_endIsSessionValid,
    apiAction_endGetDraftProjects,
    apiAction_endLoadCompletedProjects,
    apiAction_endGetCompletedProject,
    apiAction_endUploadVoiceImageFile,
    apiAction_endUploadVoiceAudioFile,
    apiAction_endSaveCustomVoice,
    apiAction_endGetFineTuneProject
} from "../actions";

const pagesSlice = createSlice({
    name: 'pages',
    initialState: ['PricingPage'], // ['PricingPage'],
    reducers:{
        pagesAction_openPage(state, action){
            _pagesAction_openPage(state, action);
        },
        /*pagesAction_openPageWithPayload(state,action){
            const myAction = {
                type: action.type,
                payload: action.payload.nextPage
            }
            _pagesAction_openPage(state, myAction);
        },
        */
        pagesAction_closePage(state, action){
            _pagesAction_closePage(state, action);
        },
        pagesAction_switchPage(state, action){
            _pagesAction_switchPage(state, action);
        }, 
        pagesAction_closeMenuOpenPage(state, action){
            _pagesAction_closeMenuOpenPage(state, action);
        },
        pagesAction_cleanStackOpenTwoPages(state, action){
            _pagesAction_cleanStackOpenTwoPages(state, action);
        },
        pagesAction_closeTwoPages(state, action){
            _pagesAction_closeTwoPages(state, action);
        },
        pagesAction_closeTwoPages_openPage(state, action){
            _pagesAction_closeTwoPages_openPage(state, action);
        },
        pagesAction_closeThreePages(state, action){
            state.shift();
            state.shift();
            state.shift();
        },
        pagesAction_closeFourPages(state, action){
            _pagesAction_closeFourPages(state, action);
        },
        pagesAction_closeFourPages_openPage(state, action){
            _pagesAction_closeFourPages(state, action);
            const myAction = {
                type: action.type,
                payload: action.payload.nextPage
            }
            _pagesAction_openPage(state, myAction);
        },
        pagesAction_resetToInitialState(state, action){
            _pagesAction_resetToInitialState(state, action);
        },
        pagesAction_logoutAllPages(state, action){
            _pagesAction_logoutAllPages(state, action)
        },pagesAction_resetToInitialStateAndLoadTAC(state, action){
            _pagesAction_resetToInitialState(state, action);
            while (state.length > 0){
                state.pop();
            }
        
            state.push('TACSPage');
        }

    },
    extraReducers(builder){
        builder.addCase(apiAction_endIsSessionValid, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endSubmitEmail, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endSubmitEmailPassword, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endSignOut, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endUploadManualText, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });      
        builder.addCase(apiAction_endResetPassword1, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endResetPassword2, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });        
        builder.addCase(apiAction_endSubmitUrl, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });  
        builder.addCase(apiAction_endUploadMediaFile, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });  
        builder.addCase(apiAction_endSaveToDraft, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });  
        builder.addCase(apiAction_endSaveToDraftLoadBasket, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });  
        builder.addCase(apiAction_endGetDraftProject, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });  
        builder.addCase(apiAction_endGetCompletedProject, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });   
        builder.addCase(apiAction_endGetCopyProject, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });  
        builder.addCase(apiAction_endDeleteProjectLoadCompletedProjects, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        }); 
        builder.addCase(apiAction_endCreatePaymentIntent, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endGetDraftProjects, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endLoadCompletedProjects, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endUploadVoiceImageFile, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endUploadVoiceAudioFile, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endSaveCustomVoice, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
        builder.addCase(apiAction_endGetFineTuneProject, (state, action) => {
            getPagesFromActionPayloadAndSetPages(state, action);
        });
    }
});

const getPagesFromActionPayloadAndSetPages = (state, action) =>{
    if(action.payload.pages){
        const myAction = {
            type: action.type,
            payload: action.payload.pages.nextPage
        }
        if(action.payload.pages.pagesAction === 'pagesAction_openPage'){
            _pagesAction_openPage(state, myAction);
        }else if(action.payload.pages.pagesAction === 'pagesAction_resetToInitialState'){
            _pagesAction_resetToInitialState(state, myAction);
        }else if(action.payload.pages.pagesAction === 'pagesAction_closePage'){
            _pagesAction_closePage(state, myAction);
        }else if(action.payload.pages.pagesAction === 'pagesAction_switchPage'){
            _pagesAction_switchPage(state, myAction);
        }else if (action.payload.pages.pagesAction === 'pagesAction_logoutAllPages'){
            _pagesAction_logoutAllPages(state, action);
        }else if(action.payload.pages.pagesAction === 'pagesAction_cleanStackOpenTwoPages'){
            const myAction = {
                type: action.type,
                payload: action.payload.pages.nextPages
            }
            _pagesAction_cleanStackOpenTwoPages(state, myAction);
        }
    }
}

const _pagesAction_logoutAllPages = (state, action) => {
    while (state.length > 0){
        state.pop();
    }

    state.push('PricingPage');
}

const _pagesAction_resetToInitialState = (state, action) => {
    while (state.length > 0){
        state.pop();
    }
    state.push('PricingPage');
}

const _pagesAction_openPage = (state, action) => {
    state.unshift(action.payload);
}

const _pagesAction_closeMenuOpenPage = (state, action) => {
    state.shift();
    state[0] = action.payload;
}

const _pagesAction_closeTwoPages = (state, action) => {
    state.shift();
    state.shift();
}

const _pagesAction_closePage = (state, action) => {
    state.shift();
}

const _pagesAction_switchPage = (state, action) => {
    state[0] = action.payload;
}

const _pagesAction_closeFourPages = (state, action) => {
    state.shift(); state.shift();
    state.shift(); state.shift();
}

const _pagesAction_closeTwoPages_openPage = (state, action) => {
    _pagesAction_closeTwoPages(state, action);
    _pagesAction_openPage(state, action);
}

const _pagesAction_cleanStackOpenTwoPages = (state, action) => {
    while (state.length > 0){
        state.pop();
    }
    
    state.unshift(action.payload[0]);
    state.unshift(action.payload[1]);
}

export const { pagesAction_openPage,
    pagesAction_closePage, 
    pagesAction_switchPage, 
    pagesAction_closeMenuOpenPage, pagesAction_cleanStackOpenTwoPages,
    pagesAction_closeTwoPages, pagesAction_closeThreePages, pagesAction_closeTwoPages_openPage,
    pagesAction_closeFourPages, pagesAction_closeFourPages_openPage, 
    pagesAction_resetToInitialState, pagesAction_logoutAllPages,
    pagesAction_resetToInitialStateAndLoadTAC,
 } = pagesSlice.actions;
export const pagesReducer = pagesSlice.reducer;
