import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import classNames from "classnames";
import { ReactComponent as OpenPeep } from "../images/svg/openpeep2.svg";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { pagesAction_closeFourPages_openPage, pagesAction_closePage, pagesAction_closeThreePages, pagesAction_closeTwoPages } from "../store";
import "../stripe/Checkout.css";

//STRIPE
import CheckoutForm from "../stripe/CheckoutForm";
import Button from "../components/buttons/Button";
import { FiFileText, FiShoppingCart, FiX } from "react-icons/fi";
import { getBackgroundCSS } from "../components/css";
import { loadCompletedProjects } from "./CompletedProjectsPage";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const live_public_key = "pk_live_51MoEgcII5qp4adabxtviVWdXijBTFBRt10PRIoHUPHDz3hj5BeiNAXa5WugaUTiUQzK8r2R1pW6sBiTe4cAtzseE00EsSYp6BS"
const test_public_key = "pk_test_51MoEgcII5qp4adabzCShh22Hgj1Jb5CYSFuRLT7MlQC7SoEkbFuyfNXnuIk1f0bqxLyZv8t41FlWnbb3FhLJWM3Y00oLNjOvNS";
let key = '';
/*
In React applications, process.env.NODE_ENV is usually one of the following:

1) development: when running the development server, such as when using npm start or yarn start.
2) test: when running tests, for example with Jest.
3) production: when the code has been built for production, such as with npm run build or yarn build.
*/
if (process.env.NODE_ENV === 'development') {
    key = test_public_key;
}else if(process.env.NODE_ENV === 'production') {
    key = live_public_key;
}
const stripePromise = loadStripe(key);

function CheckoutPage({count}){
    const dispatch = useDispatch();
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});
    const basketState = useSelector((state) => {return state.basketPage;});
    const pagesState = useSelector((state) => {return state.pages;});

    let backgroundClass = getBackgroundCSS(count, 'bg-green-1');
    let paragraphClass = classNames('flex flex-col rounded-[20px] items-center mt-3 w-full bg-white');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1');

    const appearance = {
        theme: 'minimal',  
    };

    const options = {
        clientSecret: basketState.clientSecret,
        appearance: appearance,
      };

    const getPriceText = () => {
        return `£${(basketState.project.pricing/100).toFixed(2)}`;
    }
    
    const isSucceeded = () => {
        return basketState.paymentIntentStatus === 'succeeded';
    }

    const handleClosePageClick = async () => {
        if (basketState.paymentIntentStatus === 'succeeded'){
            const pages = {
                nextPage: 'CompletedProjectsPage',
                currentPage: 'CheckoutPage',
                sipfAction: {}
            }
            const action = pagesAction_closeFourPages_openPage(pages); dispatch(action);
            await loadCompletedProjects(dispatch, sessionState);
        }else{
            const action = pagesAction_closePage(pagesState[0]); dispatch(action);
        }
    }

    const isLoading = () => {
        return apiState.stripeANDcheckUpdatePaymentIntentStatus === 'waiting';
    }
  
    const disableAllButtons = () => {
      return isLoading();
    }

    const getFirstImageVideoJSX = () => {
        let src = null;
        if (basketState.project.firstImageFileId !== null){
            src = `https://saythatback-imagefile.s3.amazonaws.com/${basketState.project.firstImageFileS3Key}_1280x720.jpg`;
        }else if (basketState.project.firstVideoFileId !== null){
            src = `https://saythatback-videofile.s3.amazonaws.com/${basketState.project.firstVideoFileS3Key}_1280x720.jpg`;
        }

        
        
        if (src === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex, rounded-[20px], items-center, w-full')} >
                    <img alt="" 
                    className={classNames('w-full, bg-white, rounded-[20px]')} 
                    src={src} />
                </div> 
            )
        }
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className={errorDivClass}>{stateErrorVariable}</div>
            )
        }
        
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePageClick} disabled={disableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-3xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Payment
                </div>
                <div className={paragraphClass}>
                    {getFirstImageVideoJSX()}
                    <div className="w-full h-fit font-montserrat font-extrabold text-2xl text-left mt-1 px-6 py-3">
                        {basketState.project.title}
                    </div>
                    <div className="flex flex-rows gap-3 w-full font-montserrat font-medium text-base items-center mt-1 px-6 pb-3">
                        <div><FiShoppingCart className="w-4 h-4" color="black" /></div>
                        <div>{getPriceText()}</div>
                    </div>
                </div>
                <div className={classNames(paragraphClass, {
                    hidden: isSucceeded()
                })}>
                    {basketState.clientSecret && (
                        <Elements options={options} stripe={stripePromise} >
                            <CheckoutForm />
                        </Elements>
                    )}
                </div>
                <div className={classNames(paragraphClass, {
                    hidden: !isSucceeded()
                })}>
                    <div className="flex items-center text-center rounded-[20px]
                    w-full bg-white pt-6 px-6 font-montserrat font-semibold 
                    text-l">
                        Payment succeeded! You're project is being made. AI go brrr! We’ll email you when it's ready. 
                    </div>
                    <div className="flex, rounded-[20px], items-center, w-full bg-white p-6 rounded-[20px]">
                    <Button primary={true} onClick={handleClosePageClick}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Close Page
                        </div>
                        <FiX className="justify-self-end h-6 w-6"/>
                    </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage;
