import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import axios from "axios";
import classNames from "classnames";
import classNamesDedupe from "classnames/dedupe";
import { resize } from "../utils/resize";
import Button from "../components/buttons/Button";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { FiChevronRight, FiUser, FiLoader } from 'react-icons/fi';
import { pagesAction_closePage, pagesAction_resetToInitialStateAndLoadTAC, pagesAction_switchPage, sipfAction_setTermsCheckbox, sipfAction_setTermsCheckboxError } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps4_smile.svg";
import { sipfAction_updateEmailField } from "../store";
import { SessionNotLoggedIn, SessionLoggedIn } from "../store";
import { apiAction_startSubmitEmail, apiAction_endSubmitEmail } from "../store";
import { submit_email } from "../api/submit_email";
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";
import Checkbox from "rc-checkbox";
 
 function LoginRegisterEmailPage1({count}){
    const dispatch = useDispatch();

    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const sipFormState = useSelector((state) => {return state.signInPageForm;});
    const apiState = useSelector((state) => {return state.api;});

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const inputFieldDivClass = classNames('flex border-black border-2 rounded-[20px] items-center h-[3.5rem] my-6 px-2.5 w-full');
    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const textInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] focus:outline-none');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'mt-6', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1', {
        'hidden': false
    })

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const handleEmailLFieldChange = (e) => {
        const action = sipfAction_updateEmailField(e.target.value); dispatch(action);
    };

    const handleSubmit = async () => {
        if (sipFormState.termsCheckbox === false){
            const action = sipfAction_setTermsCheckboxError(true); dispatch(action);
            return;
        }

        let action = apiAction_startSubmitEmail(); dispatch(action);
        const res = await submit_email(sipFormState.email);
        const payload = {
            res: res,
        }
        if (res.success && res.success === true && res.status === 'email_exists'){
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'LoginRegisterPasswordPage1'
            }
        }else if(res.success && res.success === true && res.status === 'email_registered'){
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'LoginRegisterPasswordPage2'
            }
        }
        action = apiAction_endSubmitEmail(payload); dispatch(action);
    }

    const isSubmitEmailStatusWaiting = () => {
        return apiState.submitEmailStatus === 'waiting';
    }

    const getButtonIcon = () => {
        if (isSubmitEmailStatusWaiting() === false){
            return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }else{
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }
    }

    const termsCheckboxOnChangeClick = () => {
        const action = sipfAction_setTermsCheckbox(!sipFormState.termsCheckbox); dispatch(action);
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className={errorDivClass}>{stateErrorVariable}</div>
            )
        }
        
    }

    const showTACSPage = () => {
        const action = pagesAction_resetToInitialStateAndLoadTAC(); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isSubmitEmailStatusWaiting()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep className="w-80" />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Sign In
                </div>
                <div className="w-full bg-white 
                rounded-[20px] p-6 text-center mt-2.5 border-black border-2">
                    <div className="font-montserrat font-medium text-[1.063rem]">
                        Enter your email
                    </div>
                    {getErrorJSX(sipFormState.emailErrorMessage)}
                    <div className={inputFieldDivClass}>
                        <FiUser className={featherIconsClass}/>
                        <input type="text" name="name" className={textInputClass} placeholder="Email"
                        onChange={handleEmailLFieldChange} value={sipFormState.email}/>
                    </div>
                    <div className="flex w-full h-full justify-start pb-6">
                        <label className={classNames("font-montserrat font-medium text-sm", 
                        {'text-red-1': sipFormState.termsCheckboxError})}>
                            <Checkbox
                                checked={sipFormState.termsCheckbox}
                                onChange={termsCheckboxOnChangeClick}
                            />
                            &nbsp; I agree to the <a href="#" className="underline" onClick={() => showTACSPage('TACSPage')}>Terms & Conditions.</a>
                        </label>
                    </div>
                    <Button primary waiting={isSubmitEmailStatusWaiting()} onClick={handleSubmit}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Continue
                        </div>
                        {getButtonIcon()}
                    </Button>
                </div>
            </div>
        </div>
    )
 }

 export default LoginRegisterEmailPage1;