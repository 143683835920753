import classNames from "classnames";
import classNamesDedupe from "classnames/dedupe";
import { FiAlignJustify, FiArrowDown, FiArrowDownLeft, FiArrowDownRight, FiArrowLeft, 
    FiArrowRight, FiArrowUp, FiArrowUpLeft, FiArrowUpRight, FiChevronDown, FiChevronRight, FiChevronUp, FiChevronsRight, FiClipboard, FiCopy, FiEdit2, 
    FiImage, FiMessageSquare, FiMinus, FiMove, FiMusic, FiPlus, FiRepeat, FiSave, FiType, FiVideo, FiX } from "react-icons/fi";
import { FaRobot, FaSpinner } from 'react-icons/fa';
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { ReactComponent as OpenPeep2 } from "../images/svg/openpeep2.svg";
import Button from "../components/buttons/Button";
import {  
    apiAction_startUploadMediaFile, apiAction_endUploadMediaFile,
    pagesAction_closePage, pagesAction_openPage,
    } from "../store/actions";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import { apiAction_endSaveToDraft, apiAction_endSaveToDraftLoadBasket, apiAction_startSaveToDraft, apiAction_startSaveToDraftLoadBasket, asfAction_updateOriginalText, asfAction_addBackgroundMusicSlide, asfAction_addNoTTSImageSlide, asfAction_addNoTTSVideoSlide, asfAction_addTTSImageSlide, asfAction_addTTSVideoSlide, asfAction_changeVoiceOnModalIndex, asfAction_closeAddNewSlideModal, asfAction_closeChangeModal, asfAction_copyMediaFile, asfAction_moveSlideDown, asfAction_moveSlideUp, asfAction_openAddNewSlideModal, asfAction_openChangeVoiceModal, asfAction_pasteMediaFile, asfAction_removeMediaFile, asfAction_removeSlide, asfAction_updateLargeSubtitlesField, asfAction_updateLoopBackgroundMusic, asfAction_updateTitleField, asfAction_updateSubtitlesPositionField, asfAction_updateDurationField, asfAction_updateSubtitlesOnField, asfAction_updateSilenceIntervalAtEndField, asfAction_updateTransitionOpenClose, asfAction_updateTransitionTypeId, asfAction_updateColor, asfAction_updateFrom, asfAction_updateTo, asfAction_updateDirection, asfAction_updatePlayVideoTillEndField } from "../store";
import { save_to_drafts } from "../api/save_to_drafts";
import { getDraftProjects } from "./DraftProjectsPage";
import { save_to_drafts_load_basket } from "../api/save_to_drafts_load_basket";
import { getBackgroundCSS } from "../components/css";
import NewSlideModal from "./modal/NewSlideModal";
import { upload_sound_file } from "../api/upload_sound_file";
import ChangeVoiceModal from "./modal/ChangeVoiceModal";
import { upload_image_file } from "../api/upload_image_file";
import { upload_video_file } from "../api/upload_video_file";
import Slider from "rc-slider";
import Select from "react-select";
import { SketchPicker,} from "react-color";
import OpenMenuTopBar from "../components/OpenMenuTopBar";

function AddSlidesPage({count}){
    const dispatch = useDispatch();

    const asfFormState = useSelector((state) => {return state.addSlidesForm});
    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});

    const project = asfFormState.project;
    const voices = asfFormState.voices;

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const inputFieldDivClass = classNames('flex border-black border-2 rounded-[20px] items-center h-[3.5rem] mt-6 px-2.5 w-full');
    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const voiceIconsClass = classNames('h-8 w-8 mx-1 rounded-[20px]');
    const textInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] focus:outline-none');
    
    const inputFieldInsideDivClass = classNamesDedupe(inputFieldDivClass, {
        'mt-6': false, 
        'mt-2.5': true
    });
    
    const textAreaInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] h-79 focus:outline-none');
    const textAreaFeatherIcon = classNames('h-6 w-6 mx-2 mt-3');
    const textAreaInputFieldDivClass = classNamesDedupe(inputFieldDivClass, {
        'items-center': false,
        'h-[3.5rem]': false,
        'h-80': true,
        'items-top': true,
        'mt-6': false,
        'mt-2.5': true,
        'relative': true,
    });

    const wordCountClass = (slide) => {
        return classNames('absolute top-1 right-2 font-montserrat font-medium text-xs');
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className='flex border-black items-center mt-6 w-full font-montserrat font-medium text-[1rem] text-red-1'>
                    {stateErrorVariable}
                </div>
            )
        }
        
    }
    
    const handleTitleFieldChange = (e) => {
        const action = asfAction_updateTitleField(e.target.value); dispatch(action);
    }

    const handleOriginalTextFieldChange = (e, index) => {
        const payload = {
            originalText: e.target.value,
            index: index
        };
        const action = asfAction_updateOriginalText(payload); dispatch(action);
    }

    const handlePlayVideoTillEndFieldChange = (checked, index) =>{
        const payload = {
            playVideoTillEnd: checked,
            index: index
        };
        const action = asfAction_updatePlayVideoTillEndField(payload); dispatch(action);
    }
    
    const handleSubtitlesOnFieldChange = (checked, index) =>{
        const payload = {
            subtitlesOn: checked,
            index: index
        };
        const action = asfAction_updateSubtitlesOnField(payload); dispatch(action);
    }

    const handleLargeSubtitlesFieldChange = (checked, index) =>{
        const payload = {
            largeSubtitles: checked,
            index: index
        };
        const action = asfAction_updateLargeSubtitlesField(payload); dispatch(action);
    }

    const handleSubtitlesPosition = (subtitlesPosition, index) => {
        const payload = {
            subtitlesPosition: subtitlesPosition,
            index: index
        };
        const action = asfAction_updateSubtitlesPositionField(payload); dispatch(action);
    }

    const handleDurationChange = (duration, index) => {
        const payload = {
            duration: duration,
            index: index
        };
        const action = asfAction_updateDurationField(payload); dispatch(action);
    }

    const handleSilenceIntervalAtEndChange = (silenceIntervalAtEnd, index) => {
        const payload = {
            silenceIntervalAtEnd: silenceIntervalAtEnd,
            index: index
        };
        const action = asfAction_updateSilenceIntervalAtEndField(payload); dispatch(action);
    }

    const handleRemoveSlideClick = (index) => {
        const action = asfAction_removeSlide(index); dispatch(action);
    }

    const handleMoveSlideUpClick = (index) => {
        const action = asfAction_moveSlideUp(index); dispatch(action);
    }

    const handleMoveSlideDownClick = (index) => {
        const action = asfAction_moveSlideDown(index); dispatch(action);
    }

    const handleSoundFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "audio/mpeg, audio/aac";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadSoundFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadSoundFile = async (filepath, index) => {
        let action = apiAction_startUploadMediaFile(index); dispatch(action);
        const res = await upload_sound_file(filepath, sessionState.token);
        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'sound_file_added'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        console.log(payload);
        action = apiAction_endUploadMediaFile(payload); dispatch(action);
    }

    const handleImageFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "image/png, image/jpeg, image/webp";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadImageFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadImageFile = async (filepath, index) => {
        let action = apiAction_startUploadMediaFile(index); dispatch(action);
        const res = await upload_image_file(filepath, sessionState.token);

        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'image_file_added'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endUploadMediaFile(payload); dispatch(action);
    }

    const handleVideoFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "video/mp4";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadVideoFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadVideoFile = async (filepath, index) => {
        let action = apiAction_startUploadMediaFile(index); dispatch(action);
        const res = await upload_video_file(filepath, sessionState.token);

        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'video_file_added'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endUploadMediaFile(payload); dispatch(action);
    }

    const handleOpenChangeVoiceModal = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }

        const action = asfAction_openChangeVoiceModal(index); dispatch(action);
    }

    const handleChangeVoiceClick = (voice) => {
        const action = asfAction_changeVoiceOnModalIndex(voice); dispatch(action);
    }

    const isUploadingMediaFileAtIndex = (index) => {
        let returnVar = false;
        for (const indexItem of apiState.uploadMediaFileIndexWaitingArray){
            if (indexItem === index){
                returnVar = true;
                break;
            }
        }
        return returnVar;
    }

    const isUploadingMediaFile = () => {
        return apiState.uploadMediaFileIndexWaitingArray.length > 0;
    }

    const isSavingDraft = () => {
        return apiState.saveToDraftStatus === 'waiting';
    }

    const isSavingDraftLoadingBasket = () => {
        return apiState.saveToDraftLoadBasketStatus === 'waiting';
    }

    const handleRemoveMediaFile = (index) => {
        const action = asfAction_removeMediaFile(index); dispatch(action);
    }

    const handleCopyMediaFile = (index) => {
        const action = asfAction_copyMediaFile(index);; dispatch(action);
    }

    const handlePasteMediaFile = (index) => {
        const action = asfAction_pasteMediaFile(index);; dispatch(action);
    }

    const handleUpdateDirection = (direction, index) => {
        const payload = {
            direction: direction,
            index: index
        };
        const action = asfAction_updateDirection(payload); dispatch(action);
    }
    
    const isDisableAllButtons = () => {
        if (
            isUploadingMediaFile() || 
            isSavingDraft() ||
            isSavingDraftLoadingBasket()
        ){
            return true;
        }else{
            return false;
        }
    }

    const getSubtitlePositionButtonJSX = (slide, position, index) => {
        let arrowIcon = null;
        if (position === 1){
            arrowIcon = <FiArrowDownLeft className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"} />;
        }else if(position === 2){
            arrowIcon = <FiArrowDown className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 3){
            arrowIcon = <FiArrowDownRight className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 4){
            arrowIcon = <FiArrowLeft className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 5){
            arrowIcon = <FiMove className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 6){
            arrowIcon = <FiArrowRight className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 7){
            arrowIcon = <FiArrowUpLeft className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 8){
            arrowIcon = <FiArrowUp className="w-8 h-8"color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 9){
            arrowIcon = <FiArrowUpRight className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }

        return (
            <div className="p-1">
                <Button round={slide.subtitlesPosition !== position && !isDisableAllButtons()}
                roundDisabled={slide.subtitlesPosition !== position && isDisableAllButtons()} 
                roundSelected={slide.subtitlesPosition === position && !isDisableAllButtons()}
                roundSelectedDisabled={slide.subtitlesPosition === position && isDisableAllButtons()}
                onClick={(e) => {
                    handleSubtitlesPosition(position, index);
                }} 
                >
                   {arrowIcon}
                </Button>
            </div>
        );
    }

    const handleSaveToDraftsClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
        }else{
            let action = apiAction_startSaveToDraft(); dispatch(action);
            const res = await save_to_drafts(asfFormState.project, sessionState.token);

            const payload = { res: res, };
            if (res.success && res.success === true && res.status === 'saved_to_drafts'){
                const sessionObject = {
                    isLoggedIn: true,
                    token: res.session.token,
                    creationDate: res.session.creationDate,
                    expiryDate: res.session.expiryDate
                }
                payload['addSlidesForm'] = { res: res, }
                payload['session'] = sessionObject;
                //SET COOKIES
                session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                const sessionObject = {
                    isLoggedIn: false,
                    token: null,
                    creationDate: null,
                    expiryDate: null
                }
                payload['session'] = sessionObject;
                payload['pages'] = {
                    pagesAction: 'pagesAction_logoutAllPages',
                    nextPage: ''
                };

                //REMOVE COOKIES
                session_removeCookie();
            }
            action = apiAction_endSaveToDraft(payload); dispatch(action);
        }
    }

    const handleSaveToDraftsLoadBasketClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }
        
        let action = apiAction_startSaveToDraftLoadBasket(); dispatch(action);
            const res = await save_to_drafts_load_basket(asfFormState.project, sessionState.token);

            const payload = { res: res, };
            if (res.success && res.success === true && res.status === 'saved_to_drafts_loaded_basked'){
                const sessionObject = {
                    isLoggedIn: true,
                    token: res.session.token,
                    creationDate: res.session.creationDate,
                    expiryDate: res.session.expiryDate
                }
                payload['addSlidesForm'] = { res: res, }
                payload['basketPage'] = { res: res, }
                payload['session'] = sessionObject;
                payload['pages'] = {
                    pagesAction: 'pagesAction_openPage',
                    nextPage: 'BasketPage'
                };

                //SET COOKIES
                session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                const sessionObject = {
                    isLoggedIn: false,
                    token: null,
                    creationDate: null,
                    expiryDate: null
                }
                payload['session'] = sessionObject;
                payload['pages'] = {
                    pagesAction: 'pagesAction_logoutAllPages',
                    nextPage: ''
                };

                //REMOVE COOKIES
                session_removeCookie();
            }
            
            action = apiAction_endSaveToDraftLoadBasket(payload); dispatch(action);
    }

    const handleToggleTransitionOpenClose = (index) => {
        const payload = {
            index: index,
            transitionUIOpen: !project.slides[index].transitionUIOpen
        }
        const action = asfAction_updateTransitionOpenClose(payload); dispatch(action);
    }

    const handleChangeTransitionTypeId = (transitionOption, index) => {
        const payload = {
            transitionTypeId: transitionOption.value,
            index: index
        }
        const action = asfAction_updateTransitionTypeId(payload);; dispatch(action);
        //
    }

    const handleChangeColor = (color, index) => {
        const payload = {
            color: color.hex.substring(1),
            index: index
        }
        const action = asfAction_updateColor(payload); dispatch(action);
    }

    const handleUpdateFromClick = (event, index) => {
        const rect = event.target.getBoundingClientRect();
        
        let x = event.clientX;
        let y = event.clientY;
        
        const scaleFactorX = 3840 / rect.width;
        const scaleFactorY = 2160 / rect.height;
        x = (x - rect.left) * scaleFactorX; // x position within the element scaled to 3840x2160.
        y = (y - rect.top) * scaleFactorY;  // y position within the element scaled to 3840x2160.

        if (x < 0){ x = 0 }
        if (x > 3840){ x = 3840}
        if (y < 0){ y = 0 }
        if (y > 2160){ y = 2160 }

        const payload = {
            x: x,
            y: y,
            index: index
        }
        
        const action = asfAction_updateFrom(payload); dispatch(action);
    }

    const handleUpdateToClick = (event, index) => {
        const rect = event.target.getBoundingClientRect();
        
        let x = event.clientX;
        let y = event.clientY;
        
        const scaleFactorX = 3840 / rect.width;
        const scaleFactorY = 2160 / rect.height;
        x = (x - rect.left) * scaleFactorX; // x position within the element scaled to 3840x2160.
        y = (y - rect.top) * scaleFactorY;  // y position within the element scaled to 3840x2160.

        if (x < 0){ x = 0 }
        if (x > 3840){ x = 3840}
        if (y < 0){ y = 0 }
        if (y > 2160){ y = 2160 }

        const payload = {
            x: x,
            y: y,
            index: index
        }
        
        const action = asfAction_updateTo(payload); dispatch(action);
    }


    const isSlideError = (slide) => {
        if (
            slide.error.slide !== null || 
            slide.error.index !== null || 
            slide.error.soundFileId !== null || 
            slide.error.imageFileId !== null || 
            slide.error.videoFileId !== null || 
            slide.error.voiceId !== null || 
            slide.error.subtitlesOn !== null ||
            slide.error.largeSubtitles !== null || 
            slide.error.originalText !== null || 
            slide.error.silenceIntervalAtEnd !== null || 
            slide.error.duration !== null || 
            slide.error.loopBackgroundMusic !== null || 
            slide.error.transitionTypeId !== null || 
            slide.error.color !== null || 
            slide.error.direction !== null || 
            slide.error.fromX !== null || 
            slide.error.fromY !== null || 
            slide.error.toX !== null || 
            slide.error.toY !== null 
        ){
            return true;
        }
        
        return false;
    }

    const getSlidesJSX = () => {
        const slidesJSX = [];

        const slidesCount = project.slides.length;
        project.slides.forEach((slide, index) => {
            let slideClass = classNames('flex flex-col border-2 rounded-[20px] items-center mt-6 pb-2.5 px-2.5 w-full');
            const slideError = isSlideError(slide);
            
            slideClass = classNames(slideClass, {
                'border-slate-300': !slideError,
                'border-red-1': slideError
            })

            let moveDownButtonStyle = {plain: true, plainDisabled: false};
            let moveUpButtonStyle = {plain: true, plainDisabled: false};
            let removeButtonStyle = {plain: true, plainDisabled: false};
            let addButtonStyle = {primary: true, primaryDisabled: false};
            if (slidesCount === 1){
                moveDownButtonStyle = {plain: false, plainDisabled: true};
                moveUpButtonStyle = {plain: false, plainDisabled: true};
                removeButtonStyle = {plain: true, plainDisabled: false};
            }else{
                if (index === 0){
                    moveUpButtonStyle = {plain: false, plainDisabled: true};
                }else if (index === slidesCount - 1){
                    moveDownButtonStyle = {plain: false, plainDisabled: true};
                }
            }

            if (isDisableAllButtons()){
                moveDownButtonStyle = {plain: false, plainDisabled: true};
                moveUpButtonStyle = {plain: false, plainDisabled: true};
                removeButtonStyle = {plain: false, plainDisabled: true};
                addButtonStyle = {primary: false, primaryDisabled: true};
            }

            slidesJSX.push(
                <div key={index} className={slideClass}>
                    {getSlideJSX(slide, index)}
                    <div className="w-full bg-white justify-around mt-8 gap-5 flex flex-row border rounded-full p-3">
                        <Button round={moveDownButtonStyle.plain} roundDisabled={moveDownButtonStyle.plainDisabled}
                        onClick={() =>{
                            handleMoveSlideDownClick(index)
                        }}>
                            <FiChevronDown className="justify-self-center grow h-6 w-6"/>
                        </Button>
                        <Button round={moveUpButtonStyle.plain} roundDisabled={moveUpButtonStyle.plainDisabled}
                        onClick={() =>{
                            handleMoveSlideUpClick(index)
                        }}>
                            <FiChevronUp className="justify-self-center grow h-6 w-6"/>
                        </Button>
                        <Button round={removeButtonStyle.plain} roundDisabled={removeButtonStyle.plainDisabled}
                        onClick={() =>{
                            handleRemoveSlideClick(index);
                        }}>
                            <FiMinus className="justify-self-center grow h-6 w-6"/>
                        </Button>
                        <Button roundSelected={addButtonStyle.primary} roundSelectedDisabled={addButtonStyle.primaryDisabled} 
                        onClick={() =>{
                            handleOpenAddNewSlidesModal(index + 1);
                        }}>
                            <FiPlus className="justify-self-ceter grow h-6 w-6 text-white"/>
                        </Button>
                    </div>
                </div>
            );
        });

        return slidesJSX;
    }

    const getSlideJSX = (slide, index) => {
        if (slide.slideType === 1){return getBackgroundMusicSlideJSX(slide, index);}
        else if(slide.slideType === 2){return getTTSImageSlideJSX(slide, index);}
        else if(slide.slideType === 3){return getTTSVideoSlideJSX(slide, index);}
        else if(slide.slideType === 4){return getNoTTSImageSlideJSX(slide, index);}
        else if(slide.slideType === 5){return getNoTTSVideoSlideJSX(slide, index)}
    }

    //slideType = 1
    const getBackgroundMusicSlideJSX = (slide, index) => {
        return (
        <>
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiMusic className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getErrorJSX(slide.error.slide)}
            {getUploadSoundJSX(slide, index)}
            {getPreviewSoundJSX(slide, index)}
            {getLoopBackgroundMusicJSX(slide, index)}
        </> 
        )
    }

    //slideType = 2
    const getTTSImageSlideJSX = (slide, index) => {
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiImage className="justify-start h-6 w-6 ml-3"/>
                    <FaRobot className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getUploadImageJSX(slide, index)}
            {getPreviewImageJSX(slide, index)}
            {getChangeVoiceJSX(slide, index)}
            {getOriginalTextJSX(slide, index)}
            {getSubtitlesJSX(slide, index)}
            {getDividerJSX()}
            {getSilenceIntervalJSX(slide, index)}
            {getDividerJSX()}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    //slideType = 3
    const getTTSVideoSlideJSX = (slide, index) => {
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiVideo className="justify-start h-6 w-6 ml-3"/>
                    <FaRobot className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getUploadVideoJSX(slide, index)}
            {getChangeVoiceJSX(slide, index)}
            {getPreviewVideoJSX(slide, index)}
            {getOriginalTextJSX(slide, index)}
            {getSubtitlesJSX(slide, index)}
            {getDividerJSX()}
            {getPlayVideoTillEndJSX(slide, index)}
            {getSilenceIntervalJSX(slide, index)}
            {getDividerJSX()}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    //slideType = 4
    const getNoTTSImageSlideJSX = (slide, index) => {
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiImage className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getUploadImageJSX(slide, index)}
            {getPreviewImageJSX(slide, index)}
            {getDurationJSX(slide, index)}
            {getDividerJSX()}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    //slideType = 5
    const getNoTTSVideoSlideJSX = (slide, index) => {
        
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiVideo className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getUploadVideoJSX(slide, index)}
            {getPreviewVideoJSX(slide, index)}
            {getDurationJSX(slide, index)}
            {getDividerJSX()}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    const getDividerJSX = () => {
        return (
            <div className="w-full h-[0.1rem] bg-slate-300 mt-3"></div>
        )
    }

    const getTransitionJSX = (slide, index) => {
        const getIsTransitionOpenIcon = () => {
            if (slide.transitionUIOpen === true){
                return <FiChevronDown className={classNames('justify-self-center grow h-6 w-6', {
                    'text-white': slide.transitionUIOpen
                })}/>
            }else{
                return <FiChevronRight className={classNames('justify-self-center grow h-6 w-6', {
                    'text-white': slide.transitionUIOpen
                })}/>
            }
        }

        const transitionOptions = [
            {value: 1, label: 'Cut'},
            {value: 2, label: 'Fade In Out'},
            {value: 3, label: 'Dissolve'},
            {value: 4, label: 'Iris'},
            {value: 5, label: 'Wipe'},
            {value: 6, label: 'Whip Pan'},
            {value: 7, label: 'Zoom Transition'},
            {value: 8, label: 'Barrel Roll'},
        ]

        const getTransitionOption = (transitionTypeId) => {
            for (const transitionOption of transitionOptions){
                if(transitionTypeId === transitionOption.value){
                    return transitionOption
                }
            }
        }

        const customStyles = {
            control: (base) => ({
              ...base,
              border: '2px solid black',
              boxShadow: 'none',
              borderRadius: '30px', // Apply rounded border
              padding: '5px',
              width: '100%',
            }),
        };

        const getTransitionSubComponentsJSX = () => {
            if (slide.transitionUIOpen === false){
                return <></>
            }else{
                if (slide.transitionTypeId === 1){
                    return <></>
                }else if(slide.transitionTypeId === 2){
                    return <>{getColorPickerJSX()}</>
                }else if(slide.transitionTypeId === 3){
                    return <></>
                }else if(slide.transitionTypeId === 4){
                    return(
                        <>
                            {getColorPickerJSX()}
                            {getPickFromCoordinates()}
                            {getPickToCoordinates()}
                        </>
                    )
                }else if (slide.transitionTypeId === 5){
                    return <>{pickDirectionJSX('Wipe Direction')}</>
                }else if(slide.transitionTypeId === 6){
                    return <>{pickDirectionJSX('Whip Pan Direction')}</>
                }else if(slide.transitionTypeId === 7){
                    return <></>
                }else if(slide.transitionTypeId === 8){
                    return <></>
                }
            }
        }

        const getColorPickerJSX = () => {
            return (
                <div className='flex flex-col place-items-center w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-col ml-1">
                        <div className="flex flex-grow">
                            Pick a color
                        </div>
                        {getErrorJSX(slide.error.color)}
                        <div className="flex flex-col place-items-center">
                            <SketchPicker
                                color={`#${slide.color}`}
                                styles={{ default: { picker: { width: '90%', marginTop: '1rem'  }}}}
                                onChange={(color) => handleChangeColor(color, index)}
                                disableAlpha={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        const handleStopPropagation = (event) => {
            // Stop the click event from bubbling up to the parent
            event.stopPropagation();
        }

        

        const getPickFromCoordinates = () => {
            const style = {
                left: `calc(${(slide.fromX / 3840) * 100}% - 1.5rem)`,
                top: `calc(${(slide.fromY / 2160) * 100}% - 1.5rem)`,
            };

            return (
                <div className='flex flex-col place-items-center w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-col ml-1">
                        <div className="flex flex-grow py-3">
                            Choose the exit point for Iris: 
                        </div>
                        {getErrorJSX(slide.error.fromX)}
                        {getErrorJSX(slide.error.fromY)}
                        <div className='flex rounded-[20px] items-center w-full'>
                            <div className='relative rounded-[20px] w-full aspect-video cursor-pointer' onClick={(event) => handleUpdateFromClick(event, index)}
                            style={{backgroundColor: `#${slide.color}`}}>
                                <div style={style} className={`absolute w-12 h-12 rounded-full bg-white`} onClick={handleStopPropagation} />
                            </div>
                        </div>
                        <div className='flex w-full mt-1 font-montserrat font-semibold text-lg'>
                            <span className="text-center w-full ">({Math.trunc(slide.fromX)}, {Math.trunc(slide.fromY)})</span>
                        </div>
                    </div>
                </div>
            )
        }

        const getPickToCoordinates = () => {
            const style = {
                left: `calc(${(slide.toX / 3840) * 100}% - 1.5rem)`,
                top: `calc(${(slide.toY / 2160) * 100}% - 1.5rem)`,
            };

            return (
                <div className='flex flex-col place-items-center w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-col ml-1">
                        <div className="flex flex-grow py-3">
                            Choose the entry point for the Iris (next slide)
                        </div>
                        {getErrorJSX(slide.error.toX)}
                        {getErrorJSX(slide.error.toY)}
                        <div className='flex rounded-[20px] items-center w-full'>
                            <div className="relative rounded-[20px] w-full aspect-video cursor-pointer" onClick={(event) => handleUpdateToClick(event, index)}
                            style={{backgroundColor: `#${slide.color}`}}>
                                <div style={style} className={`absolute w-12 h-12 rounded-full bg-white`} onClick={handleStopPropagation} />
                            </div>
                        </div>
                        <div className='flex w-full mt-1 font-montserrat font-semibold text-lg'>
                            <span className="text-center w-full ">({Math.trunc(slide.toX)}, {Math.trunc(slide.toY)})</span>
                        </div>
                    </div>
                </div>
            )
        }

        const pickDirectionJSX = (headerText) => {

            const getDirectionButtonJSX = (slide, direction, index) => {
                let arrowIcon = null;
                if (direction === 1){
                    arrowIcon = <FiArrowDownLeft className="w-8 h-8" color={slide.direction === direction? "white": "black"} />;
                }else if(direction === 2){
                    arrowIcon = <FiArrowDown className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 3){
                    arrowIcon = <FiArrowDownRight className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 4){
                    arrowIcon = <FiArrowLeft className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 5){
                    arrowIcon = <></>;
                }else if(direction === 6){
                    arrowIcon = <FiArrowRight className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 7){
                    arrowIcon = <FiArrowUpLeft className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 8){
                    arrowIcon = <FiArrowUp className="w-8 h-8"color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 9){
                    arrowIcon = <FiArrowUpRight className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }
        
                return (
                    <div className="p-1">
                        <Button round={slide.direction !== direction && !isDisableAllButtons()}
                        roundDisabled={slide.direction !== direction && isDisableAllButtons()} 
                        roundSelected={slide.direction === direction && !isDisableAllButtons()}
                        roundSelectedDisabled={slide.direction === direction && isDisableAllButtons()}
                        onClick={(e) => {
                            if (direction !== 5){
                                handleUpdateDirection(direction, index);
                            }
                        }} 
                        >
                           {arrowIcon}
                        </Button>
                    </div>
                );
            }

            return (
                <>
                    {getErrorJSX(slide.error.direction)}
                    <div className='flex flex-col w-full pt-6'>
                        <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex-row place-items-center">{headerText}</div>
                        <div className="flex flex-col mt-6">
                            <div className="flex justify-center">
                                {getDirectionButtonJSX(slide, 7, index)}
                                {getDirectionButtonJSX(slide, 8, index)}
                                {getDirectionButtonJSX(slide, 9, index)}
                            </div>
                            <div className="flex justify-center">
                                {getDirectionButtonJSX(slide, 4, index)}
                                {getDirectionButtonJSX(slide, 5, index)}
                                {getDirectionButtonJSX(slide, 6, index)}
                            </div>
                            <div className="flex justify-center">
                                {getDirectionButtonJSX(slide, 1, index)}
                                {getDirectionButtonJSX(slide, 2, index)}
                                {getDirectionButtonJSX(slide, 3, index)}
                            </div>  
                        </div>
                    </div>
                </>
            )
        }

        const getTransitionSelectJSX = () => {
            if (slide.transitionUIOpen === false){
                return <></>
            }else{
                return (
                    <div className='flex flex-col w-full pt-3'>
                        <div className="w-full h-full flex flex flex-row place-items-center ml-1">
                            <div className="flex flex-grow w-full">
                                <Select 
                                    className="w-full font-montserrat font-bold text-xl"
                                    value={getTransitionOption(slide.transitionTypeId)}
                                    options={transitionOptions}
                                    styles={customStyles}
                                    onChange={(transitionOption) => handleChangeTransitionTypeId(transitionOption, index)}
                                />
                            </div>
                       </div>
                    </div>
                )
            }
        }

        return (
            <>
                {getErrorJSX(slide.error.transition)}
                <div className='flex flex-col w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">Transitions</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && !slide.transitionUIOpen}
                                roundDisabled={isDisableAllButtons() && !slide.transitionUIOpen}
                                roundSelected={!isDisableAllButtons() && slide.transitionUIOpen}
                                roundSelectedDisabled={isDisableAllButtons() && slide.transitionUIOpen}
                                onClick={() => {handleToggleTransitionOpenClose(index)}}>
                                {getIsTransitionOpenIcon()}
                            </Button>
                        </div>
                    </div>
                </div>
                {getTransitionSelectJSX()}
                {getTransitionSubComponentsJSX()}
            </>
        )
    }

    const getOriginalTextJSX = (slide, index) => {
        return (
            <>
                {getErrorJSX(slide.error.originalText)}
                <div className={textAreaInputFieldDivClass}>
                    <FiAlignJustify className={textAreaFeatherIcon}/>
                    <textarea type="text" name="originalText" className={textAreaInputClass} placeholder="Manually add your text here"
                    onChange={(e) => {
                        handleOriginalTextFieldChange(e, index);
                    }} 
                    value={slide.originalText}/>
                    <div className={wordCountClass(slide)}>{slide.charCount}</div>
                </div>
                
            </>
        )
    }

    const getChangeVoiceJSX = (slide, index) => {
        return (
            <>
                {getErrorJSX(slide.error.voiceId)}
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        <img className={voiceIconsClass} 
                        src={`https://saythatback-voicefile.s3.amazonaws.com/${slide.voice.voiceImageFile.s3key}_128x128.jpg`}
                        />
                        <input id="video" readOnly={true} type="text" name="video" className={textInputClass} 
                        value={slide.voice.label}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleOpenChangeVoiceModal(index);
                            }
                        }} 
                        />
                    </div>
                </div>
            </>
        )
    }

    const getDurationJSX = (slide, index) => {
        let max = 60000;
        if (slide.videoFileId !== null && slide.videoFileId !== undefined){
            max = slide.videoFileDuration;
        }
        return (
            <>
                {getErrorJSX(slide.error.duration)}
                <div className="w-full h-7 font-montserrat font-extrabold text-xl align-middle my-3">Duration {`${Math.floor(slide.duration / 100)/10}s`}</div>
                <div className='flex rounded-[20px] items-center w-full px-2'>
                    <Slider
                        min={1000}
                        max={max}
                        value={slide.duration}
                        onChange={(value) => handleDurationChange(value, index)}
                        trackStyle={[{ backgroundColor: '#000000' },]}
                        handleStyle={[{
                            backgroundColor: '#000000',
                            height: 20,
                            width: 20,
                            marginTop: -8
                        }]}
                    />
                </div>
            </>
        )
    }

    const getUploadSoundJSX = (slide, index) => {
        const getMediaUploadIconSpinner = () => {
            if(isUploadingMediaFileAtIndex(index) === true){
                return <FaSpinner className="h-6 w-6 mx-2 animate-spin"/>
            }else{
                return <FiMusic className={featherIconsClass}/>
            }
        }

        const getMediaFileInputPlaceholder = (slide, index) => {
            if (isUploadingMediaFileAtIndex(index)){
                return "uploading backgound music..."
            }else{
                return slide.soundFileId === null? 'Click to add background music': 'Click to change background music'
            }
        } 
        
        const showRemoveMediaButton = () => {
            return slide.soundFileId === null;
        }

        let roundIconsClass = classNames('justify-self-center grow h-6 w-6');
        const copyIconClass = () => {
            let textColorWhite = false;
            if (slide.copiedMedia){
                textColorWhite = true;
            }
    
            return classNames(roundIconsClass, {
                'text-white': textColorWhite
            });
        }

        return (
            <>
                {getErrorJSX(slide.error.soundFileId)}
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        {getMediaUploadIconSpinner()}
                        <input id="soundFile" readOnly={true} type="text" name="soundFile" className={textInputClass} 
                        placeholder={getMediaFileInputPlaceholder(slide, index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleSoundFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', 
                    {hidden: showPasteMediaButton(index, slide.slideType) === false})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handlePasteMediaFile(index)}}>
                            <FiClipboard className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showCopyMediaButton(index, slide)})}>
                        <Button 
                        round={!isDisableAllButtons() && slide.copiedMedia === false}
                        roundSelected={!isDisableAllButtons() && slide.copiedMedia === true}
                        roundDisabled={isDisableAllButtons()} 
                        onClick={() => {handleCopyMediaFile(index)}}>
                            <FiCopy className={copyIconClass()}/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showRemoveMediaButton()})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handleRemoveMediaFile(index)}}>
                            <FiX className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const getUploadImageJSX = (slide, index) => {
        const getImageUploadIconSpinner = () => {
            if(isUploadingMediaFileAtIndex(index) === true){
                return <FaSpinner className="h-6 w-6 mx-2 animate-spin"/>
            }else{
                return <FiImage className={featherIconsClass}/>
            }
        }

        const getMediaFileInputPlaceholder = (slide, index) => {
            if (isUploadingMediaFileAtIndex(index)){
                return "uploading image..."
            }else{
                return slide.imageFileId === null? 'Click to add image': 'Click to change image'
            }
        }  
        
        const showRemoveMediaButton = () => {
            return slide.imageFileId === null;
        }

        let roundIconsClass = classNames('justify-self-center grow h-6 w-6');
        const copyIconClass = () => {
            let textColorWhite = false;
            if (slide.copiedMedia){
                    textColorWhite = true;
            }
    
            return classNames(roundIconsClass, {
                'text-white': textColorWhite
            });
        }

        return (
            <>
                {getErrorJSX(slide.error.imageFileId)}
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        {getImageUploadIconSpinner()}
                        <input id="imageFile" readOnly={true} type="text" name="imageFile" className={textInputClass} 
                        placeholder={getMediaFileInputPlaceholder(slide, index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleImageFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', 
                    {hidden: showPasteMediaButton(index, slide.slideType) === false})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handlePasteMediaFile(index)}}>
                            <FiClipboard className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showCopyMediaButton(index, slide)})}>
                        <Button 
                        round={!isDisableAllButtons() && slide.copiedMedia === false}
                        roundSelected={!isDisableAllButtons() && slide.copiedMedia === true}
                        roundDisabled={isDisableAllButtons()} 
                        onClick={() => {handleCopyMediaFile(index)}}>
                            <FiCopy className={copyIconClass()}/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showRemoveMediaButton()})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handleRemoveMediaFile(index)}}>
                            <FiX className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const getUploadVideoJSX = (slide, index) => {
        const getVideoUploadIconSpinner = () => {
            if(isUploadingMediaFileAtIndex(index) === true){
                return <FaSpinner className="h-6 w-6 mx-2 animate-spin"/>
            }else{
                return <FiVideo className={featherIconsClass}/>
            }
        }

        const getMediaFileInputPlaceholder = (slide, index) => {
            if (isUploadingMediaFileAtIndex(index)){
                return "uploading video..."
            }else{
                return slide.videoFileId === null? 'Click to add video': 'Click to change video'
            }
        } 
        
        const showRemoveMediaButton = () => {
            return slide.videoFileId === null;
        }

        let roundIconsClass = classNames('justify-self-center grow h-6 w-6');
        const copyIconClass = () => {
            let textColorWhite = false;
            if (slide.copiedMedia){
                    textColorWhite = true;
            }
    
            return classNames(roundIconsClass, {
                'text-white': textColorWhite
            });
        }

        return (
            <>
                {getErrorJSX(slide.error.videoFileId)}            
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        {getVideoUploadIconSpinner()}
                        <input id="videoFile" readOnly={true} type="text" name="videoFile" className={textInputClass} 
                        placeholder={getMediaFileInputPlaceholder(slide, index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleVideoFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', 
                    {hidden: showPasteMediaButton(index, slide.slideType) === false})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handlePasteMediaFile(index)}}>
                            <FiClipboard className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showCopyMediaButton(index, slide)})}>
                        <Button 
                        round={!isDisableAllButtons() && slide.copiedMedia === false}
                        roundSelected={!isDisableAllButtons() && slide.copiedMedia === true}
                        roundDisabled={isDisableAllButtons()} 
                        onClick={() => {handleCopyMediaFile(index)}}>
                            <FiCopy className={copyIconClass()}/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showRemoveMediaButton()})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handleRemoveMediaFile(index)}}>
                            <FiX className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const getPreviewSoundJSX = (slide, index) => {
        if (slide.soundFileId === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex flex-col items-center mt-6 w-full')}>
                    <div className="rounded-[20px] w-full h-full">
                        <div className='player-wrapper bg-yellow-1 w-full rounded-[20px]'>
                            <ReactPlayer 
                                url={`https://saythatback-soundfile.s3.amazonaws.com/${slide.soundFileS3Key}.aac`}
                                width="100%"
                                height="100%"
                                controls={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getPreviewImageJSX = (slide, index) => {
        if (slide.imageFileId === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex, rounded-[20px], items-center, w-full', {'hidden': slide.imageFileId === null})} id={'previewImgDiv' + index}>
                    <img id={'previewImg' + index} alt="" 
                    className={classNames('w-full, bg-white, rounded-[20px] mt-2', {'hidden': slide.imageFileId === null})} 
                    src={slide.imageFileS3Key?`https://saythatback-imagefile.s3.amazonaws.com/${slide.imageFileS3Key}_1280x720.jpg`:''} />
                </div>
            );
        }
    }

    const getPreviewVideoJSX = (slide, index) => {
        if (slide.videoFileId === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex, rounded-[20px], items-center, w-full', {'hidden': slide.videoFileId === null})} id={'previewImgDiv' + index}>
                    <div className='player-wrapper w-full rounded-[20px] mt-2'>
                        <ReactPlayer
                            url={`https://saythatback-videofile.s3.amazonaws.com/${slide.videoFileS3Key}.mp4`}
                            className='react-player'
                            width="100%"
                            height="100%"
                            playsinline
                            controls
                        />
                    </div>
                </div>
            )
        }
    }

    const getPlayVideoTillEndJSX = (slide, index) => {
        return(
            <>
                {getErrorJSX(slide.error.playVideoTillEnd)}
                <div className='flex flex-col w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">Play Video Till End</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && !slide.playVideoTillEnd}
                                roundDisabled={isDisableAllButtons() && !slide.playVideoTillEnd}
                                roundSelected={!isDisableAllButtons() && slide.playVideoTillEnd}
                                roundSelectedDisabled={isDisableAllButtons() && slide.playVideoTillEnd}
                                onClick={() => {handlePlayVideoTillEndFieldChange(!slide.playVideoTillEnd, index)}}>
                                <FiChevronsRight className={classNames('justify-self-center grow h-6 w-6',
                                    {'text-white': slide.playVideoTillEnd}
                                )}/>
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    const getSubtitlesJSX = (slide, index) => {
        return(
            <>
                {getErrorJSX(slide.error.subtitlesOn)}
                <div className='flex flex-col w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">Subtitles {slide.subtitlesOn === true?'on':'off'}</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && !slide.subtitlesOn}
                                roundDisabled={isDisableAllButtons() && !slide.subtitlesOn}
                                roundSelected={!isDisableAllButtons() && slide.subtitlesOn}
                                roundSelectedDisabled={isDisableAllButtons() && slide.subtitlesOn}
                                onClick={() => {handleSubtitlesOnFieldChange(!slide.subtitlesOn, index)}}>
                                <FiMessageSquare className={classNames('justify-self-center grow h-6 w-6',
                                    {'text-white': slide.subtitlesOn}
                                )}/>
                            </Button>
                        </div>
                    </div>
                </div>
                {getErrorJSX(slide.error.largeSubtitles)}
                <div className={classNames('flex', 'flex-col', 'w-full', 'pt-3', {hidden: !slide.subtitlesOn})}>
                    <div className="w-full font-montserrat font-extrabold text-xl flex h-full flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">{slide.largeSubtitles===true?'Large':'Small'} subtitles</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && slide.largeSubtitles}
                                roundDisabled={isDisableAllButtons() && slide.largeSubtitles}
                                roundSelected={!isDisableAllButtons() && !slide.largeSubtitles}
                                roundSelectedDisabled={isDisableAllButtons() && !slide.largeSubtitles}
                                onClick={() => {handleLargeSubtitlesFieldChange(!slide.largeSubtitles, index)}}>
                                <FiType className={classNames('justify-self-center grow',
                                    {
                                        'text-white': !slide.largeSubtitles,
                                        'h-6': slide.largeSubtitles,
                                        'w-6': slide.largeSubtitles,
                                        'h-4': !slide.largeSubtitles,
                                        'w-4': !slide.largeSubtitles
                                    }
                                )}/>
                            </Button>
                        </div>
                    </div>
                </div>
                {getErrorJSX(slide.error.subtitlesPosition)}
                <div className={classNames('flex', 'flex-col', 'w-full', 'pt-6', {hidden: !slide.subtitlesOn || slide.largeSubtitles})}>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex-row place-items-center">Small Subtitles Position</div>
                    <div className="flex flex-col mt-6">
                        <div className="flex justify-center">
                            {getSubtitlePositionButtonJSX(slide, 7, index)}
                            {getSubtitlePositionButtonJSX(slide, 8, index)}
                            {getSubtitlePositionButtonJSX(slide, 9, index)}
                        </div>
                        <div className="flex justify-center">
                            {getSubtitlePositionButtonJSX(slide, 4, index)}
                            {getSubtitlePositionButtonJSX(slide, 5, index)}
                            {getSubtitlePositionButtonJSX(slide, 6, index)}
                        </div>
                        <div className="flex justify-center">
                            {getSubtitlePositionButtonJSX(slide, 1, index)}
                            {getSubtitlePositionButtonJSX(slide, 2, index)}
                            {getSubtitlePositionButtonJSX(slide, 3, index)}
                        </div>  
                    </div>
                </div>
            </>
        )
    }

    const getSilenceIntervalJSX = (slide, index) => {
        if (slide.slideType === 3 && slide.playVideoTillEnd === true){
            return (
                <>
                
                </>
            )
        }else{
            return (
                <>
                    {getErrorJSX(slide.error.silenceIntervalAtEnd)}
                    <div className="w-full font-montserrat font-extrabold text-xl pt-6 ml-1">
                        Silence interval at end: {`${Math.floor(slide.silenceIntervalAtEnd / 100)/10}s`}
                    </div>
                    <div className='flex rounded-[20px] items-center w-full px-2 pt-3'>
                        <Slider
                            min={0}
                            max={5000}
                            value={slide.silenceIntervalAtEnd}
                            onChange={(value) => handleSilenceIntervalAtEndChange(value, index)}
                            trackStyle={[{ backgroundColor: '#000000' },]}
                            handleStyle={[{
                                backgroundColor: '#000000',
                                height: 20,
                                width: 20,
                                marginTop: -8
                            }]}
                        />
                    </div>
                </>
            )
        }
    }

    const getLoopBackgroundMusicJSX = (slide, index) => {
        if (slide.soundFileId === null){
            return <></>
        }else{
            const handleLoopBackgroundMusicChange = (checked, index) =>{
                const payload = {
                    loopBackgroundMusic: checked,
                    index: index
                };
                const action = asfAction_updateLoopBackgroundMusic(payload); dispatch(action);
            }
            
            return (
                <>
                    {getDividerJSX()}
                    <div className='flex flex-col w-full pt-3'>
                        {getErrorJSX(slide.error.loopBackgroundMusic)}
                        <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex-row place-items-center ml-1">
                            <div className='flex flex-grow'>
                                {slide.loopBackgroundMusic===true?'Repeat background music':'Play background music once'}
                            </div>
                            <div className="flex">
                                    <Button 
                                        round={!isDisableAllButtons() && !slide.loopBackgroundMusic}
                                        roundDisabled={isDisableAllButtons() && !slide.loopBackgroundMusic}
                                        roundSelected={!isDisableAllButtons() && slide.loopBackgroundMusic}
                                        roundSelectedDisabled={isDisableAllButtons() && slide.loopBackgroundMusic}
                                        onClick={() => {handleLoopBackgroundMusicChange(!slide.loopBackgroundMusic, index)}}>
                                        <FiRepeat className={classNames('justify-self-center grow h-6 w-6',{'text-white': slide.loopBackgroundMusic,})}/>
                                    </Button>
                                </div>
                        </div>
                    </div>
                </>
            )
        }
    }
    
    //run against css:hidden so outputs are inverted
    const showCopyMediaButton = (index, slide) => {
        if(slide.slideType === 1 && slide.soundFileId !== null){
            for (const [i, thisSlide] of project.slides.entries()){
                if (
                    i !== index && 
                    thisSlide.slideType === 1
                ){
                    return false;
                }
            }
        }else if ((slide.slideType === 2 || slide.slideType === 4) && slide.imageFileId !== null){
            for (const [i, thisSlide] of project.slides.entries()){
                if (
                    i !== index && 
                    (thisSlide.slideType === 2 || thisSlide.slideType === 4)
                ){
                    return false;
                }
            }
        }else if ((slide.slideType === 3 || slide.slideType === 5)  && slide.videoFileId !== null){
            for (const [i, thisSlide] of project.slides.entries()){
                if (
                    i !== index && 
                    (thisSlide.slideType === 3 || thisSlide.slideType === 5)
                ){
                    return false;
                }
            }
        }

        //css:hidden = true
        return true;
    }  

    const showPasteMediaButton = (index, slideType) => {
        if(slideType === 1){
            for (const [i, slide] of project.slides.entries()){
                if (
                    i !== index && 
                    slide.slideType === 1 && 
                    slide.copiedMedia === true
                ){
                    return true;
                }
            }
        }else if (slideType === 2 || slideType === 4){
            for (const [i, slide] of project.slides.entries()){
                if (
                    i !== index && 
                    (slide.slideType === 2 || slide.slideType === 4) && 
                    slide.copiedMedia === true
                ){
                    return true;
                }
            }
        }else if (slideType === 3 || slideType === 5){
            for (const [i, slide] of project.slides.entries()){
                if (
                    i !== index && 
                    (slide.slideType === 3 || slide.slideType === 5) && 
                    slide.copiedMedia === true
                ){
                    return true;
                }
            }
        }

        return false;
    }

    const saveToDraftIconSpinner = () => {
        if (isSavingDraft()){
            return <FaSpinner className="justify-self-start h-6 w-6 animate-spin"/>
        }else{
            return <FiSave className="justify-self-start h-6 w-6"/>
        }
    }

    const saveToDraftLoadBasketIconSpinner = () => {
        if (isSavingDraftLoadingBasket()){
            return <FaSpinner className="justify-self-end h-6 w-6 animate-spin"/>
        }else{
            return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }
    }

    const handleCloseAddNewSlidesModal = () => {
        const action = asfAction_closeAddNewSlideModal(); dispatch(action);
    }

    const handleCloseChangeVoiceModal = () => {
        const action = asfAction_closeChangeModal(); dispatch(action);
    }

    const handleOpenAddNewSlidesModal = (index) => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        const action = asfAction_openAddNewSlideModal(index); dispatch(action);
    }

    const handleAddBackgroundMusic = () => {
        const action = asfAction_addBackgroundMusicSlide(); dispatch(action);
    }

    const handleAddTTSImage = () => {
        const action = asfAction_addTTSImageSlide(); dispatch(action);
    }

    const handleAddTTSVideo = () => {
        const action = asfAction_addTTSVideoSlide(); dispatch(action);
    }

    const handleAddNoTTSImage = () => {
        const action = asfAction_addNoTTSImageSlide(); dispatch(action);
    }

    const handleAddNoTTSVideo = () => {
        const action = asfAction_addNoTTSVideoSlide(); dispatch(action);
    }

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const parentPage = pagesState[1];
        if (parentPage === 'DraftProjectsPage'){
            getDraftProjects(dispatch, sessionState);
        }
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep2 />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Let's start making your movie
                </div>
                <div className='flex, rounded-[20px], items-center, w-full'>
                    <div className='player-wrapper w-full rounded-[20px] mt-2'>
                        <ReactPlayer
                            url={`https://s3.amazonaws.com/saythatback.com-static-assets/newProjectPage1.mp4`}
                            className='react-player'
                            width="100%"
                            height="100%"
                            playsinline
                            controls
                        />
                    </div>
                </div>
                {getErrorJSX(project.error.topErrorErrorMessage)}
                {getErrorJSX(project.error.title)}
                {getErrorJSX(project.error.slides)}
                <div className={inputFieldDivClass}>
                    <FiEdit2 className={featherIconsClass}/>
                    <input type="text" name="title" className={textInputClass} placeholder="Your Project Title" 
                    value={asfFormState.project.title} onChange={handleTitleFieldChange}/>
                </div>
                {getSlidesJSX()}
                <div className={classNames('w-full bg-white text-center flex flex-row mt-6 gap-5', {'hidden': project.slides.length > 0})}>
                    <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                        className="basis-1/1" onClick={() => {
                            handleOpenAddNewSlidesModal(project.slides.length)
                        }}>
                        <FiPlus className="justify-self-start h-6 w-6"/>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Add New Slide
                        </div>
                    </Button>
                </div>  
                {getErrorJSX(project.error.topErrorErrorMessage)}
                {getErrorJSX(project.error.title)}
                {getErrorJSX(project.error.slides)}
                <div className="w-full bg-white text-center flex flex-row mt-6 gap-5">
                    <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                        className="basis-1/2" onClick={handleSaveToDraftsClick}>
                        {saveToDraftIconSpinner()}
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Save Draft
                        </div>
                    </Button>
                    <Button primary={!isDisableAllButtons()} primaryDisabled={isDisableAllButtons()} 
                        className="basis-full" onClick={handleSaveToDraftsLoadBasketClick}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Price Breakdown
                        </div>
                        {saveToDraftLoadBasketIconSpinner()}
                    </Button>
                </div>  
                <NewSlideModal 
                    isOpen={asfFormState.isAddNewSlideModalOpen} 
                    isDisableAllButtons={isDisableAllButtons} 
                    handleCloseModal={handleCloseAddNewSlidesModal}
                    handleAddBackgroundMusic={handleAddBackgroundMusic}
                    handleAddTTSImage={handleAddTTSImage}
                    handleAddTTSVideo={handleAddTTSVideo}
                    handleAddNoTTSImage={handleAddNoTTSImage}
                    handleAddNoTTSVideo={handleAddNoTTSVideo}
                />
                <ChangeVoiceModal 
                    isOpen={asfFormState.isChangeVoiceModalOpen}
                    isDisableAllButtons={isDisableAllButtons}
                    handleCloseModal={handleCloseChangeVoiceModal}
                    handleChangeVoiceClick={handleChangeVoiceClick}
                    voices={voices}
                />
            </div>
        </div> 

    )
}

export default AddSlidesPage;