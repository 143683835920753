import { createSlice } from "@reduxjs/toolkit";
import { getJSDateFromJSONDate } from "../../utils/utils";
import { apiAction_endDeleteProjectLoadCompletedProjects, apiAction_endGetCompletedProject, apiAction_endGetCopyProject, apiAction_endGetFineTuneProject, apiAction_endLoadCompletedProjects, pagesAction_closeMenuOpenPage } from "../actions";

const completedProjectsSlice = createSlice({
    name: 'completedProjectsPage',
    initialState: getInitialState(),
    reducers: {
        cpAction_setCompletedDeleteProjectId(state, action){
            state['deleteProjectId'] = action.payload;
        },
        cpAction_setCompletedCopyProjectId(state, action){
            state['copyProjectId'] = action.payload;
        }
    },
    extraReducers(builder){
        //when  page is OPENED
        builder.addCase(pagesAction_closeMenuOpenPage, (state, action) => {
            if(action.payload && action.payload === 'CompletedProjectsPage'){
                state = getInitialState(state, action);
            }
        });
        builder.addCase(apiAction_endLoadCompletedProjects, (state, action) => {
            resetErrorFields(state, action);

            const res = action.payload.res;
            if(action.payload.completedProjectsPage && res.success && res.success === true){
                //convert json.projects to state.projects
                jsonProjectsToStateProjects(state, action, res.data.projects);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                state.error.topErrorErrorMessage = res.error_description;
            }
        });
        builder.addCase(apiAction_endDeleteProjectLoadCompletedProjects, (state, action) => {
            resetErrorFields(state, action);

            const res = action.payload.res;
            const projectId = action.payload.projectId;
            state['deleteProjectId'] = null;
            if(action.payload.completedProjectsPage && res.success && res.success === true){
                jsonProjectsToStateProjects(state, action, res.data.projects);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else{
                setErrorOnProject(state, action, projectId, res.error_description);
            }
        });
        builder.addCase(apiAction_endGetCopyProject, (state, action) => {
            resetErrorFields(state, action);

            const res = action.payload.res;
            const projectId = action.payload.projectId;
            state.copyProjectId = null
            if(action.payload.completedProjectsPage && res.success && res.success === true){
                
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                setErrorOnProject(state, action, projectId, res.error_description);
            }
        });
        builder.addCase(apiAction_endGetCompletedProject, (state, action) => {
            resetErrorFields(state, action);
            const res = action.payload.res;
            const projectId = action.payload.projectId;
            if(res.success && res.success === true){
                completedJSONProjectToStateProject(state, action, res.data.project)
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                setErrorOnProject(state, action, projectId, res.error_description);
            }
        });
        builder.addCase(apiAction_endGetFineTuneProject, (state, action) => {
            resetErrorFields(state, action);

            const res = action.payload.res;
            const projectId = action.payload.projectId;
            if(res.success && res.success === true){
                
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                state.completedProject.error.topErrorErrorMessage = res.error_description;
            }
        })
    }
});

const setErrorOnProject = (state, action, projectId, error_description) => {
    for (const project of state.projects){
        if (project.id === projectId){
            project.error.topErrorErrorMessage = error_description;
            break;
        }
    }
}

const jsonProjectsToStateProjects = (state, action, jsonProjects) => {
    for (const jsonProject of jsonProjects){
        jsonProject.creationDate = getJSDateFromJSONDate(jsonProject.creationDate);

        //ERROR STATES
        jsonProject.error = {};
        jsonProject.error.topErrorErrorMessage = null;
    }
    
    state.projects = jsonProjects;
}

const completedJSONProjectToStateProject = (state, action, jsonProject) => {
    jsonProject.creationDate = getJSDateFromJSONDate(jsonProject.creationDate);
    jsonProject.paidDate = getJSDateFromJSONDate(jsonProject.paidDate);
    jsonProject.readyDate = getJSDateFromJSONDate(jsonProject.readyDate);

    jsonProject.error = {};
    jsonProject.error.topErrorErrorMessage = null;

    state.completedProject = jsonProject;
}

const resetErrorFields = (state, action) => {
    for (const project of state.projects){
        project.error.topErrorErrorMessage = null;
    }

    state.error.topErrorErrorMessage = null;

    if (state.completedProject && state.completedProject.error && state.completedProject.error.topErrorErrorMessage){
        state.completedProject.error.topErrorErrorMessage = null;
    }
}

function getInitialState() {
    let returnState = {
        //CompletedProjectsPage
        projects:[],
        completedProject: null,
        copyProjectId: null,
        deleteProjectId: null,

        //ERROR STATES
        error: {
            topErrorErrorMessage: null
        }
    };

    return returnState;
}

export const { 
    cpAction_setCompletedCopyProjectId,
    cpAction_setCompletedDeleteProjectId,
} = completedProjectsSlice.actions;
export const completedProjectsReducer = completedProjectsSlice.reducer;
