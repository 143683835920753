import { createSlice } from "@reduxjs/toolkit";
import { apiAction_endSignOut, apiAction_endSubmitEmailPassword, 
    apiAction_endUploadManualText, apiAction_endResetPassword2,
    apiAction_endIsSessionValid, 
    apiAction_endSubmitUrl, 
    apiAction_endUploadMediaFile,
    apiAction_endSaveToDraft,
    apiAction_endGetDraftProjects,
    apiAction_endGetDraftProject,
    apiAction_endGetCopyProject,
    apiAction_endDeleteProjectLoadDraftProjects,
    apiAction_endSaveToDraftLoadBasket,
    apiAction_endCreatePaymentIntent,
    apiAction_endLoadCompletedProjects,
    apiAction_endGetCompletedProject,
    pagesAction_resetToInitialState,
    apiAction_endUploadVoiceImageFile,
    apiAction_endGetFineTuneProject} from "../actions";
import { apiAction_endDeleteProjectLoadCompletedProjects, apiAction_endStripeANDcheckUpdatePaymentIntentStatus } from "./APISlice";


const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        isLoggedIn: false,
        token: '',
        creationDate: null, //stored as string format in UTC - `UTC:yyyy-mm-dd'T'HH:MM:ss'Z'`
        expiryDate: null //stored as string format in UTC - `UTC:yyyy-mm-dd'T'HH:MM:ss'Z'`
    },
    reducers: {
        sessionAction_setLoggedIn(state, action){
            _sessionAction_setLoggedIn(state, action);
        }
    },
    extraReducers(builder){
        builder.addCase(apiAction_endIsSessionValid, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endSubmitEmailPassword, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endSignOut, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endUploadManualText, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endResetPassword2, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endSubmitUrl, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endUploadMediaFile, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endSaveToDraft, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endSaveToDraftLoadBasket, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endGetDraftProjects, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endLoadCompletedProjects, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endGetDraftProject, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endGetCompletedProject, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endGetCopyProject, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endDeleteProjectLoadDraftProjects, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endDeleteProjectLoadCompletedProjects, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endCreatePaymentIntent, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endStripeANDcheckUpdatePaymentIntentStatus, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endUploadVoiceImageFile, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
        builder.addCase(apiAction_endGetFineTuneProject, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });


        builder.addCase(pagesAction_resetToInitialState, (state, action) => {
            getSessionFromActionPayloadAndSetLoggedInOrOut(state, action);
        });
    }
});

const getSessionFromActionPayloadAndSetLoggedInOrOut = (state, action) =>{
    if(action.payload.session){
        const myAction =  {
            type: action.type,
            payload: {
                isLoggedIn: action.payload.session.isLoggedIn,
                token: action.payload.session.token,
                creationDate: action.payload.session.creationDate,
                expiryDate: action.payload.session.expiryDate
            }
        }
        _sessionAction_setLoggedIn(state, myAction);
    }
}

const _sessionAction_setLoggedIn = (state, action) => {
    if (action.payload.isLoggedIn === true){
        state['isLoggedIn'] = true;
        state['token'] = action.payload.token;
        state['creationDate'] = action.payload.creationDate;
        state['expiryDate'] = action.payload.expiryDate;
    }else{
        state['isLoggedIn'] = false;
        state['token'] = '';
        state['creationDate'] = null;
        state['expiryDate'] = null;
    }
}

export const { 
    sessionAction_setLoggedIn
} = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
