import dateFormat from "dateformat";

const JSDateToJSONDateFormat = (jsDate) => {
    return dateFormat(jsDate, `UTC:yyyy-mm-dd'T'HH:MM:ss'Z'`);
}

const getJSDateFromJSONDate = (jsonDate) => {
    if (jsonDate === null){
        return null;
    }else{
        return new Date(jsonDate);
    }
}

const BASE_WEBSITE_URL = () => {
/*
In React applications, process.env.NODE_ENV is usually one of the following:

1) development: when running the development server, such as when using npm start or yarn start.
2) test: when running tests, for example with Jest.
3) production: when the code has been built for production, such as with npm run build or yarn build.
*/
    if (process.env.NODE_ENV === 'production'){
        return 'https://saythatback.com/';
    }else if (process.env.NODE_ENV === 'development'){
        return 'http://192.168.1.218:3000/'
    }
}

const msToHMS = (ms) => {
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    let hours = Math.floor(ms / (1000 * 60 * 60));
  
    let formattedDuration = '';
    if (hours > 0) {
        formattedDuration += `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}s`;
    }else if (minutes > 0 && hours < 1) {
        formattedDuration += `${minutes}:${seconds.toString().padStart(2, '0')}s`;
    }else if (minutes < 1 && hours < 1) {
        formattedDuration += `${seconds} seconds`;
    }
  
    return formattedDuration;
}

const getWordCount = (str) => {
    if (str.trim().length === 0){
        return 0;
    }
    
    return getWordsArray(str).length;
}

const getWordsArray = (str) => {
    //\w = word character
    //    \b = wordBoundry
    const regex = /[\′\'\`\w]+\b/g;
    return str.trim().match(regex);
}
  

export { 
    JSDateToJSONDateFormat, getJSDateFromJSONDate, BASE_WEBSITE_URL, msToHMS,
    getWordCount
}