import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSessionValidStatus: '', // '' || 'waiting'
    submitEmailStatus: '', // '' || waiting
    submitEmailPasswordStatus: '', //'' || waiting
    signOutStatus: '', //'' || waiting
    uploadManualTextStatus: '', // '' || waiting
    resetPassword1Status: '',// '' || waiting
    resetPassword2Status: '', // '' || waiting
    submitUrlStatus: '', // '' || 'waiting'
    uploadMediaFileIndexWaitingArray: [],
    saveToDraftStatus: '', // '' || 'waiting'
    saveToDraftLoadBasketStatus: '', // '' || 'waiting'
    loadDraftProjectsStatus: '', // '' || 'waiting'
    loadCompletedProjectsStatus: '', // '' || 'waiting'
    getDraftAudioStatus: '', // '' || 'waiting'
    getCopyProject_projectId: null,
    getCompletedProjectStatus: '', // '' || 'waiting'
    getCompletedProject_projectId: null, 
    getCopyProjectStatus: '', //'' || 'waiting'
    getCopyProject_projectId: null,
    deleteProjectLoadDraftProjectsStatus: '', // '' || 'waiting'
    deleteProjectLoadCompletedProjectsStatus: '', // '' || 'waiting'
    createPaymentIntentStatus: '', // '' || 'waiting'
    stripeANDcheckUpdatePaymentIntentStatus: '', // '' || 'waiting'
    downloadCompletedProjectStatus: '', // '' || 'waiting'
    uploadVoiceImageFileStatus: '', // '' || 'waiting'
    uploadVoiceAudioFileWaitingArray: [],
    saveCustomVoiceStatus: '', // '' || 'waiting'
    deleteCustomVoiceWaitingArray: [],
    getFineTuneProjectStatus: '', // '' || 'waiting'
    revertToOriginalFineTuneProjectStatus: '', // '' || 'waiting'
    saveFineTuneProjectStatus: '', // '' || 'waiting'
    saveSubmitFineTuneProjectStatus: '', // '' || 'waiting'
}

const apiSlice = createSlice({
    name: 'api',
    initialState: {...initialState},
    reducers: {
        apiAction_startIsSessionValid(state, action){
            state['isSessionValidStatus'] = 'waiting';
        },
        apiAction_endIsSessionValid(state, action){
            state['isSessionValidStatus'] = '';
        },
        apiAction_startSubmitEmail(state, action){
            state['submitEmailStatus'] = 'waiting';
        },
        apiAction_endSubmitEmail(state, action){
            state['submitEmailStatus'] = '';
        },
        apiAction_startSubmitEmailPassword(state, action){
            state['submitEmailPasswordStatus'] = 'waiting';
        },
        apiAction_endSubmitEmailPassword(state, action){
            state['submitEmailPasswordStatus'] = '';
        },
        apiAction_startSignOut(state, action){
            state['signOutStatus'] = 'waiting';
        },
        apiAction_endSignOut(state, action){
            state['signOutStatus'] = '';
        },
        apiAction_startUploadManualText(state, action){
            state['uploadManualTextStatus'] = 'waiting';
        },
        apiAction_endUploadManualText(state, action){
            state['uploadManualTextStatus'] = '';
        },
        apiAction_startResetPassword1(state, action){
            state['resetPassword1Status'] = 'waiting';
        },
        apiAction_endResetPassword1(state, action){
            state['resetPassword1Status'] = '';
        },
        apiAction_startResetPassword2(state, action){
            state['resetPassword2Status'] = 'waiting';
        },
        apiAction_endResetPassword2(state, action){
            state['resetPassword2Status'] = '';
        },
        apiAction_startSubmitUrl(state, action){
            state['submitUrlStatus'] = 'waiting';
        },
        apiAction_endSubmitUrl(state, action){
            state['submitUrlStatus'] = '';
        },
        apiAction_startUploadMediaFile(state, action){
            const index = action.payload;
            state["uploadMediaFileIndexWaitingArray"].push(index);
        },
        apiAction_endUploadMediaFile(state, action){
            const index = action.payload.index;
            removeItemAll(state["uploadMediaFileIndexWaitingArray"], index);
        },
        apiAction_startSaveToDraft(state, action){
            state['saveToDraftStatus'] = 'waiting';
        },
        apiAction_endSaveToDraft(state, action){
            state['saveToDraftStatus'] = '';
        },
        apiAction_startSaveToDraftLoadBasket(state, action){
            state['saveToDraftLoadBasketStatus'] = 'waiting';
        },
        apiAction_endSaveToDraftLoadBasket(state, action){
            state['saveToDraftLoadBasketStatus'] = '';
        },
        apiAction_startLoadDraftProjects(state, action){
            state['loadDraftProjectsStatus'] = 'waiting';
        },
        apiAction_endGetDraftProjects(state, action){
            state['loadDraftProjectsStatus'] = '';
        },
        apiAction_startLoadCompletedProjects(state, action){
            state['loadCompletedProjectsStatus'] = 'waiting';
        },
        apiAction_endLoadCompletedProjects(state, action){
            state['loadCompletedProjectsStatus'] = '';
        },
        apiAction_startGetDraftProject(state, action){
            const projectId = action.payload.projectId;
            state['getDraftAudioStatus'] = 'waiting';
            state['getCopyProject_projectId'] = projectId;
        },
        apiAction_endGetDraftProject(state, action){
            state['getDraftAudioStatus'] = '';
            state['getCopyProject_projectId'] = null;
        },
        apiAction_startGetCompletedProject(state, action){
            const projectId = action.payload.projectId;
            state['getCompletedProjectStatus'] = 'waiting';
            state['getCompletedProject_projectId'] = projectId;
        },
        apiAction_endGetCompletedProject(state, action){
            state['getCompletedProjectStatus'] = '';
            state['getCompletedProject_projectId'] = null;
        },
        apiAction_startGetCopyProject(state, action){
            const audioId = action.payload.audioId;
            state['getCopyProjectStatus'] = 'waiting';
            state['getCopyProject_projectId'] = audioId;
        },
        apiAction_endGetCopyProject(state, action){
            state['getCopyProjectStatus'] = '';
            state['getCopyProject_projectId'] = null;
        },
        apiAction_startDeleteProjectLoadDraftProjects(state, action){
            state['deleteProjectLoadDraftProjectsStatus'] = 'waiting';
        },
        apiAction_endDeleteProjectLoadDraftProjects(state, action){
            state['deleteProjectLoadDraftProjectsStatus'] = '';
        },
        apiAction_startDeleteProjectLoadCompletedProjects(state, action){
            state['deleteProjectLoadCompletedProjectsStatus'] = 'waiting';
        },
        apiAction_endDeleteProjectLoadCompletedProjects(state, action){
            state['deleteProjectLoadCompletedProjectsStatus'] = '';
        },
        apiAction_startCreatePaymentIntent(state, action){
            state['createPaymentIntentStatus'] = 'waiting';
        },
        apiAction_endCreatePaymentIntent(state, action){
            state['createPaymentIntentStatus'] = '';
            state['stripeANDcheckUpdatePaymentIntentStatus'] = '';
        },
        apiAction_startStripeANDcheckUpdatePaymentIntentStatus(state, action){
            state['stripeANDcheckUpdatePaymentIntentStatus'] = 'waiting';
        },
        apiAction_endStripeANDcheckUpdatePaymentIntentStatus(state, action){
            state['createPaymentIntentStatus'] = '';
            state['stripeANDcheckUpdatePaymentIntentStatus'] = '';
        },
        apiAction_startDownloadCompletedProjectStatus(state, action){
            state['downloadCompletedProjectStatus'] = 'waiting';
        },
        apiAction_startUploadVoiceImageFile(state, action){
            state.uploadVoiceImageFileStatus = 'waiting';
        },
        apiAction_endUploadVoiceImageFile(state, action){
            state.uploadVoiceImageFileStatus = '';
        },
        apiAction_startUploadVoiceAudioFile(state, action){
            const index = action.payload;
            state.uploadVoiceAudioFileWaitingArray.push(index);
        },
        apiAction_endUploadVoiceAudioFile(state, action){
            const index = action.payload.index;
            removeItemAll(state.uploadVoiceAudioFileWaitingArray, index);
        },
        apiAction_startSaveCustomVoice(state, action){
            state.saveCustomVoiceStatus = 'waiting';
        },
        apiAction_endSaveCustomVoice(state, action){
            state.saveCustomVoiceStatus = '';
        },
        apiAction_startDeleteCustomVoice(state, action){
            const voice = action.payload;
            state.deleteCustomVoiceWaitingArray.push(voice.id);
        },
        apiAction_endDeleteCustomVoice(state, action){
            const voiceId = action.payload.voice.id;
            removeItemAll(state.deleteCustomVoiceWaitingArray, voiceId);
        },
        apiAction_startGetFineTuneProject(state, action){
            state.getFineTuneProjectStatus = 'waiting';
        },
        apiAction_endGetFineTuneProject(state, action){
            state.getFineTuneProjectStatus = '';
        },
        apiAction_startRevertToOriginalFineTuneProjectStatus(state, action){
            state.revertToOriginalFineTuneProjectStatus = 'waiting';
        },
        apiAction_endRevertToOriginalFineTuneProjectStatus(state, action){
            state.revertToOriginalFineTuneProjectStatus = '';
        },
        apiAction_startSaveFineTuneProjectStatus(state, action){
            state.saveFineTuneProjectStatus = 'waiting';
        },
        apiAction_endSaveFineTuneProjectStatus(state, action){
            state.saveFineTuneProjectStatus = '';
        },
        apiAction_startSaveSubmitFineTuneProjectStatus(state, action){
            state.saveSubmitFineTuneProjectStatus = 'waiting';
        },
        apiAction_endSaveSubmitFineTuneProjectStatus(state, action){
            state.saveSubmitFineTuneProjectStatus = '';
        },


        
        
        //NOT CALL TO API but just download multiple files await
        apiAction_endDownloadCompletedProjectStatus(state, action){
            state['downloadCompletedProjectStatus'] = '';
        },

        apiAction_resetAllOnReload(state, action){
            return initialState;
        }
    },
});

const removeItemAll = (arr, value) => {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

export const { 
    apiAction_startIsSessionValid, apiAction_endIsSessionValid,
    apiAction_startSubmitEmail, apiAction_endSubmitEmail,
    apiAction_startSubmitEmailPassword, apiAction_endSubmitEmailPassword,
    apiAction_startSignOut, apiAction_endSignOut, 
    apiAction_startUploadManualText, apiAction_endUploadManualText,
    apiAction_startResetPassword1, apiAction_endResetPassword1,
    apiAction_startResetPassword2, apiAction_endResetPassword2,
    apiAction_startSubmitUrl, apiAction_endSubmitUrl,
    apiAction_startUploadMediaFile, apiAction_endUploadMediaFile,
    apiAction_startSaveToDraft, apiAction_endSaveToDraft,
    apiAction_startSaveToDraftLoadBasket, apiAction_endSaveToDraftLoadBasket,
    apiAction_startLoadDraftProjects, apiAction_endGetDraftProjects,
    apiAction_startLoadCompletedProjects, apiAction_endLoadCompletedProjects,
    apiAction_startGetDraftProject, apiAction_endGetDraftProject,
    apiAction_startGetCompletedProject, apiAction_endGetCompletedProject,
    apiAction_startGetCopyProject, apiAction_endGetCopyProject,
    apiAction_startDeleteProjectLoadDraftProjects, apiAction_endDeleteProjectLoadDraftProjects,
    apiAction_startDeleteProjectLoadCompletedProjects, apiAction_endDeleteProjectLoadCompletedProjects,
    apiAction_startCreatePaymentIntent, apiAction_endCreatePaymentIntent,
    apiAction_startStripeANDcheckUpdatePaymentIntentStatus, apiAction_endStripeANDcheckUpdatePaymentIntentStatus,
    apiAction_startDownloadCompletedProjectStatus, apiAction_endDownloadCompletedProjectStatus,
    apiAction_startUploadVoiceImageFile, apiAction_endUploadVoiceImageFile,
    apiAction_startUploadVoiceAudioFile, apiAction_endUploadVoiceAudioFile,
    apiAction_startSaveCustomVoice, apiAction_endSaveCustomVoice,
    apiAction_startDeleteCustomVoice, apiAction_endDeleteCustomVoice,
    apiAction_startGetFineTuneProject, apiAction_endGetFineTuneProject,
    apiAction_startRevertToOriginalFineTuneProjectStatus, apiAction_endRevertToOriginalFineTuneProjectStatus,
    apiAction_startSaveFineTuneProjectStatus, apiAction_endSaveFineTuneProjectStatus,
    apiAction_startSaveSubmitFineTuneProjectStatus, apiAction_endSaveSubmitFineTuneProjectStatus,
    apiAction_resetAllOnReload
} = apiSlice.actions;
export const apiReducer = apiSlice.reducer;
