import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import ReactPlayer from 'react-player';
import classNames from "classnames";
import OpenMenuTopBar from "../components/OpenMenuTopBar";
import { pagesAction_cleanStackOpenTwoPages, ppAction_SetAudio } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeep3.svg";
import { ReactComponent as PlayButton } from "../images/svg/play_button.svg";
import { ReactComponent as PauseButton } from "../images/svg/pause_button.svg";
import { ReactComponent as BookOpen } from "../images/svg/book_open.svg";
import { ReactComponent as FileText } from "../images/svg/assets.svg";
import { ReactComponent as Film } from "../images/svg/film.svg";
import { pagesAction_openPage } from "../store";
import Button from "../components/buttons/Button";
import { FiChevronRight } from "react-icons/fi";
import { pagesAction_switchPage } from "../store/actions";
import { getBackgroundCSS } from "../components/css";

function PricingPage({count}){    
    const dispatch = useDispatch();

    const pricingPageState = useSelector((state) => {return state.pricingPage;});

    let backgroundClass = getBackgroundCSS(count, 'bg-yellow-1');

    const openMenuPage = () =>{
        const action = pagesAction_openPage('MenuPage'); dispatch(action);
    }

    const handleAddSlidesPageClick = () => {
        const pageName = ['DraftProjectsPage', 'AddSlidesPage'];
        const action = pagesAction_cleanStackOpenTwoPages(pageName); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <OpenMenuTopBar onClick={openMenuPage} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center space-y-2">
                <OpenPeep />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl p-6 text-center rounded-[20px] ">
                    <div className="text-lg sm:lg font-medium">
                        Welcome to
                    </div>
                    <div>
                        SayThatBack.com
                    </div> 
                </div>
                <div className='flex, rounded-[20px], items-center, w-full'>
                    <div className='player-wrapper w-full rounded-[20px] mt-2'>
                        <ReactPlayer
                            url={`https://s3.amazonaws.com/saythatback.com-static-assets/pricingPageVideo1.mp4`}
                            className='react-player'
                            width="100%"
                            height="100%"
                            playsinline
                            controls
                        />
                    </div>
                </div>
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center space-y-3">
                    <div className="flex flex-row flex-wrap">
                        <div className="flex flex-col align-middle w-full border-black border-2 rounded-[20px] py-10 bg-black text-white">
                            <div className="text-5xl text-white">
                                PRICING
                            </div>
                            <div className="flex flex-row pt-6 ">
                                <div className="text-center font-medium text-base text-white w-1/3">
                                    <div className="font-extrabold text-lg text-center pb-3">WordSpeaker</div>
                                    <div>£0.01/word</div>
                                </div>
                                <div className="text-center font-medium text-base text-white w-1/3">
                                    <div className="font-extrabold text-lg text-center pb-3">Slides</div>
                                    <div>£0.02/slide</div>
                                </div>
                                <div className="text-center text-xl font-medium text-base text-white w-1/3">
                                    <div className="font-extrabold text-lg text-center pb-3">Processing Fee</div>
                                    <div>£0.20</div>
                                </div>
                            </div>
                            <div className="flex flex-row pt-6 ">
                                <div className="text-center font-normal italic text-sm text-white px-12 w-full">
                                    minimum cost for each project is always £0.50
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col align-middle w-full border-black border-2 rounded-[20px] py-10 mt-6 text-white">
                            <div className="text-5xl text-black">
                                Example
                            </div>
                            <div className="flex flex-row pt-6 ">
                                <div className="text-text-center font-medium text-base text-black px-12 w-1/3">
                                    <div className="font-extrabold text-lg text-center pb-3">WordSpeaker</div>
                                    <div>500</div>
                                    <div className="mt-2 font-bold">£5.00</div>
                                </div>
                                <div className="text-text-center font-medium text-base text-black px-12 w-1/3">
                                    <div className="font-extrabold text-lg text-center pb-3">Slides</div>
                                    <div>12</div>
                                    <div className="mt-2 font-bold">£0.24</div>
                                </div>
                                <div className="text-text-center text-xl font-medium text-base text-black w-1/3">
                                    <div className="font-extrabold text-lg text-center pb-3">Processing Fee</div>
                                    <div>£20p</div>
                                    <div className="mt-2 font-bold">£0.20</div>
                                </div>
                            </div>
                            <div className="flex flex-row pt-6 ">
                                <div className="text-text-center font-medium text-lg text-black w-full">
                                    Movie Length: approx 10min
                                </div>
                            </div>
                            <div className="flex flex-row pt-6 ">
                                <div className="text-text-center font-solid text-3xl text-black w-full">
                                    Total: £5.44
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center space-y-3">
                    <div className="text-2xl sm:3xl">
                        Try it out:
                    </div>
                    <Button primary onClick={handleAddSlidesPageClick}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Start a project
                        </div>
                        <FiChevronRight className="justify-self-end h-6 w-6"/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PricingPage;