import { createSlice } from "@reduxjs/toolkit";
import { pagesAction_closeMenuOpenPage, pagesAction_openPage } from "../actions";

const pricingPageSlice = createSlice({
    name: 'pricingPage',
    initialState: {
        audio1: false, // paused || playing
        video2: false, // paused || playing
        audio3: false, // paused || playing
        audio4: false, // paused || playing
    },
    reducers: {
        ppAction_SetAudio(state, action){
            if (action.payload.media_status === false){
                setToOff(state, action);
            }else{
                setToOff(state, action);
                if (action.payload.media_asset === 1){
                    state['audio1'] = true;
                }else if (action.payload.media_asset === 2){
                    state['video2'] = true;
                }else if(action.payload.media_asset === 3){
                    state['audio3'] = true;
                }else if(action.payload.media_asset === 4){
                    state['audio4'] = true;
                }
            }
        }
    },
    extraReducers(builder){
        //when  page is OPENED
        builder.addCase(pagesAction_closeMenuOpenPage, (state, action) => {
            if(action.payload && action.payload === 'PricingPage'){
                setToOff(state, action);
            }
        });
        builder.addCase(pagesAction_openPage, (state, action) => {
            if(action.payload && action.payload === 'MenuPage'){
                let myAction = {
                    type: action.type,
                    payload: null
                };
                setToOff(state, myAction);
            }
        })
    }
});

const setToOff = (state, action) =>{
    state['audio1'] = false;
    state['video2'] = false;
    state['audio3'] = false;
    state['audio4'] = false;
}

export const { ppAction_SetAudio, } = pricingPageSlice.actions;
export const pricingPageReducer = pricingPageSlice.reducer;
