import axios from "axios";
import axiosRetry from 'axios-retry';
import { BASE_API_URL, handleAxiosError } from "./apiUtils";


const save_custom_voice = async (voice, token) => {
    const json = JSON.stringify({ 
        token: token,
        voice: voice
    });
    
    let returnObject = undefined;
    try{
        axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
        const res = await axios.post(`${BASE_API_URL()}v2/save_custom_voice`, json, {
            headers: {'Content-Type': 'application/json'}
        });
        returnObject = res.data;
    }catch (error) {
        returnObject = handleAxiosError(error);
    }
    return returnObject;
}

export { save_custom_voice };