import { FiMenu } from "react-icons/fi";
import Button from "./buttons/Button";

function OpenMenuTopBar({ onClick, disabled }){
    return (
        <div className="w-full py-4 px-6 absolute top-0 left-0">
            <Button 
                round={!disabled}
                roundDisabled={disabled}
                onClick={onClick} disabled={disabled}
                >
                <FiMenu className='w-8 h-8' />
            </Button>
        </div>
    )
}

export default OpenMenuTopBar;