import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { FiChevronRight, FiUser, FiLoader } from 'react-icons/fi';
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps4_smile.svg";
import { apiAction_startSignOut, apiAction_endSignOut } from  "../store/slices/APISlice";
import { pagesAction_closePage } from "../store";
import { session_removeCookie } from "../cookie/SessionCookie";
import { sign_out } from "../api/sign_out";
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";
 
 function SignOutPage({count}){
    const dispatch = useDispatch();

    const pagesState = useSelector((state) => {return state.pages;});
    const apiState = useSelector((state) => {return state.api;});
    const sessionState = useSelector((state) => {return state.session;});

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const handleSubmit = async () => {
        //irrespective of res.sucess - we're gonna force signoff on the react
        let action = apiAction_startSignOut(); dispatch(action);
        const res = await sign_out(sessionState.token);
        const payload = { res: res, };
        //irrespective of res.sucess - we're gonna force signoff on the react
        //if (res.success && res.success === true){
        //}
        const sessionObject = {
            isLoggedIn: false,
            token: null,
            creationDate: null,
            expiryDate: null
        }
        payload['session'] = sessionObject;
        payload['pages'] = {
            pagesAction: 'pagesAction_resetToInitialState',
            nextPage: null
        }

        //REMOVE COOKIES
        session_removeCookie();

        action = apiAction_endSignOut(payload); dispatch(action);
    }

    const isSignOutStatusWaiting = () => {
        return apiState.signOutStatus === 'waiting';
    }

    const getButtonIcon = () => {
        if (isSignOutStatusWaiting() === false){
            return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }else{
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isSignOutStatusWaiting()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep className="w-80" />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Confirm Sign Out 
                </div>
                <div className="w-full bg-white 
                rounded-[20px] p-6 text-center mt-2.5 border-black border-2">
                    <div className="font-montserrat font-medium text-[1.063rem] mb-3">
                        Are you sure you want to sign out on this device?
                    </div>
                    <Button primary onClick={handleSubmit} waiting={isSignOutStatusWaiting()}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Sign Out
                        </div>
                        {getButtonIcon()}
                    </Button>
                </div>
            </div>
        </div>
    )
 }

 export default SignOutPage;