import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { FiChevronRight, FiUser, FiLoader } from 'react-icons/fi';
import { pagesAction_closePage, pagesAction_closeTwoPages } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps4_smile.svg";
import { sipfAction_updateResetPasswordField,
    sipfAction_updateResetCodeField } from "../store";
import { apiAction_startResetPassword2, apiAction_endResetPassword2 } from "../store";
import { session_setCookie } from "../cookie/SessionCookie";
import { reset_password_2 } from "../api/reset_password_2";
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";
 
 function LoginRegisterResetPage({count}){
    const dispatch = useDispatch();

    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const sipFormState = useSelector((state) => {return state.signInPageForm;});
    const apiState = useSelector((state) => {return state.api;});

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const inputFieldDivClass = classNames('flex border-black border-2 rounded-[20px] items-center h-[3.5rem] my-6 px-2.5 w-full');
    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const textInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] focus:outline-none');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'mt-6', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1', {
        'hidden': false
    })

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const passwordErrorDivClass = () => {
        let hiddenValue = true;
        if (sipFormState.resetPasswordFieldState === 'failed-server-test'){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }

    const passwordErrorMessage = () => {
        return sipFormState.resetPasswordErrorMessage;
    };

    const handleResetCodeFieldChange = (e) => {
        const action = sipfAction_updateResetCodeField(e.target.value); dispatch(action);
    };

    const handleSubmit = async () => {
        //check if logged in - if not then present login screen
        let action = apiAction_startResetPassword2(); dispatch(action);
        const res = await reset_password_2(sipFormState.email, sipFormState.resetCode, sipFormState.resetPassword);
        const payload = { res: res, }
        if (res.success && res.success === true && res.status === 'successfully_reset_password_and_signed_in'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'LoginRegisterResetConfirmationPage'
            };
            
            
            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else{
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
        }
        action = apiAction_endResetPassword2(payload); dispatch(action);
    }

    const handleResetPasswordLFieldChange = (e) => {
        const action = sipfAction_updateResetPasswordField(e.target.value); dispatch(action);
    }

    const isResetPassword2StatusWaiting = () => {
        return apiState.resetPassword2Status === 'waiting';
    }

    const getButtonIcon = () => {
        if (isResetPassword2StatusWaiting() === false){
            return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }else{
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className={errorDivClass}>{stateErrorVariable}</div>
            )
        }
        
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isResetPassword2StatusWaiting()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep className="w-80" />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Reset Password
                </div>
                <div className="w-full bg-white 
                rounded-[20px] p-6 text-center mt-2.5 border-black border-2">
                    <div className="font-montserrat font-medium text-[1.063rem]">
                        We’ve emailed you instructions so that you can reset your password below.
                    </div>
                    {getErrorJSX(sipFormState.resetCodeErrorMessage)}
                    {getErrorJSX(sipFormState.resetPasswordErrorMessage)}
                    <div className={passwordErrorDivClass()}>{passwordErrorMessage()}</div>
                    <div className={inputFieldDivClass}>
                        <FiUser className={featherIconsClass}/>
                        <input type="text" name="email" className={textInputClass} placeholder="Email"
                         value={sipFormState.email} readOnly/>
                    </div>
                    <div className={inputFieldDivClass}>
                        <FiUser className={featherIconsClass}/>
                        <input type="text" name="resetCode" className={textInputClass} placeholder="Reset Code"
                        onChange={handleResetCodeFieldChange} value={sipFormState.resetCode}/>
                    </div>
                    <div className={inputFieldDivClass}>
                        <FiUser className={featherIconsClass}/>
                        <input type="password" name="resetPassword" className={textInputClass} placeholder="Password"
                        onChange={handleResetPasswordLFieldChange} value={sipFormState.resetPassword}/>
                    </div>
                    <Button primary waiting={isResetPassword2StatusWaiting()} onClick={handleSubmit}>
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Continue
                        </div>
                        {getButtonIcon()}
                    </Button>
                </div>
            </div>
        </div>
    )
 }

 export default LoginRegisterResetPage;