import axios from "axios";
import axiosRetry from 'axios-retry';
import FormData from 'form-data';
import { BASE_API_URL, handleAxiosError } from "./apiUtils";

const upload_video_file = async (imageFilePath, token,) => {
    const form = new FormData();
    
    form.append(
        'jsonData', 
        JSON.stringify({
            token: token
        })
    );
    form.append('file', imageFilePath);

    let returnObject = undefined;
    try{
        axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
        const res = await axios.post(`${BASE_API_URL()}v2/upload_video_file`, form, {
            headers: {'Content-Type': `multipart/form-data; boundary=${form._boundary}`}
        });
        returnObject = res.data;
    }catch (error) {
        returnObject = handleAxiosError(error);
    }
    return returnObject;
}

export { upload_video_file };