import Cookies from 'js-cookie';
import { JSDateToJSONDateFormat, getJSDateFromJSONDate } from '../utils/utils';

const session_setCookie = (token, creationDate, expiryDate) => {
    Cookies.set('token', token);
    Cookies.set('creationDate', JSDateToJSONDateFormat(creationDate));
    Cookies.set('expiryDate', JSDateToJSONDateFormat(expiryDate));
}

const session_getCookie = () => {

    const token = Cookies.get('token');
    const creationDateString = Cookies.get('creationDate');
    const creationDate = !creationDateString ? creationDateString : new Date(creationDateString);
    const expiryDateString = Cookies.get('expiryDate');
    const expiryDate = !expiryDateString ? expiryDateString : new Date(expiryDateString);

    return {
        'token': token,
        'creationDate': creationDate,
        'expiryDate': expiryDate
    };
}

const session_removeCookie = () => {
    Cookies.remove('token');
    Cookies.remove('creationDate');
    Cookies.remove('expiryDate');
}

const session_isCookieSet = () => {
    const token = Cookies.get('token');
    const expiryDateString = Cookies.get('expiryDate');
    const expiryDate = !expiryDateString ? expiryDateString : getJSDateFromJSONDate(expiryDateString);
    /*
    if (!strValue) {
        // strValue was empty string, false, 0, null, undefined, ...
        falsy: https://developer.mozilla.org/en-US/docs/Glossary/Falsy
    }
    */
    if (!token || !expiryDate || expiryDate < Date.now()){
        return false;
    }else{
        return true;
    }
}

export { 
    session_setCookie, 
    session_getCookie, 
    session_removeCookie,
    session_isCookieSet
};