import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import ReactPlayer from 'react-player';
import classNames from "classnames";
import OpenMenuTopBar from "../components/OpenMenuTopBar";
import { pagesAction_cleanStackOpenTwoPages, ppAction_SetAudio } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeep3.svg";
import { ReactComponent as PlayButton } from "../images/svg/play_button.svg";
import { ReactComponent as PauseButton } from "../images/svg/pause_button.svg";
import { ReactComponent as BookOpen } from "../images/svg/book_open.svg";
import { ReactComponent as FileText } from "../images/svg/assets.svg";
import { ReactComponent as Film } from "../images/svg/film.svg";
import { pagesAction_openPage } from "../store";
import Button from "../components/buttons/Button";
import { FiChevronRight } from "react-icons/fi";
import { pagesAction_switchPage } from "../store/actions";
import { getBackgroundCSS } from "../components/css";

function TACSPage({count}){    
    const dispatch = useDispatch();

    const pricingPageState = useSelector((state) => {return state.pricingPage;});

    let backgroundClass = getBackgroundCSS(count, 'bg-yellow-1');

    const openMenuPage = () =>{
        const action = pagesAction_openPage('MenuPage'); dispatch(action);
    }

    const handleAddSlidesPageClick = () => {
        const pageName = ['DraftProjectsPage', 'AddSlidesPage'];
        const action = pagesAction_cleanStackOpenTwoPages(pageName); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <OpenMenuTopBar onClick={openMenuPage} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center space-y-2">
                <OpenPeep />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl p-6 text-center rounded-[20px] ">
                    <div>
                        Terms & Conditions
                    </div> 
                </div>
                <div className="w-full bg-white font-montserrat 
                text-bg p-6 text-start rounded-[20px] ">
                    <div>
**1. Introduction**
<br /><br />
**1.1 Agreement Acceptance**<br />
By registering, accessing, or utilizing our Services, you confirm your commitment to a binding legal contract with SayThatBack.com. Should you be representing a company, this still applies. If you disagree with these terms of service (“TOS” or “User Agreement”), please refrain from using our Services. To end this User Agreement, simply close your account and cease using our Services.
<br /><br />
**1.2 Scope of Agreement**<br />
This User Agreement encompasses both the https://saythatback.com (“Site”) and any associated sites, applications, and communications under SayThatBack.com ("Services").
<br /><br />
**1.3 Party Definitions**<br />
This User Agreement is established with SayThatBack.com Inc., hereafter referred to as “we” or “us”. Our collection, handling, and distribution of your personal information are governed by our Privacy Policy.
<br /><br />
**1.4 Amendments**<br />
Occasionally, we might alter the User Agreement or Privacy Policy. Should significant changes arise, we promise to notify you to review them prior to their activation. Any changes will not apply retrospectively. Continuing to use our Services after receiving our update notifications implies your acceptance of the amended User Agreement or Privacy Policy from the date they come into effect.
<br /><br />
**2. Responsibilities**
<br /><br />
**2.1 Eligibility**<br />
For accessing and utilizing our Services, you commit to:<br />
2.1.1 Being no younger than the Minimum Age (as defined below).<br />
2.1.2 Maintaining a single account on SayThatBack.com under your genuine name.<br />
2.1.3 Ensuring you have not been previously barred by SayThatBack.com from availing the Services.<br />
2.1.4 Acknowledging that generating accounts with deceitful details or creating multiple/similar accounts, including those shared with other users or those below the Minimum Age, breaches this User Agreement.<br />
The “Minimum Age” is determined as 16 years old. If local regulations mandate an older age for lawful provision of Services without requiring parental consent (this includes handling your personal data), that older age will then represent the Minimum Age.
<br /><br />
**2.2 Account Management**<br />
By using our Services, you agree to:<br />
2.2.1 Maintain your information's accuracy and keep it current.<br />
2.2.2 Treat your password as confidential.<br />
2.2.3 Refrain from sharing or transferring your account. Any activity on your account is your responsibility unless you terminate it or promptly report any discrepancies to team@saythatback.com. Your account remains your personal property, irrespective of any third-party claims (like your employer).
<br /><br />
**2.3 Financial Commitment**<br />
For any premium Services availed, you commit to:<br />
2.3.1 Remitting the due fees and applicable taxes, adhering to any terms relevant to the chosen Service. Defaulting on payments will lead to Service cancellation.<br />
2.3.2 Recognizing potential international transaction fees or varying prices depending on geographic factors (like exchange rates).<br />
2.3.3 Allowing our payment gateway to remember and recurrently bill your selected payment mode (like a credit card).<br />
2.3.4 Agreeing that we might compute your tax obligations based on the billing data shared during purchase.
<br /><br />
**2.4 Subscription and Pricing Updates**<br />
Occasionally, we may revise our subscription options or Service pricing. Any modifications in our subscription offerings will only be applicable post a 30-day notice period.
<br /><br />
**2.5 Reimbursements/Refunds**<br />
You acknowledge that:<br />
2.5.1 All payments are final, and reimbursements are not granted unless specifically mentioned herein.
<br /><br />
**2.6 Communication Modes**<br />
For updates and alerts, we’ll contact you through: (a) within our Service interface, or (b) employing the contact details you’ve shared (like email).
<br /><br />
**3. Rights & Limits**<br />
**3.1 Grant of License for Your Content**<br />
Any content or information you submit to the Services ("Content") remains under your ownership. However, to avail the Services, you provide SayThatBack.com a limited, non-exclusive, non-transferable, global, royalty-free, and permission to further license to access, manage, and transmit this Content. Here’s a closer look:<br />
3.1.1 You can retract the granted license for any Content by either erasing it from the Services or closing your account. This, however, doesn't apply when (i) you've disseminated it within the Service and others have duplicated, re-shared, or retained it, and (ii) due to the duration required to eliminate Content from our backup infrastructure.<br />
3.1.2 We might adjust the formatting of your Content (like translating, transcribing, resizing, altering layout, file type, or excising metadata) without altering its core meaning.<br />
3.1.3 Since you retain ownership of your Content, you are free to share it with others, even under Creative Commons licensing terms, if you wish.<br />
3.1.4 Ensure that your Content neither breaches any law nor infringes on third-party rights.
<br /><br />
**3.2 Service Continuity**<br />
Our Services might undergo changes, interruptions, or even discontinuations. We don’t guarantee indefinite storage or continued display of the Content you post. Remember, SayThatBack.com isn’t a storage entity. You acknowledge our right to not store, retain, or furnish copies of any Content, except when legally mandated or outlined in our Privacy Policy.
<br /><br />
**3.4 Account Restrictions**<br />
SayThatBack.com retains the right to limit, suspend, or deactivate your account if there's a breach of this User Agreement or any misuse of the Services.
<br /><br />
**3.5 Property Rights**<br />
All intellectual property rights related to our Services remain with SayThatBack.com. Any logos or trademarks associated with the Services belong to their respective proprietors.
<br /><br />
**4. Confidentiality**<br />
**4.1 Protecting Your Data**<br />
Any Content provided in line with this User Agreement, referred to as “Your Information”, is treated as confidential by us. We utilize Your Information solely for delivering the Services and won't disclose it to anyone outside of necessary staff, affiliates, or service providers bound by confidentiality. However, the cloak of confidentiality does not extend to:<br />
4.1.1 Information known to us before you shared it or sourced from entities without confidentiality obligations to you.<br />
4.1.2 Information becoming known to us, independently of your sharing, from entities without confidentiality obligations to you.<br />
4.1.3 Information that’s publicly known or isn't secret anymore, unless it’s because of our breach, or if you label such information as “public” or if it's in the public domain of our Site.
<br /><br />
**4.2 Voice Duplication with VoiceSpeaker**<br />
VoiceSpeaker, a feature on SayThatBack.com, enables users to produce voice outputs sounding like a Consenting Speaker. A “VoiceSpeaker” is AI-crafted using voice inputs from a Consenting Speaker. The term “Consenting Speaker” refers to someone (possibly you) who's given clear written permission for their voice to be used for Cloning Voice creation and replication.<br />
For generating new Cloning Voices, you can upload voice recordings of a Consenting Speaker, known as “Training Audio”. By doing this, you:<br />
4.2.1 Agree (and vouch that third-party Consenting Speakers have provided clear written consent) to allow the use and storage of the voice recordings. This means we can use your voice and those of any third-party Consenting Speakers for Cloning Voice training and replication.<br />
4.2.2 Acknowledge that select personnel from SayThatBack.com, along with our service providers and affiliates, might listen to parts of your Training Audio and replicated outputs to validate the Cloning Voice's quality.<br />
4.2.3 Accept that our personnel, affiliates, and service providers can use your Cloning Voice to generate a range of non-defamatory statements, strictly for internal quality checks.<br />
4.2.4 Understand that only you and those you grant access to can produce replicated audio using your Cloning Voice. SayThatBack.com ensures that neither your Training Audio nor your Cloning Voice will be shared beyond the conditions described.<br />
4.2.5 Commit to not uploading voice recordings without the necessary permissions. This means no voices of those who haven’t provided explicit written consent for Cloning Voice creation and replication.<br />
<br /><br />
5. Commercial Rights; Third-Party Content and Services<br />
5.1 Business Usage Rights Voices generated via SayThatBack.com can be utilized for business-oriented activities. It's permissible to share these voices with other entities that wish to use SayThatBack.com-generated voices for business reasons. However, you must recognize that the Commercial Rights (outlined below) don't equate to resale privileges. It's prohibited to resell or propose reselling of any SayThatBack.com services, including the entire collection of voices in the SayThatBack.com Studio, to another party. Additionally, you're bound not to utilize voices generated by SayThatBack.com for training other AI models or for any form of voice synthesis, irrespective of the purpose. "Commercial Rights" encompasses the utilization of voices generated by SayThatBack.com for any business or commerce-oriented goals, with exceptions as stipulated in this User Agreement.<br />
5.2 Content and Services from External Sources SayThatBack.com might present or allow access to data, content, or information derived from third-party sources, including from other SayThatBack.com users, collectively termed as “Third Party Content”. We neither control nor endorse any Third Party Content and offer no guarantees or claims related to them. It's your responsibility to acknowledge that we aren't accountable for any Third Party Content. Moreover, SayThatBack.com doesn't pledge to periodically update or scrutinize Third Party Content.<br />
<br /><br />
**6. Disclaimers & Liability Restrictions**<br />
**6.1 Disclaimers**<br />
We don't assure uninterrupted access to our Site or Services. There may be disruptions due to software, hardware, or other issues, and maintenance might be necessary, causing delays, interruptions, or errors. We also reserve the right to amend, pause, or terminate our Services, and may not provide prior notice.<br />
For users accessing free plans, we can, for any reason, amend, suspend or cease the Services without prior intimation.<br />
For Subscription users: we commit to prior notifications if there are Service changes negatively affecting your usage.<br />
You consent that we aren't accountable for any loss, inconvenience, or damage resulting from your inability to use or access the Site or Services due to any discontinuance or downtime. Nothing in this User Agreement implies an obligation on our end to maintain, provide support, or offer updates or corrections in relation to the Services.
<br /><br />
**6.2 Restriction of Liability**<br />
WITHIN THE BOUNDS OF APPLICABLE LAW, SAYTHATBACK.COM, ITS PERSONNEL, OR AFFILIATES, BEAR NO LIABILITY IN RELATION TO THIS AGREEMENT FOR ANY LOST BUSINESS OPPORTUNITIES, PROFITS, REPUTATIONAL DAMAGES (E.G., DEFAMATORY COMMENTS), LOSS OF DATA (E.G., DOWNTIME OR LOSS OR MODIFICATIONS TO YOUR CONTENT OR INFORMATION) OR ANY THIRD-PARTY INTERACTIONS (LIKE YOUTUBE DENYING VIDEO MONETIZATION WITH SYNTHESIZED VOICES OR ACX REJECTING TEXT-TO-SPEECH BASED AUDIOS), OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR SPECIAL DAMAGES.<br />
SAYTHATBACK.COM AND ITS AFFILIATES SHALL NOT BE LIABLE, UNDER THIS AGREEMENT, FOR AMOUNTS EXCEEDING THE TOTAL FEES YOU'VE PAID TO SAYTHATBACK.COM FOR THE SERVICES DURING THE AGREEMENT'S DURATION.
<br /><br />
**6.3 Basis for the Agreement; Exceptions**<br />
This Section 6's liability restrictions are foundational to the understanding between you and SayThatBack.com. They shall apply to all liability claims, regardless of their nature (e.g., warranty, tort, negligence, contract, etc.), even if SayThatBack.com or its affiliates were informed of possible damages and even if the remedies don't fulfill their primary intent.<br />
However, these liability limitations don't extend to death or personal injury, fraud, gross negligence, or intentional misconduct that directly results from SayThatBack.com’s actions.
<br /><br />
**7. Termination Clause**
<br /><br />
**Termination Rights:** Both you and SayThatBack.com reserve the right to end this User Agreement whenever either party deems necessary. If the agreement is terminated, you will no longer have the rights to access or use the services provided by SayThatBack.com.
<br /><br />
**Post-Termination Conditions:** Despite the termination, certain sections of this User Agreement will remain effective:
- Sections 6, 8, 10, and 10.2 of the Agreement.
- Any financial obligations or debts that were outstanding before the termination will continue to remain valid and need to be fulfilled.
<br /><br />
**8. Jurisdiction and Legal Proceedings**
<br /><br />
**Governing Law:** This Agreement, commonly referred to as the "Conditions," will be directed and deciphered under the laws of England.
<br /><br />
**Jurisdiction:** By agreeing to this User Agreement, you unequivocally and irrevocably submit any and all disputes to the exclusive authority of the courts of England.
<br /><br />
**9. General Provisions**<br />
**9.1 Severability:** In the event that any provision or part of this User Agreement is determined to be invalid or unenforceable by a competent court, both you and SayThatBack.com consent that the court should endeavor to adjust the terms to make that specific provision or part enforceable while preserving its original intent. If the court is unable to make such modifications, both parties agree that the unenforceable provision or part should be omitted, and the remaining parts of the User Agreement should continue to be in effect.
<br /><br />
**9.2 Entire Agreement:** This User Agreement, inclusive of any additional terms provided when you interact with specific features of the Services, encapsulates the complete and exclusive agreement between us concerning the Services. It supersedes all prior communications, contracts, or understandings related to the Services.
<br /><br />
**9.3 Non-waiver:** Failure by SayThatBack.com to enforce any breach or provision of this User Agreement doesn't signify a waiver of its right to enforce the said agreement in the future. Any non-enforcement should not be perceived as a waiver.
<br /><br />
**9.4 No Assignability or Third-party Beneficiaries:** You are prohibited from assigning or transferring this User Agreement or your rights to use the Services to any other individual or entity. Furthermore, this User Agreement does not confer any rights or remedies to any third parties, implying there are no third-party beneficiaries to this agreement.
<br /><br />
**9.5 Legal Notices:** If you wish to send any legal notices to SayThatBack.com, they must be sent to the address mentioned in Section 12. This is the only acceptable method for providing legal notifications to SayThatBack.com.
<br /><br />
**10. Do’s and Don’ts**<br />
**10.1 Do’s**<br />
You are to:<br />
- Abide by all relevant laws, encompassing but not restricted to privacy regulations, intellectual property regulations, anti-spam regulations, export control regulations, and tax laws whenever utilizing the Services or the Site.
<br /><br />
**10.2 Don’ts**<br />
While using our Services or Site, you shall NOT:<br />
- **10.2.1** Assume a false identity, misrepresent yourself, impersonate another, or unlawfully access another's account on SayThatBack.com.<br />
- **10.2.2** Employ unauthorized means like robots, scripts, or scrapers to extract data or profiles from the Service or Site.<br />
- **10.2.3** Override, breach, or evade any security features or restrictions put in place by the Site or Service.<br />
- **10.2.4** Unlawfully disclose, use, or distribute information sourced from our Site or Services.<br />
- **10.2.5** Divulge any unauthorized or confidential information.<br />
- **10.2.6** Infringe on intellectual property rights such as trademarks, patents, copyrights, or trade secrets.<br />
- **10.2.7** Illegally use SayThatBack.com's name, technology, or logo.<br />
- **10.2.8** Upload or distribute malicious software through our Site or Services.<br />
- **10.2.9** Attempt to uncover the underlying code of our Services or Site.<br />
- **10.2.10** Use automated bots to access our Site or Services.<br />
- **10.2.11** Harass, stalk, impersonate, or threaten anyone using our platform.<br />
- **10.2.12** Post or share inappropriate, obscene, defamatory, or illegal content.<br />
- **10.2.13** Send spam, junk mail, or partake in deceptive schemes through our platform.<br />
- **10.2.14** Without permission, harvest or disclose other users' information.<br />
- **10.2.15** Monitor our Site or Services for competitive advantages.<br />
- **10.2.16** Illegally mirror, frame, or mimic the appearance or functionality of our platform.<br />
- **10.2.17** Modify the appearance of our platform, such as obscuring advertisements.<br />
- **10.2.18** Disturb, hamper, or place excessive burdens on our Services or Site, for example through spam or viruses.<br />
Any violations may result in actions against your account, including suspension or permanent bans.
<br /><br />
**11. Claims Regarding Copyright Infringement**<br />
In accordance with the Digital Millennium Copyright Act (17 U.S.C. § 512), SayThatBack.com has established processes for obtaining written notifications of possible copyright violations. Moreover, an agent has been designated by SayThatBack.com to acknowledge such copyright infringement claims.
<br /><br />
Should you believe that your copyright has been unjustly violated, kindly provide a written statement detailing:
<br /><br />
- The electronic or physical signature of the individual authorized to act on the copyright holder's behalf.
<br /><br />
- A clear description of the copyrighted material believed to have been infringed upon.
<br /><br />
- Detailed information indicating where on our platform the supposed infringement took place.
<br /><br />
- Your email address, postal address, and/or contact number.
<br /><br />
- A declaration from you, made in good faith, indicating that the disputed usage hasn't been sanctioned by the copyright holder, its representative, or by law.
<br /><br />
- A statement made under oath by you, asserting the accuracy of the information in your notice and that you are either the copyright holder or authorized to act on their behalf.
<br /><br />
Do remember that any notices, either initial or counter-notices, must be truthful and are provided under the penalty of perjury. Submitting a deceptive notice can result in personal legal consequences. Therefore, it may be wise to seek legal advice before submitting a notice.
<br /><br />
Please direct your notices to SayThatBack.com Inc.’s official representative at the following email: team@saythatback.com
<br /><br />
**12. Contact Us**<br />
For generic inquiries, you can reach us at: team@saythatback.com. 
<br /><br />
For any legal communications or service of process, please write to: team@saythatback.com.
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default TACSPage;