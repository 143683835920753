// 1) import the react and reactdom lib
import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import './index.css';

//2) get a ref to the div with ID root
const el = document.getElementById('root');

//3) tell react to take control of that element
const root = ReactDOM.createRoot(el);

//4) create a component

//5) show the component on the screen
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);