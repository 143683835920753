import { useDispatch, useSelector } from "react-redux";
import dateFormat, { masks } from "dateformat";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import OpenMenuTopBar from "../components/OpenMenuTopBar";
import { FiCopy, FiTrash, FiUser, FiLoader, FiEdit, FiChevronRight, FiRefreshCcw, FiRefreshCw } from 'react-icons/fi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { apiAction_endDeleteProjectLoadDraftProjects, apiAction_endGetCopyProject, apiAction_startDeleteProjectLoadDraftProjects, apiAction_startGetCopyProject, apiAction_startLoadDraftProjects, dpAction_setDraftCopyProjectId, dpAction_setDraftDeleteProjectId, pagesAction_openPage, pagesAction_switchPage } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeep2.svg";
import { apiAction_endGetDraftProject, apiAction_endGetDraftProjects, apiAction_endSubmitUrl, apiAction_startGetDraftProject, apiAction_startSubmitUrl, sapfAction_UpdateURLField } from "../store/actions";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import { FaSpinner } from "react-icons/fa";
import { get_draft_projects } from "../api/get_draft_projects";
import { get_draft_project } from "../api/get_draft_project";
import { getBackgroundCSS } from "../components/css";
import CopyDraftProjectModal from "./modal/CopyDraftProjectModal";
import { get_copy_project } from "../api/get_copy_project";
import { delete_project_get_draft_projects } from "../api/delete_project_get_draft_projects";
import DeleteDraftProjectModal from "./modal/DeleteDraftProjectModal";

const getDraftProjects = async (dispatch, sessionState) =>{
    let action = apiAction_startLoadDraftProjects(); dispatch(action);

        const res = await get_draft_projects(sessionState.token);

        const payload = { res: res, };
        if (res.success && res.success === true && res.status === 'got_draft_projects'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['draftProjectsPage'] = {}
            payload['addSlidesForm'] = {}
            payload['session'] = sessionObject;
            
            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        
        action = apiAction_endGetDraftProjects(payload); dispatch(action);
}

function DraftProjectsPage({count}){
    const dispatch = useDispatch();
    
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});
    const draftProjectsState = useSelector((state) => {return state.draftProjectsPage;});

    let backgroundClass = getBackgroundCSS(count, 'bg-green-1');

    const openMenuPage = () => {
        const action = pagesAction_openPage('MenuPage'); dispatch(action);
    }

    const getTopErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className="w-full bg-white rounded-[20px] p-6 text-center mt-2.5">
                    <div className='flex border-black items-center w-full font-montserrat font-medium text-[1rem] text-red-1'>
                        {stateErrorVariable}
                    </div>
                </div>
            )
        }
        
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className='flex border-black items-center w-full pl-6 mt-6 font-montserrat font-medium text-[1rem] text-red-1'>
                    {stateErrorVariable}
                </div>
            )
        }
        
    }

    const handleCardClick = async (projectId, e) =>{
        //open AddSlidesPage
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let payload = {projectId: projectId}
        let action = apiAction_startGetDraftProject(payload); dispatch(action);
        const res = await get_draft_project(projectId, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'got_draft_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['addSlidesForm'] = { res: res }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'AddSlidesPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endGetDraftProject(payload); dispatch(action);
    }

    const handleCopyClick = async (projectId, e) => {
        e.stopPropagation();
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let action = dpAction_setDraftCopyProjectId(projectId); dispatch(action);
    }

    const handleDeleteClick = (projectId, e) => {
        e.stopPropagation();
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }

        let action = dpAction_setDraftDeleteProjectId(projectId); dispatch(action);
    }

    const isLoading = () => {
        if (
            apiState.getDraftAudioStatus === 'waiting' || 
            apiState.loadDraftProjectsStatus === 'waiting' ||
            apiState.getCopyProjectStatus === 'waiting'
        ){
            return true;
        }else{
            return false;
        }
    }

    const isDisableAllButtons = () => {
        return isLoading();
    }

    const projectDivClass = () => {

        const isLoadingVar = isLoading();

        const getShadow = () => {
            if (isLoadingVar){
                return 'shadow-none active:shadow-none hover:shadow-none'
            }else{
                return 'shadow-xl active:shadow-none hover:shadow-lg';
            }
        }

        return classNames(`flex flex-col rounded-[20px] items-center mt-3 w-full bg-white ${getShadow()}`, {
            'cursor-pointer': !isLoadingVar,
            'cursor-not-allowed': isLoadingVar,
        });
    }

    const getButtons = (project) => {
        //if loading === true
        if (isLoading()){
            if (apiState.getCopyProject_projectId === project.id || apiState.getCopyProject_projectId === project.id){
                return (
                    <div className="flex flex-rows gap-2 h-full items-center pr-5">
                        <div>
                            <FaSpinner className="justify-self-start h-6 w-6 animate-spin"/>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="flex flex-rows gap-2 h-full items-center pr-5">
                        <div>
                            <Button roundDisabled className="">
                                <FiCopy className="w-6 h-6" color="black" />
                            </Button>
                        </div>
                        <div>
                            <Button roundDisabled>
                                <FiTrash className="w-6 h-6" color="black" />
                            </Button></div>
                        </div>
                )
            }
        }else{
            return (
                <div className="flex flex-rows gap-2 h-full items-center pr-5">
                    <div>
                        <Button round className="" onClick={async (e) => {
                            await handleCopyClick(project.id, e);
                        }}>
                            <FiCopy className="w-6 h-6" color="black"/>
                        </Button>
                    </div>
                    <div>
                        <Button round onClick={async (e) => {
                            await handleDeleteClick(project.id, e);
                        }}>
                            <FiTrash className="w-6 h-6" color="black"/>
                        </Button>
                    </div>
                </div>
            )
        }
    }

    const handleToAddSlidesPagePageClick = async () => {
        const action = pagesAction_openPage('AddSlidesPage'); dispatch(action);
    }

    masks.ProjectDetails = 'ddd d mmm"," yyyy';

    const getAllProjectJSX = () => {
        if (draftProjectsState.projects.length === 0){
            return (
                <div className="w-full bg-white rounded-[20px] p-6 text-center mt-2.5">
                    <div className="font-montserrat font-medium text-[1.063rem] mb-3">
                        Draft projects appear here. Click below to start a new project. 
                    </div>
                    <div className="w-full bg-white text-center flex flex-row gap-5 pt-3">
                        <Button primary className="basis-1/2"
                            onClick={handleToAddSlidesPagePageClick}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Start a project
                            </div>
                            <FiChevronRight className="justify-self-end h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            )
        }else{
            let projectsJSX = []
            for (const project of draftProjectsState.projects){
                const getFirstImageVideoJSX = () => {
                    if (project.firstImageFileId !== null){
                        return (
                            <div className={classNames('flex, rounded-[20px], items-center, w-full')} >
                                <img alt="" 
                                className={classNames('w-full, bg-white, rounded-[20px]')} 
                                src={`https://saythatback-imagefile.s3.amazonaws.com/${project.firstImageFileS3Key}_1280x720.jpg`} />
                            </div>
                        )
                    }else if (project.firstVideoFileId !== null){
                        return (
                            <div className={classNames('flex, rounded-[20px], items-center, w-full')} >
                                <img alt="" 
                                className={classNames('w-full, bg-white, rounded-[20px]')} 
                                src={`https://saythatback-videofile.s3.amazonaws.com/${project.firstVideoFileS3Key}_1280x720.jpg`} />
                            </div>
                        )
                    }else{
                        return <></>
                    }
                }

                projectsJSX.push(
                    <div key={project.id} 
                    className={projectDivClass()} onClick={async (e) => {
                        await handleCardClick(project.id, e);
                    }}>
                        {getFirstImageVideoJSX()}
                        {getErrorJSX(project.error.topErrorErrorMessage)}
                        <div className="w-full h-fit font-montserrat font-extrabold text-2xl flex justify-start items-center px-6 py-3">
                            {project.title}
                        </div>
                        <div className="w-full h-fit flex justify-start items-center ">
                            <div className="flex flex-col w-full">
                                <div className="flex flex-rows gap-2 font-montserrat font-medium text-base items-center mt-1 px-6">
                                    <div><AiOutlineCalendar className="w-4 h-4" color="black" /></div>
                                    <div>Created on {dateFormat(project.creationDate, masks.ProjectDetails)}</div>
                                </div>
                                <div className="flex flex-rows gap-2 font-montserrat font-medium text-base items-center mt-1 px-6 pb-3">
                                    <div><FiEdit className="w-4 h-4" color="black" /></div>
                                    <div>Draft</div>
                                </div>
                            </div>
                            {getButtons(project)}
                        </div>
                    </div>

                )
            }
            return projectsJSX;
        }
    }

    const getRefreshIcon = () => {
        if (apiState.loadDraftProjectsStatus === 'waiting'){
            return <FiRefreshCw color='black' className="animate-spin justify-self-end h-6 w-6"/>
        }else{
            return <FiRefreshCw className="justify-self-end h-6 w-6"/>
        }
    }

    const handleModalCopyClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');dispatch(action);
            return;
        }
        
        const projectId = draftProjectsState.copyProjectId;
        const payload = {projectId: projectId}
        let action = apiAction_startGetCopyProject(payload); dispatch(action);
        const res = await get_copy_project(projectId, sessionState.token, [1]);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'got_copy_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'AddSlidesPage'
            };
            payload['addSlidesForm'] = { res: res, }
            payload['session'] = sessionObject;

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endGetCopyProject(payload); dispatch(action);
    }

    const handleModalDeleteClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');dispatch(action);
            return;
        }
        
        let action = apiAction_startDeleteProjectLoadDraftProjects(); dispatch(action);
        const res = await delete_project_get_draft_projects(sessionState.token, draftProjectsState.deleteProjectId);

        const payload = {projectId: draftProjectsState.deleteProjectId};
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'deleted_project_loaded_draft_projects'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['draftProjectsPage'] = { res: res, }
            payload['addSlideForm'] = {}

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        
        action = apiAction_endDeleteProjectLoadDraftProjects(payload); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <OpenMenuTopBar onClick={openMenuPage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep />
                <div className=" flex flex-row w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center">
                        <div className="w-full">Your draft projects</div>
                        <div>
                            <Button round={apiState.loadDraftProjectsStatus !== 'waiting'} 
                                roundDisabled={apiState.loadDraftProjectsStatus === 'waiting'}
                                onClick={async () => await getDraftProjects(dispatch, sessionState)}>
                                {getRefreshIcon()}
                            </Button>
                        </div>
                </div>
                {getTopErrorJSX(draftProjectsState.error.topErrorErrorMessage)}
                {getAllProjectJSX()}
            </div>
            <CopyDraftProjectModal 
                isOpen={draftProjectsState.copyProjectId !== null}
                handleModalCopyClick={handleModalCopyClick}
            />
            <DeleteDraftProjectModal 
                isOpen={draftProjectsState.deleteProjectId !== null}
                handleModalDeleteClick={handleModalDeleteClick}
            />
        </div>
    )
}

export { getDraftProjects }
export default DraftProjectsPage;
