
import axios from "axios";
import axiosRetry from 'axios-retry';
import { BASE_API_URL, handleAxiosError } from "./apiUtils";

const delete_project_get_completed_projects = async (token, projectId) => {
    const json = JSON.stringify({ 
        token: token,
        projectId: projectId
    });
    
    let returnObject = undefined;
    try{
        axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
        const res = await axios.post(`${BASE_API_URL()}v2/delete_project_get_completed_projects`, json, {
            headers: {'Content-Type': 'application/json'}
        });
        returnObject = res.data;
    }catch (error) {
        returnObject = handleAxiosError(error);
    }
    return returnObject;
}

export { delete_project_get_completed_projects };