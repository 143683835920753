import axios from "axios";
import axiosRetry from 'axios-retry';
import { BASE_API_URL, handleAxiosError } from "./apiUtils";

//NOT COMPLETE - NEED TO FINISH FUNCTION

const get_completed_project = async (projectId, token, statusArray) => {
    const json = JSON.stringify({
        projectId: projectId,
        token: token,
    });
    
    let returnObject = undefined;
    try{
        axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
        const res = await axios.post(`${BASE_API_URL()}v2/get_completed_project`, json, {
            headers: {'Content-Type': 'application/json'}
        });
        returnObject = res.data;
    }catch (error) {
        returnObject = handleAxiosError(error);
    }
    return returnObject;
}

export { get_completed_project };