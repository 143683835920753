import { createSlice } from "@reduxjs/toolkit";
import { apiAction_endSubmitEmail, 
    apiAction_endSubmitEmailPassword,
    pagesAction_closePage, pagesAction_closeThreePages,
    apiAction_endResetPassword2, pagesAction_closeFourPages, apiAction_startSubmitEmail, apiAction_endResetPassword1 } from "../actions";


const getInitialState = () => {
    return {
        email: '',
        emailErrorMessage: null,
        password: '',
        passwordErrorMessage: null,
        resetCode: '',
        resetCodeErrorMessage: null, 
        resetPassword: '',
        resetPasswordErrorMessage: null,
        termsCheckbox: false,
        termsCheckboxError: null
    }
}

const signInPageFormSlice = createSlice({
    name: 'signInPageForm',
    initialState: getInitialState(),
    reducers: {
        sipfAction_updateEmailField(state, action){
            state['email'] = action.payload;
        },
        sipfAction_updatePasswordField(state, action){
            state['password'] = action.payload;
        },
        sipfAction_updateResetCodeField(state, action){
            state['resetCode'] = action.payload;
        },
        sipfAction_updateResetPasswordField(state, action){
            state['resetPassword'] = action.payload;
        },
        sipfAction_setTermsCheckbox(state, action){
            state['termsCheckbox'] = action.payload;
        },
        sipfAction_setTermsCheckboxError(state, action){
            state['termsCheckboxError'] = action.payload;
        },
        sipfAction_resetAllFields(state, action){
            _sipfAction_resetAllFields(state, action);
        },
    },
    extraReducers(builder){
        builder.addCase(apiAction_startSubmitEmail, (state, action) => {
            reserErrorFields(state, action);
        });
        builder.addCase(apiAction_endSubmitEmail, (state, action) => {
            reserErrorFields(state, action);
            
            const res = action.payload.res;
            if (res.error){
                state['emailErrorMessage'] = res.error_description;
            }else if(res.success && res.success === true){
                state['emailErrorMessage'] = null;
            }
        });
        builder.addCase(apiAction_endSubmitEmailPassword, (state, action) => {
            reserErrorFields(state, action);
            const res = action.payload.res;
            if (res.error){
                state['passwordErrorMessage'] = res.error_description;
            }else if(res.success && res.success === true){
                
            }
        });
        builder.addCase(apiAction_endResetPassword1, (state, action) => {
            reserErrorFields(state, action);
            const res = action.payload.res;
            if (res.error){
                state['emailErrorMessage'] = res.error_description;
            }else if(
                res.success && 
                res.success === true &&
                res.addSlidesForm
            ){
                //open reset page
            }
        });
        builder.addCase(apiAction_endResetPassword2, (state, action) => {
            reserErrorFields(state, action);

            const res = action.payload.res;
            if (res.error){
                state['resetCodeErrorMessage'] = res.error_description;
            }else if(
                res.success && 
                res.success === true &&
                res.addSlidesForm
            ){
                //open confirmation page
            }
        });
        builder.addCase(pagesAction_closePage, (state, action) => {
            if (action.payload){
                if(action.payload === 'LoginRegisterEmailPage1'){
                    _sipfAction_resetAllFields(state, action);
                }else if(action.payload === 'LoginRegisterEmailPage2'){
                    _sipfAction_resetAllFields(state, action);
                }else if(action.payload === 'LoginRegisterPasswordPage1' 
                || action.payload === 'LoginRegisterPasswordPage2'){
                    state['password'] = '';
                    state['passwordErrorMessage'] = null;
                }
            }
        });
        builder.addCase(pagesAction_closeThreePages, (state, action) => {
            if (action.payload.sipfAction){
                _sipfAction_resetAllFields(state, action);
            }
        });
        builder.addCase(pagesAction_closeFourPages, (state, action) => {
            if (action.payload.sipfAction){
                _sipfAction_resetAllFields(state, action);
            }
        });
    }
});

const _sipfAction_resetAllFields = (state, action) =>{
    const initialState = getInitialState();
    for (const key in initialState){
        state[key] = initialState[key];
    }
}

const reserErrorFields = (state, action) => {
    state.emailErrorMessage = null;
    state.passwordErrorMessage = null;
    state.resetCodeErrorMessage = null;
    state.resetPasswordErrorMessage = null;
    state.termsCheckboxError = null
}

export const { 
    sipfAction_updateEmailField,
    sipfAction_updatePasswordField,
    sipfAction_updateResetPasswordField,
    sipfAction_updateResetCodeField,
    sipfAction_setTermsCheckbox, sipfAction_setTermsCheckboxError
} = signInPageFormSlice.actions;
export const signInPageFormReducer = signInPageFormSlice.reducer;
