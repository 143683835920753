import { createSlice } from "@reduxjs/toolkit";
import { getJSDateFromJSONDate } from "../../utils/utils";
import { apiAction_endCreatePaymentIntent, apiAction_endSaveToDraftLoadBasket, apiAction_startCreatePaymentIntent, pagesAction_closeFourPages_openPage, pagesAction_closePage, pagesAction_closeThreePages } from "../actions";
import { apiAction_endStripeANDcheckUpdatePaymentIntentStatus } from "./APISlice";

const basketSlice = createSlice({
    name: 'basketPage',
    initialState: {
        project: null,
        /*
        audioId: null,
        title: '',
        creationDate: null,
        firstImageFileId: null,
        firstImageFileS3Key: null,
        wordCount: 0,
        charCount: 0,
        
        */
        clientSecret: null,
        checkoutFormEmail: '',
        paymentIntentStatus: '',
        checkoutFormMessage: null,

        //error
        topErrorErrorMessage: null,
    },  
    reducers: {
        bAction_setClientSecret(state, action){
            state['clientSecret'] = action.payload;
        },
        bAction_setEmail(state, action){
            state['checkoutFormEmail'] = action.payload;
        },
        bAction_setPaymentIntentStatus(state, action){
            state['paymentIntentStatus'] = action.payload;
        },
    },
    extraReducers(builder){
        builder.addCase(apiAction_endCreatePaymentIntent, (state, action) => {
            resetErrorStates(state, action);
            state["paymentIntentStatus"] = '';
            state['checkoutFormMessage'] = '';
            const res = action.payload.res;
            if(action.payload.basketPage && res.success && res.success === true){
                state['clientSecret'] = res.data.clientSecret;
                state['paymentIntentStatus'] = res.data.status;
                state['paymentIntentAmount'] = res.data.amount;
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                state.topErrorErrorMessage = res.error_description;
            }
        });
        builder.addCase(apiAction_endStripeANDcheckUpdatePaymentIntentStatus, (state, action) => {
            const resFromStripe = action.payload.resFromStripe;
            if (resFromStripe.error === null || resFromStripe.error === undefined){
                const paymentIntentFromStripe = resFromStripe.paymentIntent;
                const res = action.payload.res;
                state['clientSecret'] = paymentIntentFromStripe.client_secret;
                state['paymentIntentStatus'] = paymentIntentFromStripe.status;
                state['paymentIntentAmount'] = paymentIntentFromStripe.amount;
                if (resFromStripe.paymentIntent.status === 'succeeded'){
                    state['checkoutFormMessage'] = 'Payment succeeded!';
                }
                if(res.success && res.success === true){
                    //if react.paymentIntentFromStripe is successful then doesb;lt matter if
                    //server error - leave alone.
                }else if(res.error && res.error === 'TOKEN_INVALID'){
                    //do nothing here
                }else if(res.error && res.error === 'PAYMENT_INTENT_ID_INVALID'){
                    //do nothing here
                }else if(res.error){
                    
                }
            }else{
                const error = resFromStripe.error;
                if (error.type === "card_error" || error.type === "validation_error") {
                    state['checkoutFormMessage'] = error.message;
                } else {
                    state['checkoutFormMessage'] = 'An unexpected error occurred.';
                }
            }
        });
        builder.addCase(apiAction_endSaveToDraftLoadBasket, (state, action) => {
            const res = action.payload.res;
            if(action.payload.basketPage && action.payload.res.success && action.payload.res.success === true){
                //resetAddSlidesPage is called in jsonProjectToStateProject
                jsonProjectToStateProject(state, action, action.payload.addSlidesForm.res.data.project);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                //do nothing
            }
        });
        
    }
});

const jsonProjectToStateProject = (state, action, jsonProject) => {
    state.project = jsonProject;
}

const resetErrorStates = (state, action) => {
    state.topErrorErrorMessage = null;
}

export const { 
    bAction_setClientSecret,
    bAction_setMessage,
    bAction_setEmail,
    bAction_setPaymentIntentStatus,
} = basketSlice.actions;
export const basketReducer = basketSlice.reducer;
