import { useDispatch, useSelector } from "react-redux";
import { saveAs } from 'file-saver'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import classNames from "classnames";
import Button from "../components/buttons/Button";
import dateFormat, { masks } from "dateformat";
import { FiClock, FiDownload, FiEdit, FiHash, FiSliders, FiUser } from 'react-icons/fi';
import { pagesAction_closePage, pagesAction_openPage, apiAction_endDownloadCompletedProjectStatus, apiAction_startDownloadCompletedProjectStatus, apiAction_startGetFineTuneProject, apiAction_endGetFineTuneProject } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeeps5.svg";
import { ReactComponent as PlayButton } from "../images/svg/play_button.svg";
import { ReactComponent as PauseButton } from "../images/svg/pause_button.svg";
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";
import ReactPlayer from "react-player";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { msToHMS } from "../utils/utils";
import JSZip, { file } from "jszip";
import { getFirstImageURL } from "../utils/Project";
import { get_fine_tune_project } from "../api/get_fine_tune_project";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";


function CompletedProjectPage({count}){
    const dispatch = useDispatch();
    
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});
    const completedProjectsState = useSelector((state) => {return state.completedProjectsPage;});
    const pagesState = useSelector((state) => {return state.pages;});

    let backgroundClass = getBackgroundCSS(count, 'bg-pink-1');
    let paragraphClass = classNames('flex flex-col rounded-[20px] items-center w-full bg-white');

    const isLoading = () => {
        if (
            apiState.downloadCompletedProjectStatus === 'waiting' ||
            apiState.getFineTuneProjectStatus === 'waiting' 
        ){
            return true;
        }else{
            return false;
        }
    }

    const isDisableAllButtons = () => {
        return isLoading();
    }
    const downloadFiles = async (files) => {
        let action = apiAction_startDownloadCompletedProjectStatus(); dispatch(action);
        await saveZip('files', files);
        action = apiAction_endDownloadCompletedProjectStatus(); dispatch(action);
        /* for (var i = 0; i < files.length; i++) {
          const link = document.createElement("a");
          link.download = files[i];
          link.href = files[i];
          link.click();
          await delay(500);
        } */
    }

    const saveZip = async (filename, urls) => {
        if(!urls) return;
    
        const zip = new JSZip();
        const folder = zip.folder("files"); // folder name where all files will be placed in 
    
        urls.forEach(async (url) => {
            const blobPromise = fetch(url, 
                {mode: 'cors'}).then((r) => {
                if (r.status === 200) return r.blob();
                return Promise.reject(new Error(r.statusText));
            });
            const name = url.substring(url.lastIndexOf("/") + 1);
            folder.file(name, blobPromise);
        });
    
        await zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));
    };

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const getDownloadButtonIconSpinner = () => {
        if (apiState.downloadCompletedProjectStatus === 'waiting'){
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }else{
            return <FiDownload className="justify-self-end h-6 w-6"/>
        }
    }
    
    const getFineTuneButtonIconSpinner = () => {
        if (apiState.getFineTuneProjectStatus === 'waiting'){
            return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }else{
            return <FiSliders className="justify-self-end h-6 w-6"/>
        }
    }

    const handleFineTuneClick = async () => {
        //open AddSlidesPage
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        const projectId = completedProjectsState.completedProject.id;
        const payload = {projectId: projectId}
        let action = apiAction_startGetFineTuneProject(payload); dispatch(action);
        const res = await get_fine_tune_project(projectId, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'got_fine_tune_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['addSlidesForm'] = res;
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'FineTuneCompletedProjectPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endGetFineTuneProject(payload); dispatch(action);
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className='flex border-black items-center w-full mt-6 font-montserrat font-medium text-[1rem] text-red-1'>
                    {stateErrorVariable}
                </div>
            )
        }
        
    }

    masks.AudioDetails = 'ddd d mmm"," yyyy';
    masks.hmmss = 'h:mm:ss';

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] mb-6 p-6 text-center">
                    4K Video Ready To Download
                </div>
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center space-y-3">
                    <div className="text-2xl sm:3xl">
                        Watch & Download!
                    </div>
                    {getErrorJSX(completedProjectsState.completedProject.error.topErrorErrorMessage)}
                    <div className="text-left text-xl font-medium text-base text-black">
                        Watch your 4K video.
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="rounded-[20px] w-full h-full">
                            <div className='player-wrapper bg-yellow-1 w-full rounded-[20px]'>
                                <ReactPlayer 
                                    className='react-player'
                                    url={`https://saythatback-projectfile.s3.amazonaws.com/${completedProjectsState.completedProject.s3key}.mp4`}
                                    width="100%"
                                    height="100%"
                                    loop={false}
                                    playsinline
                                    controls={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={paragraphClass}>
                    <div className="w-full h-fit font-montserrat font-extrabold text-2xl text-left mt-1 px-6">
                        {completedProjectsState.completedProject.title}
                    </div>
                    <div className="w-full flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6">
                        <div><FiHash className="w-4 h-4" color="black" /></div>
                        <div>{completedProjectsState.completedProject.wordCount} words and {completedProjectsState.completedProject.slides.length} slides</div>
                    </div>
                    <div className="w-full flex flex-rows gap-3 font-montserrat font-medium text-base items-center mt-1 px-6 pb-3">
                        <div><FiClock className="w-4 h-4" color="black" /></div>
                        <div>Duration: {msToHMS(completedProjectsState.completedProject.duration)} long</div>
                    </div>
                </div>
                    <div className="flex flex-row items-center gap-1">
                        <Button 
                            plain={!isDisableAllButtons()}
                            plainDisabled={isDisableAllButtons()}
                            onClick={async () => {
                                const a = document.createElement('a');
                                const fileName =  `https://saythatback-projectfile.s3.amazonaws.com/${completedProjectsState.completedProject.s3key}.aac`;
                                a.href = fileName;
                                a.download = fileName; // Set the file name
                                a.style.display = 'none'; // Hide the element
                        
                                document.body.appendChild(a); // Add to the DOM
                                a.click(); // Trigger the download
                        
                                document.body.removeChild(a); // Remove from the DOM after click
                                /*    
                                await downloadFiles([
                                    `https://saythatback-projectfile.s3.amazonaws.com/${completedProjectsState.completedProject.s3key}.aac`
                                ])
                                */
                        }}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Download Audio
                            </div>
                            {getDownloadButtonIconSpinner()}
                        </Button>
                        <Button 
                            primary={!isDisableAllButtons()}
                            primaryDisabled={isDisableAllButtons()}
                            onClick={async () => {
                                const a = document.createElement('a');
                                const fileName =  `https://saythatback-projectfile.s3.amazonaws.com/${completedProjectsState.completedProject.s3key}.mp4`;
                                a.href = fileName;
                                a.download = fileName; // Set the file name
                                a.style.display = 'none'; // Hide the element
                        
                                document.body.appendChild(a); // Add to the DOM
                                a.click(); // Trigger the download
                        
                                document.body.removeChild(a); // Remove from the DOM after click
                                /*
                                await downloadFiles([
                                    `https://saythatback-projectfile.s3.amazonaws.com/${completedProjectsState.completedProject.s3key}.mp4`
                                ])
                                */
                        }}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Download Video
                            </div>
                            {getDownloadButtonIconSpinner()}
                        </Button>
                    </div>
                </div>
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center mt-6 space-y-3">
                    <div className="text-2xl sm:3xl">
                        Something's not right?
                    </div>
                    <div className="text-left text-xl font-medium text-base text-black">
                        Click here to fine tune WordSpeaker text as well as other Project features: 
                    </div>
                    {getErrorJSX(completedProjectsState.completedProject.error.topErrorErrorMessage)}
                    <div className="flex flex-col items-center">
                        <Button 
                            primary={!isDisableAllButtons()}
                            primaryDisabled={isDisableAllButtons()}
                            onClick={handleFineTuneClick}
                        >
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Fine tune your project
                            </div>
                            {getFineTuneButtonIconSpinner()}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedProjectPage;
