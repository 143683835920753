import { FiImage, FiMusic, FiPlus, FiVideo, FiX } from "react-icons/fi";
import Modal from 'react-modal';
import Button from "../../components/buttons/Button";
import classNames from "classnames";
import classNamesDedupe from "classnames/dedupe";
import { ReactComponent as OpenPeep } from "../../images/svg/openpeep2.svg";
import { pagesAction_openPage } from "../../store/actions";
import { useDispatch } from "react-redux";


const ChangeVoiceModal = ({
    isOpen, 
    isDisableAllButtons, 
    handleCloseModal,
    handleChangeVoiceClick,
    voices
}) => {
    const dispatch = useDispatch();

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '20px',
            padding: '20px',
            height: 'fit-content',
            width: '90%',
            maxWidth: '30rem',
            backgroundColor: '#FFFFFF',

            maxHeight: '90%',
            overflow              : 'auto',
            WebkitOverflowScrolling: 'touch'
          },
    };

    const voiceIconsClass = classNames('h-10 w-10 bg-white rounded-full border-black border');

    const getVoicesJSX = () => {
        const voicesJSX = [];
        voices.forEach((voice, index) => {
            voicesJSX.push(
                <div className="mb-3" key={index}>
                    <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                        className="mb-5" onClick={() => {
                            handleChangeVoiceClick(voice);
                        }}>
                        <img className={voiceIconsClass}
                        src={`https://saythatback-voicefile.s3.amazonaws.com/${voice.voiceImageFile.s3key}_128x128.jpg`}
                        />
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            {voice.label}
                        </div>
                    </Button>
                </div>
            );
        });

        return voicesJSX;
    }

    const handleOpenAddCustomVoicePage = () => {
        const action = pagesAction_openPage('AddCustomVoicePage'); dispatch(action);
    }

    return (
    <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={modalStyle}
        preventScroll={true}
    >
        <div className="absolute">
            <Button round={!isDisableAllButtons()} roundDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleCloseModal}>
                <FiX className="justify-self-start w-6 h-6"/>
            </Button>
        </div>
        <div className="flex w-full place-content-center"><OpenPeep className="w-40 h-40 mt-4" /></div>
        <div className="w-full bg-white font-montserrat font-extrabold 
        text-2xl rounded-[20px] text-center p-1">
            Select the voice you'd like to use.
        </div>
        {getVoicesJSX()}
        <div className={classNames('bg-white text-center w-full mt-6 gap-5')}>
            <Button primary={!isDisableAllButtons()} primaryDisabled={isDisableAllButtons()} 
                className="" onClick={handleOpenAddCustomVoicePage}>
                <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                    Add a new custom voice
                </div>
                <FiPlus className="justify-self-start h-6 w-6"/>
            </Button>
        </div>  
    </Modal>
    )
}

export default ChangeVoiceModal;