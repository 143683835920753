import { FiImage, FiMusic, FiVideo, FiX } from "react-icons/fi";
import Modal from 'react-modal';
import Button from "../../components/buttons/Button";
import { ReactComponent as OpenPeep } from "../../images/svg/openpeep2.svg";


const ConfirmCustomVoiceSavedModal = ({
    isOpen, 
    handleClosePage
}) => {

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '30px',
            padding: '20px',
            height: 'fit-content',
            width: '90%',
            maxWidth: '30rem',
            backgroundColor: '#FFFFFF',

            maxHeight: '90%',
            overflow              : 'auto',
            WebkitOverflowScrolling: 'touch'
          },
    };

    return (
    <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={modalStyle}
        preventScroll={true}
    >
        <div className="flex w-full place-content-center"><OpenPeep className="w-40 h-40 mt-4" /></div>
        <div className="w-full bg-white font-montserrat font-extrabold 
        text-2xl rounded-[20px] text-center pb-6 pt-3">
            Your custom voice is ready to use.
        </div>
        <div className="mb-3">
            <Button primary={true} className="mb-5" onClick={handleClosePage}>
                <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                    Ok, let's go...
                </div>
                <FiX className="justify-self-start w-6 h-6"/>
            </Button>
        </div>
    </Modal>
    )
}

export default ConfirmCustomVoiceSavedModal;