

const BASE_API_URL = () => {
/*
In React applications, process.env.NODE_ENV is usually one of the following:

1) development: when running the development server, such as when using npm start or yarn start.
2) test: when running tests, for example with Jest.
3) production: when the code has been built for production, such as with npm run build or yarn build.
*/
    if (process.env.NODE_ENV === 'production'){
        return 'https://api.saythatback.com/';
    }else if(process.env.NODE_ENV === 'development'){
        return 'http://192.168.1.218:8000/'
    }
}

const handleAxiosError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
      return {
        error: 'NO_INTERNET',
        error_description: 'No internet: try checking your connection and trying again.'
      }
}

export { BASE_API_URL, handleAxiosError };