import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PricingPage from './pages/PricingPage';
import MenuPage from './pages/MenuPage';
import LoginRegisterEmailPage1 from './pages/LoginRegisterEmailPage1';
import LoginRegisterEmailPage2 from './pages/LoginRegisterEmailPage2';
import LoginRegisterPasswordPage1 from './pages/LoginRegisterPasswordPage1';
import LoginRegisterPasswordPage2 from './pages/LoginRegisterPasswordPage2';
import LoginRegisterSuccessfulPage from './pages/LoginRegisterSuccessfulPage';
import LoginRegisterResetPage from './pages/LoginRegisterResetPage';
import SignOutPage from './pages/SignOutPage';
import DraftProjectsPage from './pages/DraftProjectsPage';
import { session_setCookie, session_isCookieSet, session_getCookie, session_removeCookie } from "./cookie/SessionCookie";
import { apiAction_startIsSessionValid, apiAction_endIsSessionValid, pagesAction_resetToInitialState, apiAction_resetAllOnReload } from './store';
import { is_session_valid } from './api/is_session_valid';
import BasketPage from './pages/BasketPage';
import CheckoutPage from './pages/CheckoutPage';
import CompletedProjectsPage from './pages/CompletedProjectsPage';
import CompletedProjectPage from './pages/CompletedProjectPage';
import AddSlidesPage from './pages/AddSlidesPage';
import ManageCustomVoicesPage from './pages/ManageCustomVoicesPage';
import AddCustomVoicePage from './pages/AddCustomVoicePage';
import FineTuneCompletedProjectPage from './pages/FineTuneCompletedProjectsPage';
import LoginRegisterResetConfirmationPage from './pages/LoginRegisterResetConfirmationPage';
import TACSPage from './pages/TACS';
import PrivacyPage from './pages/Privacy';
const App = () => {
    const dispatch = useDispatch();

    //http://localhost:3000/?term=pizza&location=Bangalore
    /* const queryParams = new URLSearchParams(window.location.search);
    const location = queryParams.get("location");
    const term = queryParams.get("term");
    console.log(`${term}:${location}`);    */

    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session});
    const apiState = useSelector((state) => {return state.api});

    useEffect(() => {

        //detect when browser refreshed
        const navigation = window.performance.getEntriesByType('navigation')[0];
        if (navigation.type === 'reload') {
            let action = apiAction_resetAllOnReload(); dispatch(action);
        } else {}

        const runOnce = async () => {

            

            if (session_isCookieSet() === true){
                //isAuthValidAPI
                const cookie = session_getCookie();
    
                let action = apiAction_startIsSessionValid(); dispatch(action);
                const res = await is_session_valid(cookie.token); 
                const payload = { res: res, }
                if (res.success && res.success === true && res.status === 'session_valid'){
                    const sessionObject = {
                        isLoggedIn: true,
                        token: res.session.token,
                        creationDate: res.session.creationDate,
                        expiryDate: res.session.expiryDate
                    }
                    payload['session'] = sessionObject;
    
                    //SET COOKIES
                    session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
                }else{
                    
                    payload['pages'] = {
                        pagesAction: 'pagesAction_resetToInitialState',
                        nextPage: ''
                    };
                    //removes pages associated with being logged in
                    payload['noTokenCleanPages'] = {
                        value: true
                    }
                    const sessionObject = {
                        isLoggedIn: false,
                        token: null,
                        creationDate: null,
                        expiryDate: null
                    }
                    payload['session'] = sessionObject;
    
                    //REMOVE COOKIES
                    session_removeCookie();
                }
                
                action = apiAction_endIsSessionValid(payload); dispatch(action);
            }else{
                const payload = {}
                const sessionObject = {
                    isLoggedIn: false,
                    token: null,
                    creationDate: null,
                    expiryDate: null
                }
                payload['session'] = sessionObject;
                const action = pagesAction_resetToInitialState(payload);dispatch(action);
            }
        }

        runOnce();
        return () => {// this now gets called when the component unmounts - stops leaky memory
        };
    }, []);
    
    const x = pagesState.slice(0).map((page, index) => {
        if (page === 'AddSlidesPage'){
            return <AddSlidesPage count={index}  key={index} />
        }else if(page === 'BasketPage'){
            return <BasketPage count={index} key={index} />
        }else if(page === 'CheckoutPage'){
            return <CheckoutPage count={index} key={index} />
        }else if(page === 'PricingPage'){
            return <PricingPage count={index} key={index} />
        }else if(page === 'MenuPage'){
            return <MenuPage count={index} key={index}/>
        }else if(page === 'LoginRegisterEmailPage1'){
            return <LoginRegisterEmailPage1 count={index} key={index}/>
        }else if(page === 'LoginRegisterEmailPage2'){
            return <LoginRegisterEmailPage2 count={index} key={index}/>
        }else if (page === 'LoginRegisterPasswordPage1'){
            return <LoginRegisterPasswordPage1 count={index} key={index} />
        }else if (page === 'LoginRegisterPasswordPage2'){
            return <LoginRegisterPasswordPage2 count={index} key={index} />
        }else if (page === 'LoginRegisterSuccessfulPage'){
            return <LoginRegisterSuccessfulPage count={index} key={index} />
        }else if (page === 'LoginRegisterResetPage'){
            return <LoginRegisterResetPage count={index} key={index} />
        }else if (page === 'SignOutPage'){
            return <SignOutPage count={index} key={index} />
        }else if (page === 'DraftProjectsPage'){
            return <DraftProjectsPage count={index} key={index} />
        }else if (page === 'CompletedProjectsPage'){
            return <CompletedProjectsPage count={index} key={index} />
        }else if (page === 'CompletedProjectPage'){
            return <CompletedProjectPage count={index} key={index} />
        }else if (page === 'ManageCustomVoicesPage'){
            return <ManageCustomVoicesPage count={index} key={index} />
        }else if(page === 'AddCustomVoicePage'){
            return <AddCustomVoicePage count={index} key={index} />
        }else if(page === 'FineTuneCompletedProjectPage'){
            return <FineTuneCompletedProjectPage count={index} key={index} />
        }else if(page === 'LoginRegisterResetConfirmationPage'){
            return <LoginRegisterResetConfirmationPage count={index} key={index} />
        }else if(page === 'TACSPage'){
            return <TACSPage count={index} key={index} />
        }else if(page === 'PrivacyPage'){
            return <PrivacyPage count={index} key={index} />
        }else{
            return null;
        }

        
    });


    return (
        <>{x}</>
    );
}

export default App;