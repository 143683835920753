import Switch from "react-switch";
import classNames from "classnames";
import classNamesDedupe from "classnames/dedupe";
import { FiAlignJustify, FiArrowDown, FiArrowDownLeft, FiArrowDownRight, FiArrowLeft, 
    FiArrowRight, FiArrowUp, FiArrowUpLeft, FiArrowUpRight, FiChevronDown, FiChevronRight, FiChevronUp, FiClipboard, FiCopy, 
    FiVideo, 
    FiEdit2, 
    FiImage, FiLink, FiMinus, FiMove, FiMusic, FiPlus, FiSave, FiTrash, FiUser, FiX, FiMessageSquare, FiType, FiRepeat, FiHelpCircle, FiChevronsRight } from "react-icons/fi";
import { GrRevert } from "react-icons/gr";
import { FaRobot, FaSpinner } from 'react-icons/fa';
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as OpenPeep2 } from "../images/svg/openpeep2.svg";
import Button from "../components/buttons/Button";
import { pagesAction_openPage, asfAction_UpdateFragmentAiSpeakableText, 
    asfAction_UpdateFragmentOriginalText, 
    asfAction_addFragment,  
    asfAction_removeFragment, 
    pagesAction_closePage,
    asfAction_removeMediaFile,
    asfAction_copyMediaFile,
    asfAction_pasteMediaFile,
    asfAction_updateLargeSubtitlesField,
    asfAction_updateSubtitlesPositionField,
    asfAction_closeAddNewSlideModal,
    asfAction_closeChangeModal,
    asfAction_openAddNewSlideModal,
    asfAction_addBackgroundMusicSlide,
    asfAction_addTTSImageSlide,
    asfAction_addTTSVideoSlide,
    asfAction_addNoTTSImageSlide,
    asfAction_addNoTTSVideoSlide,
    asfAction_changeVoiceOnModalIndex,
    asfAction_removeSlide,
    asfAction_moveSlideUp,
    asfAction_moveSlideDown,
    asfAction_updateFrom,
    asfAction_updateTo,
    asfAction_updateColor,
    asfAction_updateDirection,
    asfAction_updateTransitionOpenClose,
    asfAction_updateTransitionTypeId,
    asfAction_updateOriginalText,
    asfAction_openChangeVoiceModal,
    asfAction_updateDurationField,
    apiAction_endUploadMediaFile,
    apiAction_startUploadMediaFile,
    asfAction_updateSubtitlesOnField,
    asfAction_updateSilenceIntervalAtEndField,
    asfAction_updateLoopBackgroundMusic,
    apiAction_startGetFineTuneProject,
    apiAction_endGetFineTuneProject,
    apiAction_startRevertToOriginalFineTuneProjectStatus,
    apiAction_endRevertToOriginalFineTuneProjectStatus,
    apiAction_startSaveFineTuneProjectStatus,
    apiAction_endSaveFineTuneProjectStatus,
    pagesAction_closeTwoPages,
    asfAction_updatePlayVideoTillEndField, 
 } from "../store";
import { getBackgroundCSS } from "../components/css";
import React from "react";
import ClosePageTopBar from "../components/ClosePageTopBar";
import NewSlideModal from "./modal/NewSlideModal";
import ChangeVoiceModal from "./modal/ChangeVoiceModal";
import { SketchPicker } from "react-color";
import Select from "react-select";
import Slider from "rc-slider";
import { upload_video_file } from "../api/upload_video_file";
import { upload_image_file } from "../api/upload_image_file";
import { upload_sound_file } from "../api/upload_sound_file";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import { get_fine_tune_project } from "../api/get_fine_tune_project";
import { revert_to_original_fine_tune_project } from "../api/revert_to_original_fine_tune_project";
import { save_fine_tune_project } from "../api/save_fine_tune_project";
import { apiAction_endSaveSubmitFineTuneProjectStatus, apiAction_startSaveSubmitFineTuneProjectStatus } from "../store/actions";
import { save_submit_fine_tune_project } from "../api/save_submit_fine_tune_project";
import SavedSumittedRemakeProject from "./modal/SavedSumittedRemakeProject";
import { loadCompletedProjects } from "./CompletedProjectsPage";

function FineTuneCompletedProjectPage({count}){
    const dispatch = useDispatch();
    
    const asfFormState = useSelector((state) => {return state.addSlidesForm});
    const pagesState = useSelector((state) => {return state.pages;});
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});

    const project = asfFormState.project;
    const voices = asfFormState.voices;
    const indexOfFirstTTSSlide = getIndexOfFirstTTSSlide();

    const inputFieldDivClass = classNames('flex border-black border-2 rounded-[20px] items-center h-[3.5rem] mt-6 px-2.5 w-full');
    const featherIconsClass = classNames('h-6 w-6 mx-2');
    const voiceIconsClass = classNames('h-8 w-8 mx-1 rounded-[20px]');
    const textInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] focus:outline-none');

    let backgroundClass = getBackgroundCSS(count, 'bg-white');

    const inputFieldInsideDivClass = classNamesDedupe(inputFieldDivClass, {
        'mt-6': false, 
        'mt-2.5': true
    });
    
    const textAreaInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] h-79 focus:outline-none');
    const textAreaFeatherIcon = classNames('h-6 w-6 mx-2 mt-3');
    const textAreaInputFieldDivClass = classNamesDedupe(inputFieldDivClass, {
        'items-center': false,
        'h-[3.5rem]': false,
        'h-80': true,
        'items-top': true,
        'mt-6': false,
        'mt-2.5': true,
        'relative': true,
    });

    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'mt-6', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1', {
        'hidden': false
    })

    const originalTextCharCountClass = (fragment) => {
        return classNames('absolute top-1 right-2 font-montserrat font-normal text-xs',
            {'text-red-1': fragment.charCount > 200}
        );
    }

    const aiSpeakableTextCharCountClass = (fragment) => {
        return classNames('absolute top-1 right-2 font-montserrat font-normal text-xs',
            {
                'text-red-1': fragment.aiSpeakableTextCharCount > 240,
                'text-white': fragment.aiSpeakableTextCharCount < 241
            }
        );
    }

    const titleErrorDivClass = () => {
        let hiddenValue = true;
        if (asfFormState.titleFieldState === 'failed-server-test'){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }

    const titleErrorMessage = () => {
        return asfFormState.titleErrorMessage;
    };

    const topErrorDivClass = () => {
        let hiddenValue = true;
        if (asfFormState.topErrorFieldState === 'failed-server-test'){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }

    const topErrorMessage = () => {
        return asfFormState.topErrorErrorMessage;
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className='flex border-black items-center mt-6 w-full font-montserrat font-medium text-[1rem] text-red-1'>
                    {errorMessage(stateErrorVariable)}
                </div>
            )
        }
    }

    const errorMessage = (stateErrorVariable) => {
        return stateErrorVariable === null || stateErrorVariable === undefined ? '' : stateErrorVariable;
    };

    const handleFragmentOriginalTextFieldChange = (e, slideIndex, fragmentIndex) => {
        const payload = {
            originalText: e.target.value,
            slideIndex: slideIndex,
            fragmentIndex: fragmentIndex
        };
        const action = asfAction_UpdateFragmentOriginalText(payload); dispatch(action);
    }

    const handleFragmentAiSpeakableTextFieldChange = (e, slideIndex, fragmentIndex) => {
        const payload = {
            aiSpeakableText: e.target.value,
            slideIndex: slideIndex,
            fragmentIndex: fragmentIndex
        };
        const action = asfAction_UpdateFragmentAiSpeakableText(payload); dispatch(action);
    }

    const handleLargeSubtitlesFieldChange = (checked, index) =>{
        const payload = {
            largeSubtitles: checked,
            index: index
        };
        const action = asfAction_updateLargeSubtitlesField(payload); dispatch(action);
    }

    const handleDurationChange = (duration, index) => {
        const payload = {
            duration: duration,
            index: index
        };
        const action = asfAction_updateDurationField(payload); dispatch(action);
    }

    const handlePlayVideoTillEndFieldChange = (checked, index) =>{
        const payload = {
            playVideoTillEnd: checked,
            index: index
        };
        const action = asfAction_updatePlayVideoTillEndField(payload); dispatch(action);
    }

    const handleOriginalTextFieldChange = (e, index) => {
        const payload = {
            originalText: e.target.value,
            index: index
        };
        const action = asfAction_updateOriginalText(payload); dispatch(action);
    }
    
    const handleOpenChangeVoiceModal = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }

        const action = asfAction_openChangeVoiceModal(index); dispatch(action);
    }

    const handleSubtitlesPosition = (subtitlesPosition, index) => {
        const payload = {
            subtitlesPosition: subtitlesPosition,
            index: index
        };
        const action = asfAction_updateSubtitlesPositionField(payload); dispatch(action);
    }

    const handleRemoveImageFile = (index) => {
        const action = asfAction_removeMediaFile(index);; dispatch(action);
    }

    const handleCopyImageFile = (index) => {
        const action = asfAction_copyMediaFile(index);; dispatch(action);
    }

    const handlePasteImageFile = (index) => {
        const action = asfAction_pasteMediaFile(index);; dispatch(action);
    }

    const handleOpenAddNewSlidesModal = (index) => {
        const action = asfAction_openAddNewSlideModal(index); dispatch(action);
    }

    const handleRemoveSlideClick = (index) => {
        const action = asfAction_removeSlide(index); dispatch(action);
    }

    const handleMoveSlideUpClick = (index) => {
        const action = asfAction_moveSlideUp(index); dispatch(action);
    }

    const handleMoveSlideDownClick = (index) => {
        const action = asfAction_moveSlideDown(index); dispatch(action);
    }

    const handleToggleTransitionOpenClose = (index) => {
        const payload = {
            index: index,
            transitionUIOpen: !project.slides[index].transitionUIOpen
        }
        const action = asfAction_updateTransitionOpenClose(payload); dispatch(action);
    }

    const handleChangeTransitionTypeId = (transitionOption, index) => {
        const payload = {
            transitionTypeId: transitionOption.value,
            index: index
        }
        const action = asfAction_updateTransitionTypeId(payload);; dispatch(action);
        //
    }
    

    const isDisableAllButtons = () => {
        if (isLoading()){
            return true;
        }else{
            return false;
        }
    }

    const originalTextErrorDivClass = (index) => {
        let hiddenValue = true;
        if (project.slides[index].originalTextFieldState === 'failed-server-test'){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }

    const originalTextErrorMessage = (index) => {
        return project.slides[index].originalTextErrorMessage;
    };

    const imageFileErrorDivClass = (index) => {
        let hiddenValue = true;
        if (project.slides[index].imageFileState === 'failed-server-test'){
            hiddenValue = false;
        }

        return classNames(errorDivClass, {
            'hidden': hiddenValue
        });
    }

    const imageFileErrorMessage = (index) => {
        return project.slides[index].imageFileErrorMessage;
    };

    const getSubtitlePositionButtonJSX = (slide, position, index) => {
        let arrowIcon = null;
        if (position === 1){
            arrowIcon = <FiArrowDownLeft className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"} />;
        }else if(position === 2){
            arrowIcon = <FiArrowDown className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 3){
            arrowIcon = <FiArrowDownRight className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 4){
            arrowIcon = <FiArrowLeft className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 5){
            arrowIcon = <FiMove className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 6){
            arrowIcon = <FiArrowRight className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 7){
            arrowIcon = <FiArrowUpLeft className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 8){
            arrowIcon = <FiArrowUp className="w-8 h-8"color={slide.subtitlesPosition === position? "white": "black"}/>;
        }else if(position === 9){
            arrowIcon = <FiArrowUpRight className="w-8 h-8" color={slide.subtitlesPosition === position? "white": "black"}/>;
        }

        return (
            <div className="p-1">
                <Button round={slide.subtitlesPosition !== position && !isDisableAllButtons()}
                roundDisabled={slide.subtitlesPosition !== position && isDisableAllButtons()} 
                roundSelected={slide.subtitlesPosition === position && !isDisableAllButtons()}
                roundSelectedDisabled={slide.subtitlesPosition === position && isDisableAllButtons()}
                onClick={(e) => {
                    handleSubtitlesPosition(position, index);
                }} 
                >
                   {arrowIcon}
                </Button>
            </div>
        );
    }

    const getImageInputPlaceholder = (slide, index) => {
        return slide.imageFileId === null? 'Click To Add Image (optional)': 'Click To Change Image'
    }



    const existsCopiedAudio = () => {
        let returnVar = false;
        for (const slide of project.slides){
            if (slide.copiedAudio === true){
                returnVar = true;
                break;
            }
        }
        return returnVar;
    }

    const undoChangesIconSpinner = () => {
        if (apiState.getFineTuneProjectStatus === 'waiting'){
            return <FaSpinner className="justify-self-start h-6 w-6 animate-spin"/>
        }else{
            return <FiTrash className="justify-self-start h-6 w-6"/>
        }
    }

    const revertChangesIconSpinner = () => {
        if (apiState.revertToOriginalFineTuneProjectStatus === 'waiting'){
            return <FaSpinner className="justify-self-start h-6 w-6 animate-spin"/>
        }else{
            return <GrRevert className="justify-self-start h-6 w-6"/>
        }
    }
    
    const saveRemakeProjectIconSpinner = () => {
        if (apiState.saveFineTuneProjectStatus === 'waiting'){
            return <FaSpinner className="justify-self-start h-6 w-6 animate-spin"/>
        }else{
            return <FiSave className="justify-self-start h-6 w-6"/>
        }
    }

    const saveSubmitRemakeProjectIconSpinner = () => {
        if (apiState.saveSubmitFineTuneProjectStatus === 'waiting'){
            return <FaSpinner className="justify-self-end h-6 w-6 animate-spin"/>
        }else{
            return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }
    }

    const isLoading = () => {
        if (
            apiState.getFineTuneProjectStatus === 'waiting' ||
            apiState.revertToOriginalFineTuneProjectStatus === 'waiting' ||
            apiState.saveFineTuneProjectStatus === 'waiting' ||
            apiState.saveSubmitFineTuneProjectStatus === 'waiting'
        ){
            return true;
        }else{
            return false;
        }
    }

    const handleUndoFineTuneProject = async () => {
        //open AddSlidesPage
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let payload = {projectId: project.id}
        let action = apiAction_startGetFineTuneProject(payload); dispatch(action);
        const res = await get_fine_tune_project(project.id, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'got_fine_tune_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['addSlidesForm'] = res;
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'FineTuneCompletedProjectPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endGetFineTuneProject(payload); dispatch(action);
    }

    const handleRevertProjectToOriginalClick = async () => {
        //open AddSlidesPage
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let payload = {projectId: project.id}
        let action = apiAction_startRevertToOriginalFineTuneProjectStatus(payload); dispatch(action);
        const res = await revert_to_original_fine_tune_project(project.id, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'reverted_to_original_fine_tune_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['addSlidesForm'] = res;
            payload['session'] = sessionObject;

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endRevertToOriginalFineTuneProjectStatus(payload); dispatch(action);
    }

    const handleSaveFineTuneProject = async () => {
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let payload = {projectId: project.id}
        let action = apiAction_startSaveFineTuneProjectStatus(payload); dispatch(action);
        const res = await save_fine_tune_project(project, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'saved_fine_tune_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['addSlidesForm'] = res;
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'FineTuneCompletedProjectPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endSaveFineTuneProjectStatus(payload); dispatch(action);
    }

    const handleSaveSubmitFineTuneProject = async () => {
        if (isLoading()){
            return;
        }

        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2'); dispatch(action);
            return;
        }
        
        let payload = {projectId: project.id}
        let action = apiAction_startSaveSubmitFineTuneProjectStatus(payload); dispatch(action);
        const res = await save_submit_fine_tune_project(project, sessionState.token);
        payload['res'] = res;
        if (res.success && res.success === true && res.status === 'saved_submitted_fine_tune_project'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['addSlidesForm'] = res;
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'FineTuneCompletedProjectPage'
            };

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        action = apiAction_endSaveSubmitFineTuneProjectStatus(payload); dispatch(action);
    }

    const getMainButtonsJSX = () => {
        return (
            <>
                <div className="w-full bg-white text-center flex flex-row mt-6 gap-5">
                    <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                        onClick={handleUndoFineTuneProject}
                        className="basis-1/2">
                        {undoChangesIconSpinner()}
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Undo fine tuning
                        </div>
                    </Button>
                    <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                        onClick={handleRevertProjectToOriginalClick}
                        className="basis-1/2">
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Revert to project at payment
                        </div>
                        {revertChangesIconSpinner()}
                    </Button>
                </div>
                <div className="w-full bg-white text-center flex flex-row mt-6 gap-5">
                    <Button 
                        plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                        onClick={handleSaveFineTuneProject}
                        className="basis-1/2" >
                        {saveRemakeProjectIconSpinner()}
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Save fine tuning
                        </div>
                    </Button>
                    <Button primary={!isDisableAllButtons()} primaryDisabled={isDisableAllButtons()} 
                        onClick={handleSaveSubmitFineTuneProject}
                        className="basis-1/2" >
                        <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                            Submit project to build
                        </div>
                        {saveSubmitRemakeProjectIconSpinner()}
                    </Button>
                </div> 
            </>
        )
    }

    const handleChangeColor = (color, index) => {
        const payload = {
            color: color.hex.substring(1),
            index: index
        }
        const action = asfAction_updateColor(payload); dispatch(action);
    }

    const handleUpdateFromClick = (event, index) => {
        const rect = event.target.getBoundingClientRect();
        
        let x = event.clientX;
        let y = event.clientY;
        
        const scaleFactorX = 3840 / rect.width;
        const scaleFactorY = 2160 / rect.height;
        x = (x - rect.left) * scaleFactorX; // x position within the element scaled to 3840x2160.
        y = (y - rect.top) * scaleFactorY;  // y position within the element scaled to 3840x2160.

        if (x < 0){ x = 0 }
        if (x > 3840){ x = 3840}
        if (y < 0){ y = 0 }
        if (y > 2160){ y = 2160 }
        
        const payload = {
            x: x,
            y: y,
            index: index
        }
        
        const action = asfAction_updateFrom(payload); dispatch(action);
    }

    const handleUpdateToClick = (event, index) => {
        const rect = event.target.getBoundingClientRect();
        
        let x = event.clientX;
        let y = event.clientY;
        
        const scaleFactorX = 3840 / rect.width;
        const scaleFactorY = 2160 / rect.height;
        x = (x - rect.left) * scaleFactorX; // x position within the element scaled to 3840x2160.
        y = (y - rect.top) * scaleFactorY;  // y position within the element scaled to 3840x2160.

        if (x < 0){ x = 0 }
        if (x > 3840){ x = 3840}
        if (y < 0){ y = 0 }
        if (y > 2160){ y = 2160 }

        const payload = {
            x: x,
            y: y,
            index: index
        }
        
        const action = asfAction_updateTo(payload); dispatch(action);
    }

    const isSlideError = (slide) => {
        if (
            slide.error.slide !== null || 
            slide.error.index !== null || 
            slide.error.soundFileId !== null || 
            slide.error.imageFileId !== null || 
            slide.error.videoFileId !== null || 
            slide.error.voiceId !== null || 
            slide.error.subtitlesOn !== null ||
            slide.error.largeSubtitles !== null || 
            slide.error.originalText !== null || 
            slide.error.silenceIntervalAtEnd !== null || 
            slide.error.duration !== null || 
            slide.error.loopBackgroundMusic !== null || 
            slide.error.transitionTypeId !== null || 
            slide.error.color !== null || 
            slide.error.direction !== null || 
            slide.error.fromX !== null || 
            slide.error.fromY !== null || 
            slide.error.toX !== null || 
            slide.error.toY !== null ||
            isFragmentsError(slide) === true
        ){
            return true;
        }
        
        return false;
    }

    const isFragmentsError = (slide) => {
        for (const fragment of slide.fragments){
            if (isFragmentError(fragment) === true){
                return true;
            }
        }

        return false;
    }

    const isFragmentError = (fragment) => {
        if (
            fragment.error.fragment !== null ||
            fragment.error.originalText !== null ||
            fragment.error.aiSpeakableText !== null
        ){
            return true;
        }else{
            return false;
        }
    }
    
    const getSlidesJSX = () => {
        const slidesJSX = [];

        const slidesCount = project.slides.length;
        project.slides.forEach((slide, index) => {
            let slideClass = classNames('flex flex-col border-2 rounded-[20px] items-center mt-6 pb-2.5 px-2.5 w-full');
            const slideError = isSlideError(slide);
            
            slideClass = classNames(slideClass, {
                'border-slate-300': !slideError,
                'border-red-1': slideError
            })

            let moveDownButtonStyle = {plain: true, plainDisabled: false};
            let moveUpButtonStyle = {plain: true, plainDisabled: false};
            let removeButtonStyle = {plain: true, plainDisabled: false};
            let addButtonStyle = {primary: true, primaryDisabled: false};
            if (slidesCount === 1){
                moveDownButtonStyle = {plain: false, plainDisabled: true};
                moveUpButtonStyle = {plain: false, plainDisabled: true};
                removeButtonStyle = {plain: true, plainDisabled: false};
            }else{
                if (index === 0){
                    moveUpButtonStyle = {plain: false, plainDisabled: true};
                }else if (index === slidesCount - 1){
                    moveDownButtonStyle = {plain: false, plainDisabled: true};
                }
            }

            if (isDisableAllButtons()){
                moveDownButtonStyle = {plain: false, plainDisabled: true};
                moveUpButtonStyle = {plain: false, plainDisabled: true};
                removeButtonStyle = {plain: false, plainDisabled: true};
                addButtonStyle = {primary: false, primaryDisabled: true};
            }

            slidesJSX.push(
                <div key={index} className={slideClass}>
                    {getSlideJSX(slide, index)}
                    <div className="w-full bg-white justify-around mt-8 gap-5 flex flex-row border rounded-full p-3">
                        <Button round={moveDownButtonStyle.plain} roundDisabled={moveDownButtonStyle.plainDisabled}
                        onClick={() =>{
                            handleMoveSlideDownClick(index)
                        }}>
                            <FiChevronDown className="justify-self-center grow h-6 w-6"/>
                        </Button>
                        <Button round={moveUpButtonStyle.plain} roundDisabled={moveUpButtonStyle.plainDisabled}
                        onClick={() =>{
                            handleMoveSlideUpClick(index)
                        }}>
                            <FiChevronUp className="justify-self-center grow h-6 w-6"/>
                        </Button>
                        <Button round={removeButtonStyle.plain} roundDisabled={removeButtonStyle.plainDisabled}
                        onClick={() =>{
                            handleRemoveSlideClick(index);
                        }}>
                            <FiMinus className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            );
        });

        return slidesJSX;
    }

    const getSlideJSX = (slide, index) => {
        if (slide.slideType === 1){return getBackgroundMusicSlideJSX(slide, index);}
        else if(slide.slideType === 2){return getTTSImageSlideJSX(slide, index);}
        else if(slide.slideType === 3){return getTTSVideoSlideJSX(slide, index);}
        else if(slide.slideType === 4){return getNoTTSImageSlideJSX(slide, index);}
        else if(slide.slideType === 5){return getNoTTSVideoSlideJSX(slide, index)}
    }

    //slideType = 1
    const getBackgroundMusicSlideJSX = (slide, index) => {
        return (
        <>
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiMusic className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getErrorJSX(slide.error.slide)}
            {getUploadSoundJSX(slide, index)}
            {getPreviewSoundJSX(slide, index)}
            {getLoopBackgroundMusicJSX(slide, index)}
        </> 
        )
    }

    //slideType = 2
    const getTTSImageSlideJSX = (slide, index) => {
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiImage className="justify-start h-6 w-6 ml-3"/>
                    <FaRobot className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getUploadImageJSX(slide, index)}
            {getPreviewImageJSX(slide, index)}
            {getChangeVoiceJSX(slide, index)}
            {getFragmentsJSX(slide.fragments, index)}
            {getSubtitlesJSX(slide, index)}
            {getDividerJSX()}
            {getSilenceIntervalJSX(slide, index)}
            {getDividerJSX()}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    //slideType = 3
    const getTTSVideoSlideJSX = (slide, index) => {
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiVideo className="justify-start h-6 w-6 ml-3"/>
                    <FaRobot className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getUploadVideoJSX(slide, index)}
            {getErrorJSX(slide.error.voiceId)}
            {getChangeVoiceJSX(slide, index)}
            {getPreviewVideoJSX(slide, index)}
            {getErrorJSX(slide.error.fragment)}
            {getFragmentsJSX(slide.fragments, index)}
            {getSubtitlesJSX(slide, index)}
            {getDividerJSX()}
            {getPlayVideoTillEndJSX(slide, index)}
            {getSilenceIntervalJSX(slide, index)}
            {getDividerJSX()}
            {getErrorJSX(slide.error.transitionId)}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    //slideType = 4
    const getNoTTSImageSlideJSX = (slide, index) => {
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiImage className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getErrorJSX(slide.error.imageFileId)}
            {getUploadImageJSX(slide, index)}
            {getPreviewImageJSX(slide, index)}
            {getErrorJSX(slide.error.duration)}
            {getDurationJSX(slide, index)}
            {getDividerJSX()}
            {getErrorJSX(slide.error.transitionId)}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    //slideType = 5
    const getNoTTSVideoSlideJSX = (slide, index) => {
        
        return (
        <>
            {getErrorJSX(slide.error.slide)}
            <div className="w-full flex flex-row items-center mt-2.5 grow">
                <div className="w-full font-montserrat font-extrabold text-2xl flex ml-1 justify-start items-center">
                    Slide {index + 1} 
                    <FiVideo className="justify-start h-6 w-6 ml-3"/>
                </div>
            </div>
            {getErrorJSX(slide.error.videoFileId)}
            {getUploadVideoJSX(slide, index)}
            {getPreviewVideoJSX(slide, index)}
            {getErrorJSX(slide.error.duration)}
            {getDurationJSX(slide, index)}
            {getDividerJSX()}
            {getErrorJSX(slide.error.transitionId)}
            {getTransitionJSX(slide, index)}
        </> 
        )
    }

    const getTransitionJSX = (slide, index) => {
        const getIsTransitionOpenIcon = () => {
            if (slide.transitionUIOpen === true){
                return <FiChevronDown className={classNames('justify-self-center grow h-6 w-6', {
                    'text-white': slide.transitionUIOpen
                })}/>
            }else{
                return <FiChevronRight className={classNames('justify-self-center grow h-6 w-6', {
                    'text-white': slide.transitionUIOpen
                })}/>
            }
        }

        const transitionOptions = [
            {value: 1, label: 'Cut'},
            {value: 2, label: 'Fade In Out'},
            {value: 3, label: 'Dissolve'},
            {value: 4, label: 'Iris'},
            {value: 5, label: 'Wipe'},
            {value: 6, label: 'Whip Pan'},
            {value: 7, label: 'Zoom Transition'},
            {value: 8, label: 'Barrel Roll'},
        ]

        const getTransitionOption = (transitionTypeId) => {
            for (const transitionOption of transitionOptions){
                if(transitionTypeId === transitionOption.value){
                    return transitionOption
                }
            }
        }

        const customStyles = {
            control: (base) => ({
              ...base,
              border: '2px solid black',
              boxShadow: 'none',
              borderRadius: '30px', // Apply rounded border
              padding: '5px',
              width: '100%',
            }),
        };

        const getTransitionSubComponentsJSX = () => {
            if (slide.transitionUIOpen === false){
                return <></>
            }else{
                if (slide.transitionTypeId === 1){
                    return <></>
                }else if(slide.transitionTypeId === 2){
                    return <>{getColorPickerJSX()}</>
                }else if(slide.transitionTypeId === 3){
                    return <></>
                }else if(slide.transitionTypeId === 4){
                    return(
                        <>
                            {getColorPickerJSX()}
                            {getPickFromCoordinates()}
                            {getPickToCoordinates()}
                        </>
                    )
                }else if (slide.transitionTypeId === 5){
                    return <>{pickDirectionJSX('Wipe Direction')}</>
                }else if(slide.transitionTypeId === 6){
                    return <>{pickDirectionJSX('Whip Pan Direction')}</>
                }else if(slide.transitionTypeId === 7){
                    return <></>
                }else if(slide.transitionTypeId === 8){
                    return <></>
                }
            }
        }

        const getColorPickerJSX = () => {
            return (
                <div className='flex flex-col place-items-center w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-col ml-1">
                        <div className="flex flex-grow">
                            Pick a color
                        </div>
                        {getErrorJSX(slide.error.color)}
                        <div className="flex flex-col place-items-center">
                            <SketchPicker
                                color={`#${slide.color}`}
                                styles={{ default: { picker: { width: '90%', marginTop: '1rem'  }}}}
                                onChange={(color) => handleChangeColor(color, index)}
                                disableAlpha={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        const handleStopPropagation = (event) => {
            // Stop the click event from bubbling up to the parent
            event.stopPropagation();
        }

        const getPickFromCoordinates = () => {
            const style = {
                left: `calc(${(slide.fromX / 3840) * 100}% - 1.5rem)`,
                top: `calc(${(slide.fromY / 2160) * 100}% - 1.5rem)`,
            };

            return (
                <div className='flex flex-col place-items-center w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-col ml-1">
                        <div className="flex flex-grow py-3">
                            Choose the exit point for Iris: 
                        </div>
                        {getErrorJSX(slide.error.fromX)}
                        {getErrorJSX(slide.error.fromY)}
                        <div className='flex rounded-[20px] items-center w-full'>
                            <div className="relative bg-sky-900 rounded-[20px] w-full aspect-video cursor-pointer" onClick={(event) => handleUpdateFromClick(event, index)}>
                                <div style={style} className={`absolute w-12 h-12 rounded-full bg-white`} onClick={handleStopPropagation} />
                            </div>
                        </div>
                        <div className='flex w-full mt-1 font-montserrat font-semibold text-lg'>
                            <span className="text-center w-full ">({Math.trunc(slide.fromX)}, {Math.trunc(slide.fromY)})</span>
                        </div>
                    </div>
                </div>
            )
        }

        const getPickToCoordinates = () => {
            const style = {
                left: `calc(${(slide.toX / 3840) * 100}% - 1.5rem)`,
                top: `calc(${(slide.toY / 2160) * 100}% - 1.5rem)`,
            };

            return (
                <div className='flex flex-col place-items-center w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-col ml-1">
                        <div className="flex flex-grow py-3">
                            Choose the entry point for the Iris (next slide)
                        </div>
                        {getErrorJSX(slide.error.toX)}
                        {getErrorJSX(slide.error.toY)}
                        <div className='flex rounded-[20px] items-center w-full'>
                            <div className="relative bg-sky-900 rounded-[20px] w-full aspect-video cursor-pointer" onClick={(event) => handleUpdateToClick(event, index)}>
                                <div style={style} className={`absolute w-12 h-12 rounded-full bg-white`} onClick={handleStopPropagation} />
                            </div>
                        </div>
                        <div className='flex w-full mt-1 font-montserrat font-semibold text-lg'>
                            <span className="text-center w-full ">({Math.trunc(slide.toX)}, {Math.trunc(slide.toY)})</span>
                        </div>
                    </div>
                </div>
            )
        }

        const pickDirectionJSX = (headerText) => {

            const getDirectionButtonJSX = (slide, direction, index) => {
                let arrowIcon = null;
                if (direction === 1){
                    arrowIcon = <FiArrowDownLeft className="w-8 h-8" color={slide.direction === direction? "white": "black"} />;
                }else if(direction === 2){
                    arrowIcon = <FiArrowDown className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 3){
                    arrowIcon = <FiArrowDownRight className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 4){
                    arrowIcon = <FiArrowLeft className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 5){
                    arrowIcon = <></>;
                }else if(direction === 6){
                    arrowIcon = <FiArrowRight className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 7){
                    arrowIcon = <FiArrowUpLeft className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 8){
                    arrowIcon = <FiArrowUp className="w-8 h-8"color={slide.direction === direction? "white": "black"}/>;
                }else if(direction === 9){
                    arrowIcon = <FiArrowUpRight className="w-8 h-8" color={slide.direction === direction? "white": "black"}/>;
                }
        
                return (
                    <div className="p-1">
                        <Button round={slide.direction !== direction && !isDisableAllButtons()}
                        roundDisabled={slide.direction !== direction && isDisableAllButtons()} 
                        roundSelected={slide.direction === direction && !isDisableAllButtons()}
                        roundSelectedDisabled={slide.direction === direction && isDisableAllButtons()}
                        onClick={(e) => {
                            if (direction !== 5){
                                handleUpdateDirection(direction, index);
                            }
                        }} 
                        >
                           {arrowIcon}
                        </Button>
                    </div>
                );
            }

            return (
                <>
                    {getErrorJSX(slide.error.direction)}
                    <div className='flex flex-col w-full pt-6'>
                        <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex-row place-items-center">{headerText}</div>
                        <div className="flex flex-col mt-6">
                            <div className="flex justify-center">
                                {getDirectionButtonJSX(slide, 7, index)}
                                {getDirectionButtonJSX(slide, 8, index)}
                                {getDirectionButtonJSX(slide, 9, index)}
                            </div>
                            <div className="flex justify-center">
                                {getDirectionButtonJSX(slide, 4, index)}
                                {getDirectionButtonJSX(slide, 5, index)}
                                {getDirectionButtonJSX(slide, 6, index)}
                            </div>
                            <div className="flex justify-center">
                                {getDirectionButtonJSX(slide, 1, index)}
                                {getDirectionButtonJSX(slide, 2, index)}
                                {getDirectionButtonJSX(slide, 3, index)}
                            </div>  
                        </div>
                    </div>
                </>
            )
        }

        const getTransitionSelectJSX = () => {
            if (slide.transitionUIOpen === false){
                return <></>
            }else{
                return (
                    <div className='flex flex-col w-full pt-3'>
                        <div className="w-full h-full flex flex flex-row place-items-center ml-1">
                            {getErrorJSX(slide.error.transition)}
                            <div className="flex flex-grow w-full">
                                <Select 
                                    className="w-full font-montserrat font-bold text-xl"
                                    value={getTransitionOption(slide.transitionTypeId)}
                                    options={transitionOptions}
                                    styles={customStyles}
                                    onChange={(transitionOption) => handleChangeTransitionTypeId(transitionOption, index)}
                                />
                            </div>
                       </div>
                    </div>
                )
            }
        }

        return (
            <>
                <div className='flex flex-col w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">Transitions</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && !slide.transitionUIOpen}
                                roundDisabled={isDisableAllButtons() && !slide.transitionUIOpen}
                                roundSelected={!isDisableAllButtons() && slide.transitionUIOpen}
                                roundSelectedDisabled={isDisableAllButtons() && slide.transitionUIOpen}
                                onClick={() => {handleToggleTransitionOpenClose(index)}}>
                                {getIsTransitionOpenIcon()}
                            </Button>
                        </div>
                    </div>
                </div>
                {getTransitionSelectJSX()}
                {getTransitionSubComponentsJSX()}
            </>
        )
    }

    const getChangeVoiceJSX = (slide, index) => {
        return (
            <>
                {getErrorJSX(slide.error.voiceId)}
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        <img className={voiceIconsClass} 
                        src={`https://saythatback-voicefile.s3.amazonaws.com/${slide.voice.voiceImageFile.s3key}_128x128.jpg`}
                        />
                        <input id="video" readOnly={true} type="text" name="video" 
                            className={textInputClass} 
                            value={slide.voice.label}
                        />
                    </div>
                </div>
            </>
        )
    }

    const getDurationJSX = (slide, index) => {
        let max = 60000;
        if (slide.videoFileId !== null && slide.videoFileId !== undefined){
            max = slide.videoFileDuration;
        }
        return (
            <>
                <div className="w-full h-7 font-montserrat font-extrabold text-xl align-middle my-3">Duration {`${Math.floor(slide.duration / 100)/10}s`}</div>
                <div className='flex rounded-[20px] items-center w-full px-2'>
                    <Slider
                        min={1000}
                        max={max}
                        value={slide.duration}
                        onChange={(value) => handleDurationChange(value, index)}
                        trackStyle={[{ backgroundColor: '#000000' },]}
                        handleStyle={[{
                            backgroundColor: '#000000',
                            height: 20,
                            width: 20,
                            marginTop: -8
                        }]}
                    />
                </div>
            </>
        )
    }

    const handleSoundFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "audio/mpeg, audio/aac";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadSoundFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadSoundFile = async (filepath, index) => {
        let action = apiAction_startUploadMediaFile(index); dispatch(action);
        const res = await upload_sound_file(filepath, sessionState.token);
        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'sound_file_added'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        console.log(payload);
        action = apiAction_endUploadMediaFile(payload); dispatch(action);
    }

    const handleImageFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "image/png, image/jpeg, image/webp";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadImageFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadImageFile = async (filepath, index) => {
        let action = apiAction_startUploadMediaFile(index); dispatch(action);
        const res = await upload_image_file(filepath, sessionState.token);

        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'image_file_added'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endUploadMediaFile(payload); dispatch(action);
    }

    const handleVideoFileOnClick = (index) => {
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');
            dispatch(action);
            return;
        }

        let input = document.createElement('input');
        input.type = 'file';
        input.accept= "video/mp4, video/quicktime";
        input.hidden = true;
        document.body.appendChild(input);
        //IOS Problems 
        //(1)- therefore addListener is used
        //(2)- input is added and removed from body
        //https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        //Take Photo Issues
        input.addEventListener('change', async (e) => {
            let files = Array.from(input.files);
            const file = files[0];
            uploadVideoFile(file, index);
            document.body.removeChild(input);
        });
        /*
        input.onchange = () => {
            let files = Array.from(input.files);
            resizeLandscape(files[0], async (data, filename) => {
                // you can use this method to get file and perform respective operations
                await uploadImageFile(data, index);
            });
        };
        */
        input.click();   
    }

    const uploadVideoFile = async (filepath, index) => {
        let action = apiAction_startUploadMediaFile(index); dispatch(action);
        const res = await upload_video_file(filepath, sessionState.token);

        const payload = { res: res, index: index};
        if (res.success && res.success === true && res.status === 'video_file_added'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['addSlidesForm'] = { res: res, index: index};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }

        action = apiAction_endUploadMediaFile(payload); dispatch(action);
    }

    const handleSubtitlesOnFieldChange = (checked, index) =>{
        const payload = {
            subtitlesOn: checked,
            index: index
        };
        const action = asfAction_updateSubtitlesOnField(payload); dispatch(action);
    }

    const handleSilenceIntervalAtEndChange = (silenceIntervalAtEnd, index) => {
        const payload = {
            silenceIntervalAtEnd: silenceIntervalAtEnd,
            index: index
        };
        const action = asfAction_updateSilenceIntervalAtEndField(payload); dispatch(action);
    }

    const isUploadingMediaFileAtIndex = (index) => {
        let returnVar = false;
        for (const indexItem of apiState.uploadMediaFileIndexWaitingArray){
            if (indexItem === index){
                returnVar = true;
                break;
            }
        }
        return returnVar;
    }

    const isUploadingMediaFile = () => {
        return apiState.uploadMediaFileIndexWaitingArray.length > 0;
    }

    const isSavingDraft = () => {
        return apiState.saveToDraftStatus === 'waiting';
    }

    const isSavingDraftLoadingBasket = () => {
        return apiState.saveToDraftLoadBasketStatus === 'waiting';
    }

    const getUploadSoundJSX = (slide, index) => {
        const getMediaUploadIconSpinner = () => {
            if(isUploadingMediaFileAtIndex(index) === true){
                return <FaSpinner className="h-6 w-6 mx-2 animate-spin"/>
            }else{
                return <FiMusic className={featherIconsClass}/>
            }
        }

        const getMediaFileInputPlaceholder = (slide, index) => {
            if (isUploadingMediaFileAtIndex(index)){
                return "uploading backgound music..."
            }else{
                return slide.soundFileId === null? 'Click to add background music': 'Click to change background music'
            }
        } 
        
        const showRemoveMediaButton = () => {
            return slide.soundFileId === null;
        }

        let roundIconsClass = classNames('justify-self-center grow h-6 w-6');
        const copyIconClass = () => {
            let textColorWhite = false;
            if (slide.copiedMedia){
                textColorWhite = true;
            }
    
            return classNames(roundIconsClass, {
                'text-white': textColorWhite
            });
        }

        return (
            <>
                {getErrorJSX(slide.error.soundFileId)}
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        {getMediaUploadIconSpinner()}
                        <input id="soundFile" readOnly={true} type="text" name="soundFile" className={textInputClass} 
                        placeholder={getMediaFileInputPlaceholder(slide, index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleSoundFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', 
                    {hidden: showPasteMediaButton(index, slide.slideType) === false})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handlePasteMediaFile(index)}}>
                            <FiClipboard className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showCopyMediaButton(index, slide)})}>
                        <Button 
                        round={!isDisableAllButtons() && slide.copiedMedia === false}
                        roundSelected={!isDisableAllButtons() && slide.copiedMedia === true}
                        roundDisabled={isDisableAllButtons()} 
                        onClick={() => {handleCopyMediaFile(index)}}>
                            <FiCopy className={copyIconClass()}/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showRemoveMediaButton()})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handleRemoveMediaFile(index)}}>
                            <FiX className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const getUploadImageJSX = (slide, index) => {
        const getImageUploadIconSpinner = () => {
            if(isUploadingMediaFileAtIndex(index) === true){
                return <FaSpinner className="h-6 w-6 mx-2 animate-spin"/>
            }else{
                return <FiImage className={featherIconsClass}/>
            }
        }

        const getMediaFileInputPlaceholder = (slide, index) => {
            if (isUploadingMediaFileAtIndex(index)){
                return "uploading image..."
            }else{
                return slide.imageFileId === null? 'Click to add image': 'Click to change image'
            }
        }  

        const showRemoveMediaButton = () => {
            return slide.imageFileId === null;
        } 

        let roundIconsClass = classNames('justify-self-center grow h-6 w-6');
        const copyIconClass = () => {
            let textColorWhite = false;
            if (slide.copiedMedia){
                    textColorWhite = true;
            }
    
            return classNames(roundIconsClass, {
                'text-white': textColorWhite
            });
        }

        return (
            <>
                {getErrorJSX(slide.error.imageFileId)}
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        {getImageUploadIconSpinner()}
                        <input id="imageFile" readOnly={true} type="text" name="imageFile" className={textInputClass} 
                        placeholder={getMediaFileInputPlaceholder(slide, index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleImageFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', 
                    {hidden: showPasteMediaButton(index, slide.slideType) === false})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handlePasteMediaFile(index)}}>
                            <FiClipboard className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showCopyMediaButton(index, slide)})}>
                        <Button 
                        round={!isDisableAllButtons() && slide.copiedMedia === false}
                        roundSelected={!isDisableAllButtons() && slide.copiedMedia === true}
                        roundDisabled={isDisableAllButtons()} 
                        onClick={() => {handleCopyMediaFile(index)}}>
                            <FiCopy className={copyIconClass()}/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showRemoveMediaButton()})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handleRemoveMediaFile(index)}}>
                            <FiX className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const getUploadVideoJSX = (slide, index) => {
        const getVideoUploadIconSpinner = () => {
            if(isUploadingMediaFileAtIndex(index) === true){
                return <FaSpinner className="h-6 w-6 mx-2 animate-spin"/>
            }else{
                return <FiVideo className={featherIconsClass}/>
            }
        }

        const getMediaFileInputPlaceholder = (slide, index) => {
            if (isUploadingMediaFileAtIndex(index)){
                return "uploading video..."
            }else{
                return slide.videoFileId === null? 'Click to add video': 'Click to change video'
            }
        } 
        
        const showRemoveMediaButton = () => {
            return slide.videoFileId === null;
        }

        let roundIconsClass = classNames('justify-self-center grow h-6 w-6');
        const copyIconClass = () => {
            let textColorWhite = false;
            if (slide.copiedMedia){
                    textColorWhite = true;
            }
    
            return classNames(roundIconsClass, {
                'text-white': textColorWhite
            });
        }

        return (
            <>
                {getErrorJSX(slide.error.videoFileId)}            
                <div className="flex flex-row w-full">
                    <div className={inputFieldInsideDivClass}>
                        {getVideoUploadIconSpinner()}
                        <input id="videoFile" readOnly={true} type="text" name="videoFile" className={textInputClass} 
                        placeholder={getMediaFileInputPlaceholder(slide, index)}
                        onClick={() => {
                            if(!isDisableAllButtons()){
                                handleVideoFileOnClick(index);
                            }
                        }} 
                        />
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', 
                    {hidden: showPasteMediaButton(index, slide.slideType) === false})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handlePasteMediaFile(index)}}>
                            <FiClipboard className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showCopyMediaButton(index, slide)})}>
                        <Button 
                        round={!isDisableAllButtons() && slide.copiedMedia === false}
                        roundSelected={!isDisableAllButtons() && slide.copiedMedia === true}
                        roundDisabled={isDisableAllButtons()} 
                        onClick={() => {handleCopyMediaFile(index)}}>
                            <FiCopy className={copyIconClass()}/>
                        </Button>
                    </div>
                    <div className={classNames('ml-2', 'flex', 'justify-center', 'items-center', 'items-center', 'mt-2.5', {hidden: showRemoveMediaButton()})}>
                        <Button 
                            round={!isDisableAllButtons()}
                            roundDisabled={isDisableAllButtons()}
                            onClick={() => {handleRemoveMediaFile(index)}}>
                            <FiX className="justify-self-center grow h-6 w-6"/>
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    const getPreviewSoundJSX = (slide, index) => {
        if (slide.soundFileId === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex flex-col items-center mt-6 w-full')}>
                    <div className="rounded-[20px] w-full h-full">
                        <div className='player-wrapper bg-yellow-1 w-full rounded-[20px]'>
                            <ReactPlayer 
                                url={`https://saythatback-soundfile.s3.amazonaws.com/${slide.soundFileS3Key}.aac`}
                                width="100%"
                                height="100%"
                                controls={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getPreviewImageJSX = (slide, index) => {
        if (slide.imageFileId === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex, rounded-[20px], items-center, w-full', {'hidden': slide.imageFileId === null})} id={'previewImgDiv' + index}>
                    <img id={'previewImg' + index} alt="" 
                    className={classNames('w-full, bg-white, rounded-[20px] mt-2', {'hidden': slide.imageFileId === null})} 
                    src={slide.imageFileS3Key?`https://saythatback-imagefile.s3.amazonaws.com/${slide.imageFileS3Key}_1280x720.jpg`:''} />
                </div>
            );
        }
    }

    const getPreviewVideoJSX = (slide, index) => {
        if (slide.videoFileId === null){
            return <></>
        }else{
            return (
                <div className={classNames('flex, rounded-[20px], items-center, w-full', {'hidden': slide.videoFileId === null})} id={'previewImgDiv' + index}>
                    <div className='player-wrapper w-full rounded-[20px] mt-2'>
                        <ReactPlayer
                            url={`https://saythatback-videofile.s3.amazonaws.com/${slide.videoFileS3Key}.mp4`}
                            className='react-player'
                            width="100%"
                            height="100%"
                            playsinline
                            controls
                        />
                    </div>
                </div>
            )
        }
    }

    const getSubtitlesJSX = (slide, index) => {
        return(
            <>
                {getErrorJSX(slide.error.subtitlesOn)}
                <div className='flex flex-col w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">Subtitles {slide.subtitlesOn === true?'on':'off'}</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && !slide.subtitlesOn}
                                roundDisabled={isDisableAllButtons() && !slide.subtitlesOn}
                                roundSelected={!isDisableAllButtons() && slide.subtitlesOn}
                                roundSelectedDisabled={isDisableAllButtons() && slide.subtitlesOn}
                                onClick={() => {handleSubtitlesOnFieldChange(!slide.subtitlesOn, index)}}>
                                <FiMessageSquare className={classNames('justify-self-center grow h-6 w-6',
                                    {'text-white': slide.subtitlesOn}
                                )}/>
                            </Button>
                        </div>
                    </div>
                </div>
                {getErrorJSX(slide.error.largeSubtitles)}
                <div className={classNames('flex', 'flex-col', 'w-full', 'pt-3', {hidden: !slide.subtitlesOn})}>
                    <div className="w-full font-montserrat font-extrabold text-xl flex h-full flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">{slide.largeSubtitles===true?'Large':'Small'} subtitles</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && slide.largeSubtitles}
                                roundDisabled={isDisableAllButtons() && slide.largeSubtitles}
                                roundSelected={!isDisableAllButtons() && !slide.largeSubtitles}
                                roundSelectedDisabled={isDisableAllButtons() && !slide.largeSubtitles}
                                onClick={() => {handleLargeSubtitlesFieldChange(!slide.largeSubtitles, index)}}>
                                <FiType className={classNames('justify-self-center grow',
                                    {
                                        'text-white': !slide.largeSubtitles,
                                        'h-6': slide.largeSubtitles,
                                        'w-6': slide.largeSubtitles,
                                        'h-4': !slide.largeSubtitles,
                                        'w-4': !slide.largeSubtitles
                                    }
                                )}/>
                            </Button>
                        </div>
                    </div>
                </div>
                {getErrorJSX(slide.error.subtitlesPosition)}
                <div className={classNames('flex', 'flex-col', 'w-full', 'pt-6', {hidden: !slide.subtitlesOn || slide.largeSubtitles})}>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex-row place-items-center">Small Subtitles Position</div>
                    <div className="flex flex-col mt-6">
                        <div className="flex justify-center">
                            {getSubtitlePositionButtonJSX(slide, 7, index)}
                            {getSubtitlePositionButtonJSX(slide, 8, index)}
                            {getSubtitlePositionButtonJSX(slide, 9, index)}
                        </div>
                        <div className="flex justify-center">
                            {getSubtitlePositionButtonJSX(slide, 4, index)}
                            {getSubtitlePositionButtonJSX(slide, 5, index)}
                            {getSubtitlePositionButtonJSX(slide, 6, index)}
                        </div>
                        <div className="flex justify-center">
                            {getSubtitlePositionButtonJSX(slide, 1, index)}
                            {getSubtitlePositionButtonJSX(slide, 2, index)}
                            {getSubtitlePositionButtonJSX(slide, 3, index)}
                        </div>  
                    </div>
                </div>
            </>
        )
    }
    
    const getPlayVideoTillEndJSX = (slide, index) => {
        return(
            <>
                {getErrorJSX(slide.error.playVideoTillEnd)}
                <div className='flex flex-col w-full pt-3'>
                    <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex flex-row place-items-center ml-1">
                        <div className="flex flex-grow">Play Video Till End</div>
                        <div className="flex">
                            <Button 
                                round={!isDisableAllButtons() && !slide.playVideoTillEnd}
                                roundDisabled={isDisableAllButtons() && !slide.playVideoTillEnd}
                                roundSelected={!isDisableAllButtons() && slide.playVideoTillEnd}
                                roundSelectedDisabled={isDisableAllButtons() && slide.playVideoTillEnd}
                                onClick={() => {handlePlayVideoTillEndFieldChange(!slide.playVideoTillEnd, index)}}>
                                <FiChevronsRight className={classNames('justify-self-center grow h-6 w-6',
                                    {'text-white': slide.playVideoTillEnd}
                                )}/>
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const getSilenceIntervalJSX = (slide, index) => {
        if (slide.slideType === 3 && slide.playVideoTillEnd === true){
            return (
                <>
                
                </>
            )
        }else{
            return (
                <>
                    {getErrorJSX(slide.error.silenceIntervalAtEnd)}
                    <div className="w-full font-montserrat font-extrabold text-xl pt-6 ml-1">
                        Silence interval at end: {`${Math.floor(slide.silenceIntervalAtEnd / 100)/10}s`}
                    </div>
                    <div className='flex rounded-[20px] items-center w-full px-2 pt-3'>
                        <Slider
                            min={0}
                            max={5000}
                            value={slide.silenceIntervalAtEnd}
                            onChange={(value) => handleSilenceIntervalAtEndChange(value, index)}
                            trackStyle={[{ backgroundColor: '#000000' },]}
                            handleStyle={[{
                                backgroundColor: '#000000',
                                height: 20,
                                width: 20,
                                marginTop: -8
                            }]}
                        />
                    </div>
                </>
            )
        }
    }

    const getLoopBackgroundMusicJSX = (slide, index) => {
        if (slide.soundFileId === null){
            return <></>
        }else{
            const handleLoopBackgroundMusicChange = (checked, index) =>{
                const payload = {
                    loopBackgroundMusic: checked,
                    index: index
                };
                const action = asfAction_updateLoopBackgroundMusic(payload); dispatch(action);
            }
            
            return (
                <>
                    {getDividerJSX()}
                    <div className='flex flex-col w-full pt-3'>
                        {getErrorJSX(slide.error.loopBackgroundMusic)}
                        <div className="w-full font-montserrat font-extrabold text-xl h-full flex flex-row place-items-center ml-1">
                            <div className='flex flex-grow'>
                                {slide.loopBackgroundMusic===true?'Repeat background music':'Play background music once'}
                            </div>
                            <div className="flex">
                                    <Button 
                                        round={!isDisableAllButtons() && !slide.loopBackgroundMusic}
                                        roundDisabled={isDisableAllButtons() && !slide.loopBackgroundMusic}
                                        roundSelected={!isDisableAllButtons() && slide.loopBackgroundMusic}
                                        roundSelectedDisabled={isDisableAllButtons() && slide.loopBackgroundMusic}
                                        onClick={() => {handleLoopBackgroundMusicChange(!slide.loopBackgroundMusic, index)}}>
                                        <FiRepeat className={classNames('justify-self-center grow h-6 w-6',{'text-white': slide.loopBackgroundMusic,})}/>
                                    </Button>
                                </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const getDividerJSX = () => {
        return (
            <div className="w-full h-[0.1rem] bg-slate-300 mt-3"></div>
        )
    }
    
    //run against css:hidden so outputs are inverted
    const showCopyMediaButton = (index, slide) => {
        if(slide.slideType === 1 && slide.soundFileId !== null){
            for (const [i, thisSlide] of project.slides.entries()){
                if (
                    i !== index && 
                    thisSlide.slideType === 1
                ){
                    return false;
                }
            }
        }else if ((slide.slideType === 2 || slide.slideType === 4) && slide.imageFileId !== null){
            for (const [i, thisSlide] of project.slides.entries()){
                if (
                    i !== index && 
                    (thisSlide.slideType === 2 || thisSlide.slideType === 4)
                ){
                    return false;
                }
            }
        }else if ((slide.slideType === 3 || slide.slideType === 5)  && slide.videoFileId !== null){
            for (const [i, thisSlide] of project.slides.entries()){
                if (
                    i !== index && 
                    (thisSlide.slideType === 3 || thisSlide.slideType === 5)
                ){
                    return false;
                }
            }
        }

        //css:hidden = true
        return true;
    }  

    const showPasteMediaButton = (index, slideType) => {
        if(slideType === 1){
            for (const [i, slide] of project.slides.entries()){
                if (
                    i !== index && 
                    slide.slideType === 1 && 
                    slide.copiedMedia === true
                ){
                    return true;
                }
            }
        }else if (slideType === 2 || slideType === 4){
            for (const [i, slide] of project.slides.entries()){
                if (
                    i !== index && 
                    (slide.slideType === 2 || slide.slideType === 4) && 
                    slide.copiedMedia === true
                ){
                    return true;
                }
            }
        }else if (slideType === 3 || slideType === 5){
            for (const [i, slide] of project.slides.entries()){
                if (
                    i !== index && 
                    (slide.slideType === 3 || slide.slideType === 5) && 
                    slide.copiedMedia === true
                ){
                    return true;
                }
            }
        }

        return false;
    }

    const handleRemoveFragmentClick = (e, slideIndex, index) => {
        const action = asfAction_removeFragment({
            slideIndex: slideIndex,
            fragmentIndex: index
        }); dispatch(action);
    }

    const handleAddFragmentClick = (e, slideIndex, index) => {
        const action = asfAction_addFragment({
            slideIndex: slideIndex,
            fragmentIndex: index
        }); dispatch(action);
    }

    const getFragmentsJSX = (fragments, slideIndex) => {
        let fragmentsJSX = [];

        const getFragmentTitleExplanationJSX = (slideIndex, fragmentIndex) => {
            if (slideIndex !== indexOfFirstTTSSlide || fragmentIndex !== 0){
                return <></>
            }else{
                return (
                    <>
                        <div className="w-full flex flex-col items-center mt-2.5 grow">
                            <div className="w-full font-montserrat font-semibold text-base flex ml-1 justify-start">
                                The text you provided is divided into manageable fragments for processing and reading. Make adjustments to these fragments to fine tune how the text is read back and visualised.
                            </div>
                        </div>
                    </>
                )
            }
        }

        const getFragmentWhiteBoxExplanationJSX = (slideIndex, fragmentIndex) => {
            if (slideIndex !== indexOfFirstTTSSlide || fragmentIndex !== 0){
                return <></>
            }else{
                return (
                    <>
                        <div className="w-full flex flex-col items-center mt-2.5 grow">
                            <div className="w-full font-montserrat font-medium text-base flex ml-1 justify-start items-center">
                                The white box above contains text in a format that's easily understandable by humans, and this is what forms the subtitles.
                            </div>
                        </div>
                    </>
                )
            }
        }
    
        const getFragmentBlackBoxExplanationJSX = (slideIndex, fragmentIndex) => {
            if (slideIndex !== indexOfFirstTTSSlide || fragmentIndex !== 0){
                return <></>
            }else{
                return (
                    <>
                        <div className="w-full flex flex-row items-center mt-2.5 grow">
                            <div className="w-full font-montserrat font-medium text-base flex ml-1 justify-start items-center">
                                Conversely, the black box above contains what we call 'robot text' - the machine-readable format that generates the spoken words.
                            </div>
                        </div>
                    </>
                )
            }
        }

        fragments.forEach((fragment, index) => {
            const originalTextAreaInputClass = classNames('w-full bg-white my-2.5 font-montserrat font-medium text-[1.313rem] h-39');
            const originalTextAreaFeatherIcon = classNames('h-6 w-6 mx-2 mt-3');
            const originalTextAreaInputFieldDivClass = classNamesDedupe(textAreaInputFieldDivClass, {
                'items-center': false,
                'h-[3.5rem]': false,
                'h-40': true,
                'items-top': true,
                'mt-6': false,
                'mt-2.5': true,
                'border-black': fragment.topErrorFieldState === 'initial',
                'border-red-1': fragment.topErrorFieldState === 'failed-server-test'
            });

            const aiSpeakableTextAreaInputClass = classNames(originalTextAreaInputClass, 'bg-black text-white');
            const aiSpeakableTextAreaFeatherIcon = classNames(originalTextAreaFeatherIcon, 'fill-white');
            const aiSpeakableTextAreaInputFieldDivClass = classNamesDedupe(originalTextAreaInputFieldDivClass, 'bg-black');

            const fragmentTopErrorDivClass = () => {
                let hiddenValue = true;
                if (fragment.topErrorFieldState === 'failed-server-test'){
                    hiddenValue = false;
                }
        
                return classNames(errorDivClass, {
                    'hidden': hiddenValue
                });
            }
        
            const fragmentTopErrorMessage = () => {
                return fragment.topErrorErrorMessage;
            };

            const isDisableFragmentAddButton = () => {
                return isDisableAllButtons() || fragments.length > 9
            }

            fragmentsJSX.push(
                <React.Fragment key={index}>
                    {getErrorJSX(fragment.error.fragment)}
                    <div className={fragmentTopErrorDivClass()}>{fragmentTopErrorMessage()}</div>
                    {getFragmentTitleExplanationJSX(slideIndex, index)}
                    {getErrorJSX(fragment.error.originalText)}
                    <div className={originalTextAreaInputFieldDivClass}>
                        <FiUser className={originalTextAreaFeatherIcon}/>
                        <textarea type="text" name="originalText" className={originalTextAreaInputClass} placeholder="Manually add your text here"
                        onChange={(e) => {
                            handleFragmentOriginalTextFieldChange(e, slideIndex, index);
                        }} 
                        value={fragment.originalText}/>
                        <div className={originalTextCharCountClass(fragment)}>{fragment.charCount}</div>
                    </div>
                    {getFragmentWhiteBoxExplanationJSX(slideIndex, index)}
                    {getErrorJSX(fragment.error.aiSpeakableText)}
                    <div className={aiSpeakableTextAreaInputFieldDivClass}>
                        <FaRobot className={aiSpeakableTextAreaFeatherIcon}/>
                        <textarea type="text" name="originaiSpeakableTextalText" className={aiSpeakableTextAreaInputClass} placeholder="Manually add your text here"
                        onChange={(e) => {
                            handleFragmentAiSpeakableTextFieldChange(e, slideIndex, index);
                        }} 
                        value={fragment.aiSpeakableText}/>
                        <div className={aiSpeakableTextCharCountClass(fragment)}>{fragment.aiSpeakableTextCharCount}</div>
                    </div>
                    {getFragmentBlackBoxExplanationJSX(slideIndex, index)}
                    <div className={classNames('w-full bg-white text-center flex flex-row mt-3 gap-5')}>
                        <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()}
                        onClick={(e) => {
                            handleRemoveFragmentClick(e, slideIndex, index);
                            }}>
                            <FiTrash className="justify-self-end h-6 w-6"/>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Remove Fragment
                            </div>
                        </Button>
                        <Button primary={!isDisableFragmentAddButton()} primaryDisabled={isDisableFragmentAddButton()}
                            onClick={(e) => {
                                handleAddFragmentClick(e, slideIndex, index);
                            }}>
                            <div className="grow font-montserrat font-extrabold text-[1.063rem]">
                                Add Fragment
                            </div>
                            <FiPlus className="justify-self-end h-6 w-6"/>
                        </Button>
                    </div>
                </React.Fragment>
            )
        });
        return fragmentsJSX;
    }

    const handleCloseAddNewSlidesModal = () => {
        const action = asfAction_closeAddNewSlideModal(); dispatch(action);
    }

    const handleCloseChangeVoiceModal = () => {
        const action = asfAction_closeChangeModal(); dispatch(action);
    }

    const handleAddBackgroundMusic = () => {
        const action = asfAction_addBackgroundMusicSlide(); dispatch(action);
    }

    const handleAddTTSImage = () => {
        const action = asfAction_addTTSImageSlide(); dispatch(action);
    }

    const handleAddTTSVideo = () => {
        const action = asfAction_addTTSVideoSlide(); dispatch(action);
    }

    const handleAddNoTTSImage = () => {
        const action = asfAction_addNoTTSImageSlide(); dispatch(action);
    }

    const handleAddNoTTSVideo = () => {
        const action = asfAction_addNoTTSVideoSlide(); dispatch(action);
    }

    const handleChangeVoiceClick = (voice) => {
        const action = asfAction_changeVoiceOnModalIndex(voice); dispatch(action);
    }



    const showProject = () => {
        if (project === null){
            return <></>
        }

        return (
            <>
            <div className={titleErrorDivClass()}>{titleErrorMessage()}</div>
                <div className={inputFieldDivClass}>
                    <FiEdit2 className={featherIconsClass}/>
                    <input type="text" name="title" className={textInputClass} placeholder="Title" 
                    value={project.title} readOnly/>
                </div>
                <div className="flex flex-col items-center mt-6">
                    <div className="rounded-[20px] w-full h-full">
                        <div className='player-wrapper bg-yellow-1 w-full rounded-[20px]'>
                            <ReactPlayer 
                                className='react-player'
                                url={`https://saythatback-projectfile.s3.amazonaws.com/${project.s3key}.mp4`}
                                width="100%"
                                height="100%"
                                loop
                                playsinline
                                controls
                            />
                        </div>
                    </div>
                </div>
                {getErrorJSX(project.error.slides)}
                {getSlidesJSX()}
            </>
    )}

    function getIndexOfFirstTTSSlide(){
        for (const [index, slide] of project.slides.entries()){
            if ([2, 3].includes(slide.slideType)){
                return index;
            }
        }
    }
    
    const handleClosePage = async () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const handleCloseTwoPage = async () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closeTwoPages(pageToClose); dispatch(action);
        await loadCompletedProjects(dispatch, sessionState);
    }

    const handleRemoveMediaFile = (index) => {
        const action = asfAction_removeMediaFile(index); dispatch(action);
    }

    const handleCopyMediaFile = (index) => {
        const action = asfAction_copyMediaFile(index);; dispatch(action);
    }

    const handlePasteMediaFile = (index) => {
        const action = asfAction_pasteMediaFile(index);; dispatch(action);
    }

    const handleUpdateDirection = (direction, index) => {
        const payload = {
            direction: direction,
            index: index
        };
        const action = asfAction_updateDirection(payload); dispatch(action);
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep2 />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2 ">
                    Fine tune this project
                </div>
                <div className='flex, rounded-[20px], items-center, w-full'>
                    <div className='player-wrapper w-full rounded-[20px] mt-2'>
                        <ReactPlayer
                            url={`https://s3.amazonaws.com/saythatback.com-static-assets/fineTuningPage1.mp4`}
                            className='react-player'
                            width="100%"
                            height="100%"
                            playsinline
                            controls
                        />
                    </div>
                </div>
                {getErrorJSX(project.error.topErrorErrorMessage)}
                {getErrorJSX(project.error.title)}
                {getErrorJSX(project.error.slides)}
                {showProject()}
                {getErrorJSX(project.error.topErrorErrorMessage)}
                {getErrorJSX(project.error.title)}
                {getErrorJSX(project.error.slides)}
                {getMainButtonsJSX()}
                <NewSlideModal
                    isOpen={asfFormState.isAddNewSlideModalOpen} 
                    isDisableAllButtons={isDisableAllButtons} 
                    handleCloseModal={handleCloseAddNewSlidesModal}
                    handleAddBackgroundMusic={handleAddBackgroundMusic}
                    handleAddTTSImage={handleAddTTSImage}
                    handleAddTTSVideo={handleAddTTSVideo}
                    handleAddNoTTSImage={handleAddNoTTSImage}
                    handleAddNoTTSVideo={handleAddNoTTSVideo}
                />
                <ChangeVoiceModal
                    isOpen={asfFormState.isChangeVoiceModalOpen}
                    isDisableAllButtons={isDisableAllButtons}
                    handleCloseModal={handleCloseChangeVoiceModal}
                    handleChangeVoiceClick={handleChangeVoiceClick}
                    voices={voices}
                />
                <SavedSumittedRemakeProject
                    isOpen={asfFormState.isSavedSumittedRemakeProjectOpen}
                    handleModalOkClick={handleCloseTwoPage}
                />
            </div>
        </div> 

    )
}

export default FineTuneCompletedProjectPage;