import _ from 'lodash';
import { createSlice } from "@reduxjs/toolkit";
import { getJSDateFromJSONDate } from "../../utils/utils";
import { apiAction_endDeleteProjectLoadDraftProjects, apiAction_endGetDraftProject, apiAction_endGetDraftProjects, pagesAction_closeMenuOpenPage, pagesAction_closePage } from "../actions";
import { apiAction_endGetCopyProject } from "./APISlice";

const draftProjectsSlice = createSlice({
    name: 'draftProjectsPage',
    initialState: getInitialState(),
    reducers: {
        dpAction_setDraftDeleteProjectId(state, action){
            state.deleteProjectId = action.payload;
        },
        dpAction_setDraftCopyProjectId(state, action){
            state.copyProjectId = action.payload;
        },
    },
    extraReducers(builder){
        //when  page is OPENED
        builder.addCase(pagesAction_closeMenuOpenPage, (state, action) => {
            if(action.payload && action.payload === 'DraftProjectsPage'){
                state = getInitialState(state, action)
            }
        });
        builder.addCase(apiAction_endGetDraftProjects, (state, action) => {
            resetErrorState(state, action);

            const res = action.payload.res;
            if(action.payload.draftProjectsPage && res.success && res.success === true){
                //convert json.project to state.project
                jsonProjectsToStateProjects(state, action, res.data.projects);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                state.error.topErrorErrorMessage = res.error_description;
            }
        });
        builder.addCase(apiAction_endGetDraftProject, (state, action) => {
            resetErrorState(state, action);

            const res = action.payload.res;
            const projectId = action.payload.projectId;
            if(res.success && res.success === true){
                
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                setErrorOnProject(state, action, projectId, res.error_description);
            }
        });
        builder.addCase(apiAction_endDeleteProjectLoadDraftProjects, (state, action) => {
            resetErrorState(state, action);

            const projectId = action.payload.projectId;
            const res = action.payload.res;
            state.deleteProjectId = null;
            if(action.payload.draftProjectsPage && res.success && res.success === true){
                //convert json.project to state.project
                jsonProjectsToStateProjects(state, action, res.data.projects);
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else{
                setErrorOnProject(state, action, projectId, res.error_description);
            }
        });
        builder.addCase(apiAction_endGetCopyProject, (state, action) => {
            resetErrorState(state, action);

            const projectId = action.payload.projectId;
            const res = action.payload.res;
            state.copyProjectId = null
            if(action.payload.draftProjectsPage && res.success && res.success === true){
                
            }else if(res.error && res.error === 'TOKEN_INVALID'){
                //do nothing here
            }else if(res.error){
                setErrorOnProject(state, action, projectId, res.error_description);
            }
        });
    }
});

const setErrorOnProject = (state, action, projectId, error_description) => {
    for (const project of state.projects){
        if (project.id === projectId){
            project.error.topErrorErrorMessage = error_description;
            break;
        }
    }
}

const jsonProjectsToStateProjects = (state, action, jsonProjects) => {
    for (const jsonProject of jsonProjects){
        jsonProject.creationDate = getJSDateFromJSONDate(jsonProject.creationDate);

        //ERROR STATES
        jsonProject.error = {};
        jsonProject.error.topErrorErrorMessage = null;
    }
    
    state.projects = jsonProjects;
}

function getInitialState() {
    let returnState = {
        projects:[],
        copyProjectId: null,
        deleteProjectId: null,

        //ERROR STATES
        error: {
            topErrorErrorMessage: null
        }
    };

    return returnState;
}

const resetErrorState = (state, action) => {
    state.error.topErrorErrorMessage = null;

    for (const project of state.projects){
        project.error.topErrorErrorMessage = null;
    }
}

export const { 
    dpAction_setDraftCopyProjectId,
    dpAction_setDraftDeleteProjectId
} = draftProjectsSlice.actions;
export const draftProjectsReducer = draftProjectsSlice.reducer;
