import { FiImage, FiMusic, FiVideo, FiX } from "react-icons/fi";
import Modal from 'react-modal';
import Button from "../../components/buttons/Button";
import { FaRobot } from "react-icons/fa";
import { ReactComponent as OpenPeep } from "../../images/svg/openpeep2.svg";


const NewSlideModal = ({
    isOpen, 
    isDisableAllButtons, 
    handleCloseModal,
    handleAddBackgroundMusic,
    handleAddTTSImage,
    handleAddTTSVideo,
    handleAddNoTTSImage,
    handleAddNoTTSVideo
}) => {

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '20px',
            padding: '20px',
            height: 'fit-content',
            width: '90%',
            maxWidth: '30rem',
            backgroundColor: '#FFFFFF',
    
            maxHeight: '90%',
            overflow              : 'auto',
            WebkitOverflowScrolling: 'touch'
          },
    };

    return (
    <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        style={modalStyle}
    >
        <div className="absolute">
            <Button round={!isDisableAllButtons()} roundDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleCloseModal}>
                <FiX className="justify-self-start w-6 h-6"/>
            </Button>
        </div>
        <div className="flex place-content-center ">
            <OpenPeep className="h-[12rem]"/>
        </div>
        <div className="w-full bg-white font-montserrat font-extrabold 
        text-xl rounded-[20px] text-center mb-1 p-1">
            Select the type of slide you'd like to add
        </div>
        <div className="mb-3">
            <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleAddBackgroundMusic}>
                <FiMusic className="justify-self-start w-6 h-6"/>
                <div className="grow font-montserrat font-bold text-[1.063rem]">
                    Background Music
                </div>
            </Button>
        </div>
        <div className="mb-3">
            <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleAddTTSImage}>
                <FiImage className="justify-self-start w-6 h-6 mr-1"/>
                <div className="grow font-montserrat font-bold text-[1.063rem]">
                    Image + VoiceSpeaker
                </div>
                <FaRobot className="justify-self-start w-6 h-6"/>
            </Button>
        </div>
        <div className="mb-3">
            <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleAddTTSVideo}>
                <FiVideo className="justify-self-start w-6 h-6 mr-1"/>
                <div className="grow font-montserrat font-bold text-[1.063rem]">
                    Video + VoiceSpeaker
                </div>
                <FaRobot className="justify-self-start w-6 h-6"/>
            </Button>
        </div>
        <div className="mb-3">
            <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleAddNoTTSImage}>
                <FiImage className="justify-self-start w-6 h-6"/>
                <div className="grow font-montserrat font-bold text-[1.063rem]">
                    Image Only
                </div>
            </Button>
        </div>
        <div className="mb-3">
            <Button plain={!isDisableAllButtons()} plainDisabled={isDisableAllButtons()} 
                className="mb-5" onClick={handleAddNoTTSVideo}>
                <FiVideo className="justify-self-start w-6 h-6"/>
                <div className="grow font-montserrat font-bold text-[1.063rem]">
                    Video Only
                </div>
            </Button>
        </div>
    </Modal>
    )
}

export default NewSlideModal;