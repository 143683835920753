
import className from "classnames";

function Button({children,
    plain,
    primary,
    plainDisabled,
    primaryDisabled,
    secondary,
    success,
    warning,
    danger,
    round,
    roundDisabled,
    roundSelected,
    roundSelectedDisabled,
    largeRound,
    largeRoundSelected,
    onClick,
    waiting,
    disabled,
    caption,
}){

    const getShadow = () => {
        if (disabled || 
            plainDisabled || 
            primaryDisabled || 
            roundDisabled ||
            roundSelectedDisabled){
            return 'shadow-none hover:shadow-none active:shadow-none'
        }else{
            return 'shadow-xl hover:shadow-lg active:shadow-none'
        }
    }

    const classes = className(
        `p-4 object-fill border font-montserrat font-extrabold w-full flex items-center ${getShadow()}`, 
        {
            'border-black bg-white text-black h-[3.5rem] rounded-[30px]': plain,
            'border-black bg-white text-black h-[3.5rem] rounded-[30px] disabled:opacity-75 cursor-not-allowed':plainDisabled,
            'border-white bg-black text-white h-[3.5rem] rounded-[30px]': primary,
            'border-black bg-black text-white h-[3.5rem] rounded-[30px] disabled:opacity-75 cursor-not-allowed': primaryDisabled,
            'border-black bg-white w-12 h-12 rounded-full p-0 justify-center': round,
            'border-black bg-white w-12 h-12 rounded-full p-0 justify-center disabled:opacity-75 cursor-not-allowed': roundDisabled,
            'border-black bg-black w-12 h-12 rounded-full p-0 justify-center': roundSelected,
            'border-black bg-black w-12 h-12 rounded-full p-0 justify-center disabled:opacity-75 cursor-not-allowed': roundSelectedDisabled,
            'border-black bg-white w-24 h-24 rounded-full p-0 justify-center': largeRound,
            'border-black bg-black w-24 h-24 rounded-full p-0 justify-center': largeRoundSelected,
            'disabled:opacity-75 cursor-not-allowed': waiting
    })

    return (
    <button className={classes} onClick={onClick} caption={caption}
        disabled={
            disabled || 
            plainDisabled || 
            primaryDisabled || 
            roundDisabled ||
            roundSelectedDisabled}>
        {children}
    </button>);
}

export default Button;