import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Button from "../components/buttons/Button";
import { FiChevronRight } from 'react-icons/fi';
import { apiAction_endCreatePaymentIntent, apiAction_startCreatePaymentIntent, pagesAction_closePage, pagesAction_openPage } from "../store";
import { ReactComponent as OpenPeep } from "../images/svg/openpeep2.svg";
import { session_removeCookie, session_setCookie } from "../cookie/SessionCookie";
import ClosePageTopBar from "../components/ClosePageTopBar";
import { create_payment_intent } from "../api/create_payment_intent";
import { FaSpinner } from "react-icons/fa";
import { getBackgroundCSS } from "../components/css";

function BasketPage({count}){
    const dispatch = useDispatch();
    
    const sessionState = useSelector((state) => {return state.session;});
    const apiState = useSelector((state) => {return state.api;});
    const basketState = useSelector((state) => {return state.basketPage;});
    const pagesState = useSelector((state) => {return state.pages;});

    let backgroundClass = getBackgroundCSS(count, 'bg-green-1');
    const errorDivClass = classNames('flex', 'border-black', 'items-center', 'w-full', 'font-montserrat', 'font-medium', 'text-[1rem]', 'text-red-1');

    const handleClosePage = () => {
        const pageToClose = pagesState[0];
        const action = pagesAction_closePage(pageToClose); dispatch(action);
    }

    const handlePayNowClick = async () => {
        //check if logged in - if not then present login screen
        if(sessionState.isLoggedIn === false ){
            //load login page
            const action = pagesAction_openPage('LoginRegisterEmailPage2');dispatch(action);
            return;
        }
        
        let action = apiAction_startCreatePaymentIntent(); dispatch(action);
        const res = await create_payment_intent(basketState.project.id, sessionState.token);
        const payload = { res: res, };
        if (res.success && res.success === true && res.status === 'created_payment_intent'){
            const sessionObject = {
                isLoggedIn: true,
                token: res.session.token,
                creationDate: res.session.creationDate,
                expiryDate: res.session.expiryDate
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_openPage',
                nextPage: 'CheckoutPage'
            };
            payload['basketPage'] = {};

            //SET COOKIES
            session_setCookie(res.session.token, res.session.creationDate, res.session.expiryDate);
        }else if(res.error && res.error === 'TOKEN_INVALID'){
            const sessionObject = {
                isLoggedIn: false,
                token: null,
                creationDate: null,
                expiryDate: null
            }
            payload['session'] = sessionObject;
            payload['pages'] = {
                pagesAction: 'pagesAction_logoutAllPages',
                nextPage: ''
            };

            //REMOVE COOKIES
            session_removeCookie();
        }
        
        action = apiAction_endCreatePaymentIntent(payload); dispatch(action);
    }

    const isLoading = () => {
        if (apiState.createPaymentIntentStatus === 'waiting'){
            return true;
        }else{
            return false;
        }
    }

    const isDisableAllButtons = () => {
        return isLoading();
    }

    const getButtonIcon = () => {
        if (isLoading()){
          return <FaSpinner className="animate-spin justify-self-end h-6 w-6"/>
        }else{
          return <FiChevronRight className="justify-self-end h-6 w-6"/>
        }
    }

    const TTSFee = (1000/1000) * basketState.project.wordCount/100;
    const SlidesFee = (1000/1000) * 2 * basketState.project.slides.length/100;
    const ProcessingFee = 20/100;
    const SumFee = TTSFee + SlidesFee + ProcessingFee;
    const MinimumFee = 50/100;
    const TotalFee = Math.max(SumFee, MinimumFee)

    const getMin50FeeExplainerJSX = () => {
        if (SumFee > 50/100){
            return <></>
        }else{
            return (
                <div className="font-montserrat font-semibold text-start mt-6">
                    Since the total cost falls below £{(MinimumFee).toFixed(2)}, we will apply our standard minimum charge of £{(MinimumFee).toFixed(2)}.
                </div>
            )
        }
    }

    const getErrorJSX = (stateErrorVariable) => {
        if (stateErrorVariable === null || stateErrorVariable === undefined){
            return <></>
        }else{
            return (
                <div className={errorDivClass}>{stateErrorVariable}</div>
            )
        }
        
    }

    return (
        <div className={backgroundClass}>
            <ClosePageTopBar onClick={handleClosePage} disabled={isDisableAllButtons()} />
            <div className="w-screen sm:w-[40rem] p-6 flex flex-col items-center">
                <OpenPeep />
                <div className="w-full bg-white font-montserrat font-extrabold 
                text-4xl rounded-[20px] p-6 text-center border-black border-2">
                    Price Breakdown
                </div>
                <div className="w-full bg-white rounded-[20px] mt-2 p-6 text-center border-black border-2 flex flex-col gap-3">
                    {getErrorJSX(basketState.topErrorErrorMessage)}
                    <div className="font-montserrat text-left">
                        <div className="font-semibold">Text-to-Voice Cost</div> 
                        <div className="font-regular flex flex-row text-left">
                            <div className="w-1/2 text-start">{basketState.project.wordCount} words:</div>
                            <div className="w-1/2 text-end">£{(TTSFee).toFixed(2)} </div>
                        </div>
                    </div>
                    <div className="font-montserrat text-left">
                        <div className="font-semibold">Slide(s) Cost</div> 
                        <div className="font-regular flex flex-row text-left">
                            <div className="w-1/2 text-start">{basketState.project.slides.length} slide{basketState.project.slides.length>1?"s":""}:</div>
                            <div className="w-1/2 text-end">£{(SlidesFee).toFixed(2)} </div>
                        </div>
                    </div>
                    <div className="font-montserrat text-left">
                        <div className="font-semibold">Processing Cost</div> 
                        <div className="font-regular flex flex-row text-left">
                            <div className="w-1/2 text-start">Flat Fee:</div>
                            <div className="w-1/2 text-end">£{(ProcessingFee).toFixed(2)} </div>
                        </div>
                    </div>
                    <div className="font-montserrat text-left">
                        <div className="font-semibold flex flex-row text-start">
                            <div className="w-1/2 text-start">Total Charge</div>
                            <div className="w-1/2 text-end">£{(SumFee).toFixed(2)} </div>
                        </div>
                    </div>
                    {getMin50FeeExplainerJSX()}
                    <div className="mt-3">
                        <Button primary={!isDisableAllButtons()} primaryDisabled={isDisableAllButtons()} 
                            onClick={async () => {
                                await handlePayNowClick();
                            }}>
                            <div className="grow font-montserrat font-extrabold text-xl">
                                Pay now - £{(basketState.project.pricing/100).toFixed(2)}
                            </div>
                            {getButtonIcon()}
                        </Button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default BasketPage;
